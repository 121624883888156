import React, { useContext, useRef } from "react";
import { useState, useEffect } from "react";

import {
  MHeader,
  MTableCell,
  ApiData,
  ActionType,
} from "../Components/table/Table";
import "../Components/table/table.css";

import Header from "../Components/Header/Header";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import QuantityInput from "../Components/QuantityInput/QuantityInput";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { Header2 } from "../Components/Header/Header2";
import ModalSupression from "../Components/Modal/ModalSupression";

import { HospitalInterface, VersementInterface } from "../interfaces";
import Pagination from "../Components/pagination/Pagination";
import { rootUrl } from "../constants";
import { CurrentUserContext } from "../App";
import { useReactToPrint } from "react-to-print";
import PaymentModal, { PaymentData } from "../Components/Modal/Paymentmodal";
import PrintList from "../Components/printable_list";
import { generalRefundCategories } from "../utils";
export const titles = [
  { label: "Date", sortable: true },
  { label: "Montant", sortable: true },
  { label: "Compte", sortable: true },
  { label: "Numéro de quittance", sortable: true },
];

interface InsurancePaymentInterface {
  id: number;
  amount: number;
  created_at: string;
  quittance_number: number;
  account: string;
  hospital_name: string;
  for_cnam: boolean;
}

interface ApiDataWithRest<T> extends ApiData<T> {
  rest: number;
}

const InsurancePaymentsPage = () => {
  const componentRef = useRef<HTMLDivElement>(null);

  const [selected, setSelected] = useState<number[]>([]);
  let [currentPage, setCurrentPage] = useState<number>(1);
  const [data, setData] =
    useState<ApiDataWithRest<InsurancePaymentInterface> | null>(null);
  const [searchedText, setSearchedText] = useState<string>("");
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();

  const [isAddingInsurancePayment, setIsAddingInsurancePayment] =
    useState<boolean>(false);

  let currentuserContexxt = useContext(CurrentUserContext);
  let token = useContext(CurrentUserContext).currentUserData!.token;
  const insuredHospitals =
    currentuserContexxt.currentUserData!.hospitals.filter(
      (hospital) => hospital.is_hospital
    );

  const [selectedHospitalId, setSelectedHospitalId] = useState<number>(
    insuredHospitals[0].id
  );
  const [forCnam, setForCnam] = useState<boolean>(true);

  function FetchData() {
    setData(null);

    axios
      .get(
        rootUrl + "/hospital/" + selectedHospitalId + "/insurance_payments",
        {
          params: {
            for_cnam: forCnam ? "True" : "False",
          },
          headers: {
            Authorization: "Token " + token,
          },
        }
      )
      .then((response: { data: any }) => {
        setData(response.data);
      })
      .catch((error: any) => {});
  }

  useEffect(() => {
    FetchData();
  }, [currentPage, selectedHospitalId, forCnam]);

  useEffect(() => {
    clearTimeout(typingTimeout);

    const timeoutId = setTimeout(() => {
      setSelected([]);
      currentPage = 1;
      FetchData();
    }, 500);

    setTypingTimeout(timeoutId);

    return () => {
      clearTimeout(typingTimeout);
    };
  }, [searchedText]);

  const handlePrint = useReactToPrint({
    onBeforeGetContent() {},

    content: () => {
      return componentRef.current;
    },
    onAfterPrint: () => {},
  });

  const currentHospital = insuredHospitals.find(
    (hospital) => hospital.id === selectedHospitalId
  );

  function deleteInsurancePayment(id: number) {
    axios
      .delete(
        rootUrl +
          "/hospital/" +
          selectedHospitalId +
          "/insurance_payments/" +
          id,
        {
          headers: {
            Authorization: "Token " + token,
          },
        }
      )
      .then((response) => {
        alert("Versement supprimé avec succès");
        FetchData();
      })
      .catch((error) => {
        alert("Une erreur s'est produite");
        console.error(error);
      });
  }

  function handlePayment({ data }: { data: PaymentData }) {
    if (!currentHospital) return;

    const newData = {
      amount: data.amount,
      account: currentHospital!.bank_account,
      quittance_number: data.quittance_number,
      for_cnam: forCnam,
      hospital: currentHospital!.id,
    };
    axios
      .post(
        rootUrl + "/hospital/" + selectedHospitalId + "/insurance_payments",
        newData,
        {
          headers: {
            Authorization: "Token " + token,
          },
        }
      )
      .then((response) => {
        alert("Versement effectué avec succès");
        FetchData();
      })
      .catch((error) => {
        alert("Une erreur s'est produite");
        console.error(error);
      });
  }

  return (
    <div className="hopitaux-wrapper">
      {isAddingInsurancePayment && data && (
        <PaymentModal
          open={true}
          initalRevenue={null}
          onClose={() => {
            setIsAddingInsurancePayment(false);
          }}
          onConfirmClick={(data) => {
            handlePayment({ data: data });
            setIsAddingInsurancePayment(false);
          }}
          name={null}
        />
      )}
      <Header title={"Versement Assurance "} />
      <div className="flex gap-x-2 ml-10 font-pop font-medium">
        {insuredHospitals.map((h, index) => {
          return (
            <button
              onClick={() => {
                setSelectedHospitalId(h.id);
                setSelected([]);

                setForCnam(true);
                setCurrentPage(1);
              }}
              className={`font-semibold rounded-sm text-black  p-2 transition-all ${
                selectedHospitalId === h.id ? "bg-[#187498] text-white" : ""
              }`}
            >
              <p>{h.name}</p>
            </button>
          );
        })}
      </div>
      {
        <div className="flex flex-col gap-y-2 mt-2 ml-10">
          <hr className="w-full border-slate-400" />
          <div className="flex gap-x-2 font-pop font-medium ">
            <button
              onClick={() => {
                setForCnam(true);
                setCurrentPage(1);
              }}
              className={`font-semibold rounded-sm text-black  p-2 transition-all ${
                forCnam ? "bg-[#187498] text-white" : ""
              }`}
            >
              <p>CNAM</p>
            </button>
            <button
              onClick={() => {
                setForCnam(false);
                setCurrentPage(1);
              }}
              className={`font-semibold rounded-sm text-black  p-2 transition-all ${
                !forCnam ? "bg-[#187498] text-white" : ""
              }`}
            >
              <p>CONVENTION</p>
            </button>
          </div>
        </div>
      }

      {
        <Header2
          inputPlaceholder={"Rechercher dans ce tableau"}
          onInputChange={function (text: string): void {
            setSearchedText(text);
          }}
          onAddButtonClick={() => {
            setIsAddingInsurancePayment(true);
          }}
          handlePrint={handlePrint}
        />
      }
      <div className="flex gap-x-2 ml-10 mb-4 items-center ">
        <div className="flex gap-x-1 ">
          <span className="font-semibold">Total réglé : </span>
          <span>{data?.total ?? 0}</span>
        </div>
        <div className="w-[2px] h-6 bg-black" />
        <div className="flex gap-x-1  ">
          <span className="font-semibold">Total dû : </span>
          <span>{data?.rest ?? 0}</span>
        </div>
      </div>

      {data === null ? (
        // center loading
        //center
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress thickness={6} sx={{ color: "#0B63F8" }} />
        </div>
      ) : (
        <Table
          style={{
            backgroundColor: "white",
            marginRight: "40px",
            marginLeft: "40px",
            width: "95.5%",
          }}
        >
          <MHeader
            titles={titles}
            withActions={true}
            onCheckClick={() => {
              if (selected.length === data?.data.length) {
                setSelected([]);
              } else {
                setSelected(data?.data.map((item) => item.id) || []);
              }
            }}
            isAllSelected={
              data &&
              data!.data.length !== 0 &&
              selected.length === data?.data.length
            }
          />
          <TableBody>
            {data!.data.map((insurancePayment, index) => (
              <TableRow key={index}>
                <TableCell size="small">
                  <Checkbox
                    checked={selected.includes(insurancePayment.id)}
                    onChange={() => {
                      if (selected.includes(insurancePayment.id)) {
                        setSelected(
                          selected.filter(
                            (item) => item !== insurancePayment.id
                          )
                        );
                      } else {
                        setSelected([...selected, insurancePayment.id]);
                      }
                    }}
                  />
                </TableCell>
                <MTableCell
                  label={`${new Date(
                    insurancePayment.created_at
                  ).toDateString()}`}
                  isTitle={false}
                />
                <MTableCell
                  label={insurancePayment.amount + " MRU"}
                  isTitle={false}
                />
                <MTableCell
                  label={`${insurancePayment.account}`}
                  isTitle={false}
                />
                <MTableCell
                  label={`${insurancePayment.quittance_number}`}
                  isTitle={false}
                />
                <MTableCell
                  label={
                    <button
                      onClick={() => {
                        deleteInsurancePayment(insurancePayment.id);
                      }}
                      className="bg-red-400 border-2 text-sm font-medium text-white border-red-400 rounded py-2 px-3 hover:bg-transparent hover:text-red-500 active:scale-95 transition-all "
                    >
                      Supprimer
                    </button>
                  }
                  isTitle={false}
                />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      {data && (
        <Pagination
          currentPage={currentPage}
          totalPages={data!.total_pages}
          onPageChange={function (page: number): void {
            setCurrentPage(page);
          }}
        />
      )}
      <div ref={componentRef}>
        <PrintList
          centerName={currentHospital!.name}
          title={"VERSEMENTS " + (forCnam ? "CNAM" : "CONVENTION")}
          showRegisseur={true}
          titles={titles.map((title) => title.label)}
          afterTable={
            // total
            <div className="flex gap-x-2 items-center ">
              <div className="flex gap-x-1 ">
                <span className="font-semibold">Total réglé : </span>
                <span>{data?.total ?? 0}</span>
              </div>
              <div className="w-[2px] h-6 bg-black" />
              <div className="flex gap-x-1  ">
                <span className="font-semibold">Total dû : </span>
                <span>{data?.rest ?? 0}</span>
              </div>
            </div>
          }
          data={
            data?.data
              .filter(
                (versement) =>
                  selected.length === 0 || selected.includes(versement.id)
              )
              .map((insurancePayment, index) => {
                return [
                  new Date(insurancePayment.created_at).toDateString(),
                  insurancePayment.amount + " MRU",
                  insurancePayment.account,
                  insurancePayment.quittance_number,
                ];
              }) ?? []
          }
        />
      </div>
    </div>
  );
};

export default InsurancePaymentsPage;
