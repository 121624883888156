export const generalRefundCategories = [
  "commerçants",
  "prestateurs",
  "artisanats",
];
export function paraseRefundCategory(category: string) {
  // # category_1 : commerçants
  // # category_2 : prestateurs
  // # category_3 : artisanas

  const index = category.split("_")[1];
  return generalRefundCategories[parseInt(index) - 1];
}
