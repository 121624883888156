const IS_PRODUCTION = true;
export const rootUrl = IS_PRODUCTION
  ? "https://khalilkt.pythonanywhere.com"
  : "http://127.0.0.1:8000";

// export const rootUrl = "http://api.gvimnouadhibou.com/";

export interface InssuranceCompany {
  name: string;
  value: string;
  multiplier: number;
}
// export const insuranceNames = ["CNAM", "TAAZOUR", "HILAL"];
export const defInsurances: InssuranceCompany[] = [
  {
    name: "CNAM",
    value: "CNAM",
    multiplier: 0.1,
  },
  {
    name: "TAAZOUR",
    value: "TAAZOUR",
    multiplier: 0.0,
  },
  {
    name: "HILAL AL AHMAR",
    value: "HILAL",
    multiplier: 0.0,
  },
];
