import { useContext, useEffect } from "react";
import { CurrentUserContext } from "../App";

export interface FilterProps {
  statsType: "daily" | "monthly" | null;
  selectedDate: string | null;
  selectedMonth: number | null;
  selectedYear: number | null;
  selectedUserId: number | null;
  filterByUser?: boolean;
  canSelectedAll?: boolean;
}
export function FilterComponent({
  filterProps,
  setFilterProps,
}: {
  filterProps: FilterProps;
  setFilterProps: (props: FilterProps) => void;
}) {
  let currentSelectedHospitalId =
    useContext(CurrentUserContext).currentUserData!.selectedHospitalId;
  let currentSelectedHospital = useContext(
    CurrentUserContext
  ).currentUserData!.hospitals.find((h) => h.id === currentSelectedHospitalId);

  if (filterProps.filterByUser === undefined) {
    filterProps.filterByUser = true;
  }
  const showUserFilter =
    currentSelectedHospital !== undefined && filterProps.filterByUser === true;
  return (
    <div className="flex relative flex-row gap-x-3">
      {showUserFilter === true && (
        <select
          value={filterProps.selectedUserId ?? ""}
          onChange={(e) => {
            const ret = e.target.value;
            setFilterProps({
              ...filterProps,
              selectedUserId: ret === "" ? null : parseInt(ret),
            });
          }}
          className="w-40 p-2  text-sm text-gray-900 border border-gray-300 rounded-lg bg-white"
        >
          <option value="">Caissier</option>
          {currentSelectedHospital!.staff_data!.map((c) => (
            <option value={c.id}>{c.name}</option>
          ))}
        </select>
      )}
      <select
        className="w-40 p-2  text-sm text-gray-900 border border-gray-300 rounded-lg bg-white"
        onChange={(e) => {
          const ret = e.target.value as "daily" | "monthly";
          setFilterProps({
            statsType: ret,
            selectedDate: null,
            selectedMonth: null,
            selectedYear: null,
            selectedUserId: filterProps.selectedUserId,
          });
        }}
        value={filterProps.statsType ?? ""}
      >
        <option value="" disabled={!filterProps.canSelectedAll ?? true}>
          Tout
        </option>
        <option value="daily">Par jour</option>
        <option value="monthly">Par mois</option>
      </select>
      <input
        type="date"
        value={filterProps.selectedDate ?? ""}
        onChange={(e) => {
          let ret = e.target.value;

          setFilterProps({
            ...filterProps,
            selectedDate: ret === "" ? null : ret,
            selectedMonth: null,
            selectedYear: null,
          });
        }}
        className={
          "absolute w-40 p-2  text-sm text-gray-900 border border-gray-300 rounded-lg bg-white transition-all " +
          `${
            filterProps.statsType === "daily"
              ? ""
              : " translate-x-10 opacity-0 -z-10 "
          } ${showUserFilter ? " ml-[21rem]" : " ml-44"}`
        }
      />
      <div
        className={
          "absolute flex gap-x-2 transition-all" +
          `${
            filterProps.statsType === "monthly"
              ? ""
              : " -translate-x-10 opacity-0 -z-10"
          } ${showUserFilter ? " ml-[21rem]" : " ml-44"}`
        }
      >
        <select
          value={filterProps.selectedMonth ?? ""}
          onChange={(e) => {
            const ret = e.target.value;
            setFilterProps({
              ...filterProps,
              selectedDate: null,
              selectedMonth: ret === "" ? null : parseInt(ret),
            });
          }}
          className={
            "w-40 p-2  text-sm text-gray-900 border border-gray-300 rounded-lg bg-white " +
            `${filterProps.statsType === "monthly" ? "" : ""}`
          }
        >
          <option value="" disabled={!filterProps.canSelectedAll ?? true}>
            Tout
          </option>
          <option value="1">Janvier</option>
          <option value="2">Février</option>
          <option value="3">Mars</option>
          <option value="4">Avril</option>
          <option value="5">Mai</option>
          <option value="6">Juin</option>
          <option value="7">Juillet</option>
          <option value="8">Août</option>
          <option value="9">Septembre</option>
          <option value="10">Octobre</option>
          <option value="11">Novembre</option>
          <option value="12">Décembre</option>
        </select>
        <select
          value={filterProps.selectedYear ?? ""}
          onChange={(e) => {
            const ret = e.target.value;
            setFilterProps({
              ...filterProps,
              selectedDate: null,
              selectedMonth: null,
              selectedYear: ret === "" ? null : parseInt(ret),
            });
          }}
          className={
            "w-24 p-2  text-sm text-gray-900 border border-gray-300 rounded-lg bg-white " +
            `${filterProps.statsType === "monthly" ? "" : ""}`
          }
        >
          {[
            ...[
              <option value="" disabled={!filterProps.canSelectedAll ?? true}>
                Tout
              </option>,
            ],
            Array.from({ length: 5 }, (v, i) => {
              const now = new Date();
              const year = now.getFullYear() - 4 + i;
              return <option value={year}>{year}</option>;
            }),
          ]}
        </select>
      </div>
    </div>
  );
}
