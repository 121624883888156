import React, { FC, useContext, useState } from "react";
import { MedicationItem } from "../../interfaces";
import axios from "axios";
import { rootUrl } from "../../constants";
import * as XLSX from "xlsx";
import { CurrentUserContext } from "../../App";
import { MdCancel } from "react-icons/md";

interface FileUploadModalProps {
  onFileUpload: (file: File) => void;
  setOpenedFileUpload: () => void;
}

const FileUploadModal: React.FC<FileUploadModalProps> = (props) => {
  const currentUserData = useContext(CurrentUserContext).currentUserData;
  const currentSelectedHospitalId = currentUserData?.selectedHospitalId || "";
  const token = currentUserData?.token || "";
  const [errorMsg, setErrorMsg] = useState<string | null>("");
  const [sheetData, setSheetData] = useState<MedicationItem[] | null>(null);

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    setErrorMsg(null);
    setSheetData(null);

    if (file) {
      const allowedExtensions = ["xlsx", "xls"];
      const fileExtension = file.name.split(".").pop()?.toLowerCase();

      if (!fileExtension || !allowedExtensions.includes(fileExtension)) {
        setErrorMsg("Veuillez choisir un fichier XLSX ou XLS valide.");
        return;
      }

      try {
        const reader = new FileReader();
        reader.onload = async (event) => {
          const medexceldata = event.target?.result;
          const workbook = XLSX.read(medexceldata, { type: "binary" });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];

          const requiredColumns = ["Médicament", "Prix", "Quantité"];
          const headerRow = XLSX.utils.sheet_to_json(sheet, {
            header: 1,
          })[0] as string[];

          if (
            requiredColumns.every((col) =>
              headerRow
                .map((colHeader) => colHeader.toLowerCase())
                .includes(col.toLowerCase())
            )
          ) {
            const parsedData = XLSX.utils.sheet_to_json(sheet);

            // all parsed data keys should be lowercase

            parsedData.forEach((item: any) => {
              Object.keys(item).forEach((key) => {
                const newKey = key.toLowerCase();
                if (key !== newKey) {
                  item[newKey] = item[key];
                  delete item[key];
                }
              });
            });
            setSheetData(
              parsedData.map((item: any, index) => {
                return {
                  id: index,
                  name: item["médicament"],
                  quantity: item["quantité"],
                  price: item["prix"],
                };
              })
            );
          } else {
            console.error("Invalid columns in the file");
            setErrorMsg(
              `Le fichier ne contient pas les colonnes requises. (${requiredColumns.join(
                ", "
              )})`
            );
          }
        };
        reader.readAsBinaryString(file);
      } catch (error) {
        console.error("Error reading file:", error);
        setErrorMsg("Une erreur s'est produite lors de la lecture du fichier.");
      }
    }
  };

  const submitFile = async () => {
    if (!sheetData) {
      console.error("No file selected");
      return;
    }

    console.log("parsedData", sheetData);
    const response = await axios.post(
      rootUrl + "/hospital/" + currentSelectedHospitalId + "/inventory/bulk",
      sheetData,
      {
        headers: {
          Authorization: "Token " + token,
          "Content-Type": "application/json",
        },
      }
    );

    console.log("Backend response:", response.data);

    alert("Le fichier a été importé avec succès.");
  };

  return (
    <div className="flex items-center relative justify-center w-full">
      <MdCancel
        onClick={props.setOpenedFileUpload}
        className="absolute h-6 w-6 top-5 right-12 hover:cursor-pointer text-[#187498]"
      />

      <label
        htmlFor="dropzone-file"
        className="flex flex-col my-2 items-center justify-center w-[95.5%] h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 hover:bg-gray-100 transition-all duration-300 ease-in-out"
      >
        <div className="flex flex-col relative items-center justify-center pt-5 pb-6">
          <svg
            className="w-10 h-10 mb-4 text-[#187498] dark:text-[#187498]"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 20 16"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
            />
          </svg>
          <p className="mb-2 text-sm text-[#187498] dark:text-[#187498] font-semibold">
            Veuillez choisir un fichier Excel
          </p>
        </div>
        <input
          className="hidden"
          id="dropzone-file"
          type="file"
          onChange={handleFileUpload}
        />
        {sheetData && (
          <p className="mb-2 text-sm text-[#36AE7C] dark:text-[#36AE7C] font-semibold">
            {`Le fichier ajoutera ${sheetData.length} médicaments.`}
          </p>
        )}
        {errorMsg && (
          <p className="mb-2 text-sm text-red-500 font-semibold">{errorMsg}</p>
        )}
        <button
          onClick={submitFile}
          disabled={!sheetData}
          className={`text-white bg-[#187498] ${
            !sheetData ? "cursor-not-allowed bg-gray-400" : "hover:bg-[#187498]"
          } font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 bg-[#187498] enabled:hover:bg-[#187498] focus:outline-none`}
        >
          Importer
        </button>
      </label>
    </div>
  );
};

export default FileUploadModal;
