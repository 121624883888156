import React, {
  useContext,
  useState,
  useEffect,
  useRef,
  ReactNode,
} from "react";
import "./header.css";
import TicketModal from "../Modal/TicketModal";
import { CurrentUserContext } from "../../App";
import { MdLogout } from "react-icons/md";
import { FaUser } from "react-icons/fa";
import { PiBellSimpleRingingFill } from "react-icons/pi";
import PaymentModal, { PaymentData } from "../Modal/Paymentmodal";
import { VersementInterface } from "../../interfaces";
import axios from "axios";
import { rootUrl } from "../../constants";
import { useNavigate } from "react-router-dom";
import PrintList from "../printable_list";
import { useReactToPrint } from "react-to-print";

export interface HeaderProps {
  title: string;
}
type notificationType = "no_payment" | "not_valid_payment";
const Header: React.FC<HeaderProps> = ({ title }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPaymentOpen, setIsPaymentOpen] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isNotificationDropdownVisible, setIsNotificationDropdownVisible] =
    useState(false);

  const [headerAlert, setHeaderAlert] = useState<string | null>(null);

  const setCurrentUser = useContext(CurrentUserContext).setCurrentUser;
  let userContext = useContext(CurrentUserContext);

  const currentUser = useContext(CurrentUserContext).currentUserData;
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    async function checkMonthlyNotifications() {
      const currentHospitaId = userContext.currentUserData?.selectedHospitalId;
      const currentHospital = userContext.currentUserData?.hospitals.find(
        (h) => h.id === currentHospitaId
      );
      if (!currentHospitaId) return;
      axios
        .get(rootUrl + "/hospital/" + currentHospitaId + "/not_payed", {
          headers: {
            Authorization: "Token " + userContext.currentUserData!.token,
          },
        })
        .then(async (response) => {
          let total = response.data.data.reduce((acc: number, curr: any) => {
            return acc + curr.revenue;
          }, 0);
          let unpaidWeeks = response.data.data.length;
          let pharmacyTotal = 0;
          let pharmacyUnpaidWeeks = 0;
          if (currentHospital?.has_pharmacy) {
            const res = await axios
              .get(rootUrl + "/hospital/" + currentHospitaId + "/not_payed", {
                headers: {
                  Authorization: "Token " + userContext.currentUserData!.token,
                },
                params: {
                  for_pharmacy: "True",
                },
              })
              .catch((error) => {
                console.log(error);
                return;
              });
            pharmacyUnpaidWeeks = res!.data.data.length;
            pharmacyTotal = res!.data.data.reduce((acc: number, curr: any) => {
              return acc + curr.revenue;
            }, 0);
          }
          if (unpaidWeeks > 0 || pharmacyUnpaidWeeks > 0) {
            setHeaderAlert(
              "Veulliez effectuer les versements auprés du trésorier : " +
                total +
                " MRU" +
                (pharmacyUnpaidWeeks > 0
                  ? " pour l'hopital et " +
                    pharmacyTotal +
                    " MRU pour la pharmacy"
                  : "")
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    if (!userContext.currentUserData?.isAdmin) {
      checkMonthlyNotifications();
    }
  }, [userContext.currentUserData?.selectedHospitalId]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const openPayment = () => {
    setIsPaymentOpen(true);
  };

  const closePayment = () => {
    setIsPaymentOpen(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleConfirmClick = (selectedOption: string) => {
    // alert("Selected Option: " + selectedOption);
  };

  const changeDropdownState = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  const handlePayment = ({
    data,
    id,
  }: {
    data: PaymentData | string;
    id?: number;
  }) => {
    if (typeof data === "string") {
      axios
        .patch(
          rootUrl + "/payments/" + id + "/",
          {
            is_valid: null,
            quittance_number: data,
          },
          {
            headers: {
              Authorization: "Token " + userContext.currentUserData!.token,
            },
          }
        )
        .then((response) => {
          alert("Mis à jour avec succès");
          setHeaderAlert(null);
        });

      return;
    }
    setIsSubmitting(true);
    axios
      .post(rootUrl + "/payments/", data, {
        headers: {
          Authorization: "Token " + userContext.currentUserData!.token,
        },
      })
      .then((response) => {
        alert("Versement effectué avec succès");
        setHeaderAlert(null);
      })
      .catch((error) => {
        console.log(error);
        alert("Une erreur s'est produite, veuillez réessayer");
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  let notifRef = useRef<HTMLDivElement>(null);
  const profileRef = useRef<HTMLDivElement>(null);

  const myName = useContext(CurrentUserContext).currentUserData?.name;

  return (
    <div className="flex flex-col mb-10 mt-4 mx-10 gap-y-2">
      {headerAlert && (
        <div
          ref={notifRef}
          className="dropdown-content  bg-orange-400 text-l p-1 shadow-md hover:cursor-default  transition-all animate-fadeIn w-full inline-block whitespace-nowrap"
        >
          <p
            onClick={openPayment}
            className=" p-2 text-base text-white font-pop flex justify-center items-center gap-2 hover:cursor-pointer font-normal"
          >
            <span className="font-semibold text-xl text-white">
              {headerAlert && <PiBellSimpleRingingFill />}
            </span>

            {headerAlert}
          </p>
        </div>
      )}
      {/* <p>Le stock du médicament <span className="font-bold">Doliprane</span>{" "}
            est 10</p> */}
      <div className="flex self-end gap-x-4">
        {!currentUser?.isAdmin &&
          currentUser?.selectedHospitalId &&
          currentUser!.hospitals.find(
            (h) => h.id === currentUser?.selectedHospitalId
          )!.stock_alerts.length > 0 && (
            <NotificationsDropdown
              notifications={
                currentUser!.hospitals
                  .find((h) => h.id === currentUser?.selectedHospitalId)!
                  .stock_alerts.map((medic) => ({
                    title: "Alert de stock",
                    message: (
                      <p>
                        Le stock du médicament{" "}
                        <span className="font-bold"> {medic.name} </span> est{" "}
                        {medic.quantity}
                      </p>
                    ),
                  })) || []
              }
            />
          )}
        {!currentUser?.isAdmin && (
          <div className="flex-row flex gap-5">
            <div className="relative">
              <button className="user-button" onClick={changeDropdownState}>
                <FaUser />
              </button>
              {isDropdownVisible && (
                <div
                  ref={profileRef}
                  className="dropdown-content  absolute bg-white mt-2 text-l p-4 rounded-md shadow-md  transition-all animate-fadeIn inline-block whitespace-nowrap"
                >
                  <p className="border-b-2 p-2 font-pop font-semibold ">
                    {
                      userContext.currentUserData?.hospitals.find(
                        (h) =>
                          h.id ===
                          userContext.currentUserData?.selectedHospitalId
                      )?.name
                    }
                  </p>
                  <p className="p-2 font-pop font-semibold ">
                    Caissier : <span className="font-normal">{myName}</span>
                  </p>
                </div>
              )}
            </div>

            <button className="ticket-button" onClick={openModal}></button>
          </div>
        )}

        {/* {isPaymentOpen && headerAlert && (
          <PaymentModal
            open={isPaymentOpen}
            revenue={headerAlert!.payload!.revenue}
            for_validation={headerAlert!.type === "not_valid_payment"}
            onClose={closePayment}
            onConfirmClick={(data) => {
              if (headerAlert!.type === "not_valid_payment") {
                handlePayment({
                  data: data,
                  id: headerAlert!.payload!.payment_id!,
                });
              } else {
                handlePayment({ data: data, id: undefined });
              }
            }}
            name={null}
          />
        )} */}

        {isModalOpen && (
          <TicketModal
            open={isModalOpen}
            onClose={closeModal}
            onConfirmClick={handleConfirmClick}
          />
        )}
        <button
          className="logout-button"
          onClick={() => {
            setCurrentUser(null);

            localStorage.removeItem("token");
            localStorage.removeItem("selectedHospitalId");
            navigate("/");
          }}
        >
          <MdLogout />{" "}
        </button>
      </div>

      <div className="header-right">
        <h1 className="title">{title}</h1>
      </div>
    </div>
  );
};

export default Header;

function NotificationsDropdown({
  notifications,
}: {
  notifications: {
    title: string;
    message: ReactNode;
  }[];
}) {
  const [isOpened, setIsOpened] = useState(false);
  const thisRef = useRef<HTMLDivElement>(null);
  const componentRef = useRef<HTMLDivElement>(null);
  const userContext = useContext(CurrentUserContext);

  const currentHospitaId = userContext.currentUserData?.selectedHospitalId;
  const currentHospital = userContext.currentUserData?.hospitals.find(
    (h) => h.id === currentHospitaId
  );
  const handlePrint = useReactToPrint({
    onBeforeGetContent() {},

    content: () => {
      return componentRef.current;
    },
    onAfterPrint: () => {},
  });
  // add a listenner if clciks anywhere outside the dropdown, close it
  useEffect(() => {
    const handleDocumentClick = (event: MouseEvent) => {
      if (
        thisRef.current &&
        event.target instanceof Node &&
        thisRef.current.contains(event.target)
      ) {
        return;
      }
      setIsOpened(false);
    };

    document.addEventListener("mousedown", handleDocumentClick);

    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
    };
  }, []);
  return (
    <div className="relative flex flex-col gap-y-2 items-end" ref={thisRef}>
      <button
        onClick={() => {
          setIsOpened(!isOpened);
        }}
        className="bg-white  relative shadow-sm flex items-center justify-center w-[52px] h-[52px]"
      >
        <div className="absolute rounded-full bg-red-400 w-[20px] h-[20px] top-0 right-0 flex items-center justify-center translate-x-[10px] translate-y-[-10px]">
          <span className=" text-xs text-white font-bold">
            {notifications.length}
          </span>
        </div>
        <svg
          fill="#000000"
          height="25px"
          width="25px"
          version="1.1"
          id="Icons"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 32"
        >
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            {" "}
            <g>
              {" "}
              <path d="M26.8,25H5.2c-0.8,0-1.5-0.4-1.9-1.1c-0.4-0.7-0.3-1.5,0.1-2.2L4.5,20c1.8-2.7,2.7-5.8,2.7-9c0-3.7,2.4-7.1,5.9-8.3 C13.7,1.6,14.8,1,16,1s2.3,0.6,2.9,1.7c3.5,1.2,5.9,4.6,5.9,8.3c0,3.2,0.9,6.3,2.7,9l1.1,1.7c0.4,0.7,0.5,1.5,0.1,2.2 C28.4,24.6,27.6,25,26.8,25z"></path>{" "}
            </g>{" "}
            <path d="M11.1,27c0.5,2.3,2.5,4,4.9,4s4.4-1.7,4.9-4H11.1z"></path>{" "}
          </g>
        </svg>
      </button>
      <div
        className={`bg-white shadow-xl absolute translate-y-[58px] max-h-96 overflow-y-scroll w-[300px] flex flex-col transition-all origin-top-right ${
          isOpened ? "scale-100 opacity-100" : "scale-0 opacity-0"
        }`}
      >
        <div className="flex-col ">
          <div className="flex w-full justify-end border-y py-1 px-2">
            <button
              onClick={() => {
                handlePrint();
              }}
              className=" text-blue-500 font-semibold self-end hover:text-blue-600"
            >
              Imprimer
            </button>
          </div>
          {notifications.map((notif, index) => (
            <div className="flex flex-col py-1 px-2 gap-y-1 ">
              <h3 className="font-semibold">{notif.title}</h3>
              <p className="text-xs mb-1">{notif.message}</p>
              {index !== notifications.length - 1 && <hr />}
            </div>
          ))}
        </div>
        <div ref={componentRef}>
          <PrintList
            title={"Alertes de Stock"}
            titles={["Alertes"]}
            centerName={currentHospital!.name}
            data={notifications.map((notif) => [notif.message])}
            afterTable={
              // total
              <div className="flex">
                <p className="font-semibold">Total : </p>
                <p className="ml-2">
                  {notifications.length} alerte
                  {notifications.length > 1 && "s"}
                </p>
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
}
