import React, { useContext, useRef } from "react";
import { useState, useEffect } from "react";

import {
  MHeader,
  MTableCell,
  MActionsCell,
  ApiData,
  ActionType,
} from "../Components/table/Table";
import Header from "../Components/Header/Header";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { Header2, HeaderDelete } from "../Components/Header/Header2";
import ModalSupression from "../Components/Modal/ModalSupression";

import { UserInterface } from "../interfaces";
import Pagination from "../Components/pagination/Pagination";
import { rootUrl } from "../constants";
import { CurrentUserContext } from "../App";
import { useReactToPrint } from "react-to-print";
import PrintList from "../Components/printable_list";
export const titles = [
  { label: "Nom", sortable: true },
  { label: "Identifiant", sortable: true },
  { label: "Centres assigné", sortable: true },
];

const UsersPage = () => {
  const componentRef = useRef<HTMLDivElement>(null);
  const [printing, setPrinting] = useState<boolean>(false);

  const [selected, setSelected] = useState<number[]>([]);
  let [currentPage, setCurrentPage] = useState<number>(1);
  const [data, setData] = useState<ApiData<UserInterface> | null>(null);
  const [searchedText, setSearchedText] = useState<string>("");
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();

  const navigate = useNavigate();
  const [error, setError] = useState(null);

  let currentuserContexxt = useContext(CurrentUserContext);

  const [deletingId, setDeletingId] = useState<number[]>([]);

  let token = useContext(CurrentUserContext).currentUserData!.token;

  function FetchData() {
    setData(null);
    axios
      .get(rootUrl + "/users/", {
        params: { page: currentPage, search: searchedText },
        headers: {
          Authorization: "Token " + token,
        },
      })
      .then((response: { data: any }) => {
        console.log(data);
        setData(response.data);
      })
      .catch((error: any) => {
        setError(error);
      });
  }

  useEffect(() => {
    FetchData();
  }, [currentPage]);

  useEffect(() => {
    clearTimeout(typingTimeout);

    // Set a new timeout to delay the search
    const timeoutId = setTimeout(() => {
      setSelected([]);
      currentPage = 1; //should be updated but not render
      FetchData();
    }, 500);

    setTypingTimeout(timeoutId);

    return () => {
      clearTimeout(typingTimeout);
    };
  }, [searchedText]);

  const handlePrint = useReactToPrint({
    onBeforeGetContent() {
      setPrinting(true);
    },

    content: () => {
      return componentRef.current;
    },
    onAfterPrint: () => {
      setPrinting(false);
    },
  });

  function deleteUsers(ids: number[]) {
    for (let id of ids) {
      axios
        .delete(rootUrl + "/users/" + id + "/", {
          headers: {
            Authorization: "Token " + token,
          },
        })
        .then((response: { data: any }) => {
          FetchData();
        });
    }
  }

  return (
    <div className="hopitaux-wrapper">
      {deletingId.length > 0 && (
        <ModalSupression
          open={false}
          name={
            deletingId.length === 1
              ? data!.data.find((item) => item.id === deletingId[0])!.name
              : null
          }
          onClose={function (): void {
            setDeletingId([]);
          }}
          onConfirmClick={function (): void {
            deleteUsers(deletingId);
            setDeletingId([]);
          }}
        />
      )}

      <Header title="Utilisateurs" />
      {
        <Header2
          inputPlaceholder={"Rechercher dans ce tableau"}
          onInputChange={function (text: string): void {
            setSearchedText(text);
          }}
          onAddButtonClick={function (): void {
            navigate("/Admin/Utilisateurs/Ajouter");
          }}
          handlePrint={handlePrint}
        />
      }
      {data === null ? (
        // center loading
        //center
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress thickness={6} sx={{ color: "#0B63F8" }} />
        </div>
      ) : (
        <Table
          style={{
            backgroundColor: "white",
            marginRight: "40px",
            marginLeft: "40px",
            width: "95.5%",
          }}
        >
          <MHeader
            titles={titles}
            onCheckClick={() => {
              if (selected.length === data?.data.length) {
                setSelected([]);
              } else {
                setSelected(data?.data.map((item) => item.id) || []);
              }
            }}
            isAllSelected={
              data &&
              data!.data.length !== 0 &&
              selected.length === data?.data.length
            }
          />
          <TableBody>
            {data!.data.map((user, index) => (
              <TableRow key={index}>
                <TableCell size="small">
                  <Checkbox
                    checked={selected.includes(user.id)}
                    onChange={() => {
                      if (selected.includes(user.id)) {
                        setSelected(
                          selected.filter((item) => item !== user.id)
                        );
                      } else {
                        setSelected([...selected, user.id]);
                      }
                    }}
                  />
                </TableCell>
                <MTableCell label={user.name} isTitle={false} />

                <MTableCell label={user.username} isTitle={false} />
                <MTableCell
                  label={user.is_admin ? "Tous" : user.assigned_hospital_name}
                  isTitle={false}
                />

                <MActionsCell
                  onClick={(a) => {
                    if (a === ActionType.DELETE) {
                      setDeletingId([user.id]);
                    } else if (a === ActionType.VIEW) {
                      currentuserContexxt.setCurrentUser({
                        ...currentuserContexxt.currentUserData!,
                        selectedHospitalId: user.id,
                      });
                      navigate("/Médicaments");
                    } else if (a === ActionType.EDIT) {
                      navigate("/Admin/Utilisateurs/" + user.id + "/Modifier/");
                    }
                  }}
                />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      {data && (
        <Pagination
          currentPage={currentPage}
          totalPages={data!.total_pages}
          onPageChange={function (page: number): void {
            setCurrentPage(page);
          }}
        />
      )}
      <div ref={componentRef}>
        <PrintList
          title={"LISTE DES UTITLISATEURS"}
          centerName={null}
          titles={titles.map((item) => item.label)}
          data={
            data?.data
              .filter(
                (u, index) => selected.length === 0 || selected.includes(u.id)
              )
              .map((item) => [
                item.name,
                item.username,
                item.is_admin ? "Tous" : item.assigned_hospital_name,
              ]) || []
          }
        />
      </div>
    </div>
  );
};

export default UsersPage;
