import React, { useContext, useEffect } from "react";
import MainChart from "../Components/Charts/MainChart";
import "../Components/Charts/mainchart.css";
import Header from "../Components/Header/Header";
import { CurrentUserContext } from "../App";
import axios from "axios";
import { rootUrl } from "../constants";

interface Stats {
  today_operations_count: number;
  today_analyses_count: number;
  today_medicaments_count: number;
  today_tickets_count: number;
  today_revenue: number;
  month_revenue: number;
  year_revenue: number;
  months_revenue: {
    [key: string]: number;
  };
  all_revenue: number;
  all_payments: number;
}

interface SalesStats {
  operations_count: number;
  analyses_count: number;
  medicaments_count: number;
  tickets_count: number;
  alimentations_count: number;
  refunds_count: number;
  operations_revenue: number;
  analyses_revenue: number;
  medicaments_revenue: number;
  tickets_revenue: number;
  total_revenue: number;
  alimentations_revenue: number;
  refunds_revenue: number;
}

const StatisticDashboard = () => {
  const [stats, setStats] = React.useState<Stats | null>(null);
  const [salesStats, setSalesStats] = React.useState<SalesStats | null>(null);
  const token = useContext(CurrentUserContext).currentUserData?.token;
  const currentUserData = useContext(CurrentUserContext).currentUserData!;

  const currentHospitalId = currentUserData.selectedHospitalId;
  const isAdmin = currentUserData.isAdmin;

  const currentHospital = currentUserData.hospitals.find(
    (hospital) => hospital.id === currentHospitalId
  )!;

  const isHospital = currentHospital?.is_hospital ?? true;
  const isPharmacy = currentHospital?.has_pharmacy ?? true;

  const [statsType, setStatsType] = React.useState<"daily" | "monthly">(
    "monthly"
  );
  const [selectedMonth, setSelectedMonth] = React.useState<number>(
    new Date().getMonth() + 1
  );
  const [selectedYear, setSelectedYear] = React.useState<number>(
    new Date().getFullYear()
  );

  const [selectedDate, setSelectedDate] = React.useState<string>(
    new Date().toISOString().slice(0, 10)
  );

  function parseRevenue(revenue: number) {
    return revenue.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  }

  useEffect(() => {
    let url;
    if (isAdmin && !currentHospitalId) {
      url = `${rootUrl}/stats`;
    } else if (currentHospitalId) {
      url = `${rootUrl}/hospital/` + currentHospitalId + "/stats";
    } else {
      return;
    }
    axios
      .get(url, {
        headers: {
          Authorization: "Token " + token,
        },
      })
      .then((response: { data: any }) => {
        // alert(JSON.stringify(response.data));
        setStats(response.data);
      });
  }, [currentHospitalId]);

  useEffect(() => {
    fetchSalesStats();
  }, [selectedDate, selectedMonth, selectedYear, statsType, currentHospitalId]);

  function fetchSalesStats() {
    let day;
    let month;
    let year;
    if (statsType === "daily") {
      day = parseInt(selectedDate.split("-")[2]);
      month = parseInt(selectedDate.split("-")[1]);
      year = parseInt(selectedDate.split("-")[0]);
    } else if (statsType === "monthly") {
      day = undefined;
      month = selectedMonth;
      year = selectedYear;
    } else {
      return;
    }
    let url;
    if (isAdmin && !currentHospitalId) {
      url = `${rootUrl}/stats/sales`;
    } else if (currentHospitalId) {
      url = `${rootUrl}/hospital/` + currentHospitalId + "/stats/sales";
    } else {
      return;
    }
    axios
      .get(url, {
        headers: {
          Authorization: "Token " + token,
        },
        params: {
          day: day,
          month: month,
          year: year,
        },
      })
      .then((response: { data: any }) => {
        setSalesStats(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className="wrapper-all">
      <Header title="Tableau de bord" />

      {(currentHospital?.municipal_tax_data ?? null) === null && stats && (
        <MainChart
          monthSales={stats.months_revenue}
          medicaments={stats.today_medicaments_count}
          operations={stats.today_operations_count}
          analyses={stats.today_analyses_count}
          tickets={stats.today_tickets_count}
          isHospital={isHospital}
          isPharmacy={isPharmacy}
        />
      )}

      <div className="grid grid-cols-3 gap-x-16 gap-y-4 mx-16 mb-10">
        <div className="grid-element">
          <div>{(stats?.today_revenue ?? 0).toFixed(2)} MRU</div>{" "}
          <div className="grid-text">Revenue du Jour</div>
        </div>

        <div className="grid-element">
          <div>{(stats?.month_revenue ?? 0).toFixed(2)} MRU</div>{" "}
          <div className="grid-text">Revenue du Mois</div>
        </div>
        <div className="grid-element">
          <div>{(stats?.year_revenue ?? 0).toFixed(2)} MRU</div>
          <div className="grid-text">Revenue de l'année</div>
        </div>
      </div>
      <div className="flex flex-row gap-x-3 ml-16 mb-10">
        <select
          className="w-40 p-2  text-sm text-gray-900 border border-gray-300 rounded-lg bg-white"
          onChange={(e) => {
            setStatsType(e.target.value as "daily" | "monthly");
          }}
          value={statsType}
        >
          <option value="daily">Par jour</option>
          <option value="monthly">Par mois</option>
        </select>
        <input
          type="date"
          value={selectedDate ?? ""}
          onChange={(e) => {
            setSelectedDate(e.target.value);
          }}
          className={
            "w-40 p-2  text-sm text-gray-900 border border-gray-300 rounded-lg bg-white " +
            `${statsType === "daily" ? "" : "hidden"}`
          }
        />
        <select
          value={selectedMonth}
          onChange={(e) => {
            setSelectedMonth(parseInt(e.target.value));
          }}
          className={
            "w-40 p-2  text-sm text-gray-900 border border-gray-300 rounded-lg bg-white " +
            `${statsType === "monthly" ? "" : "hidden"}`
          }
        >
          <option value="1">Janvier</option>
          <option value="2">Février</option>
          <option value="3">Mars</option>
          <option value="4">Avril</option>
          <option value="5">Mai</option>
          <option value="6">Juin</option>
          <option value="7">Juillet</option>
          <option value="8">Août</option>
          <option value="9">Septembre</option>
          <option value="10">Octobre</option>
          <option value="11">Novembre</option>
          <option value="12">Décembre</option>
        </select>
        <select
          value={selectedYear}
          onChange={(e) => {
            setSelectedYear(parseInt(e.target.value));
          }}
          className={
            "w-24 p-2  text-sm text-gray-900 border border-gray-300 rounded-lg bg-white " +
            `${statsType === "monthly" ? "" : "hidden"}`
          }
        >
          {[
            Array.from({ length: 5 }, (v, i) => {
              const now = new Date();
              const year = now.getFullYear() - 4 + i;
              return <option value={year}>{year}</option>;
            }),
          ]}
        </select>
      </div>
      {salesStats && (
        <div className="flex flex-col gap-y-2">
          <div className="bg-white border gap-y-2 flex flex-col rounded-sm shadow-sm p-4 w-96 self-start ml-16 ">
            <div className=" font-bold text-xl ">
              {(salesStats?.total_revenue ?? 0).toFixed(2) + " MRU"}
            </div>{" "}
            <div className="">Total de Revenue</div>
          </div>
          <hr className="mx-16 my-4" />
          <div
            className={
              "grid  gap-y-2 gap-x-4 mx-16 mb-10 " +
              (isHospital && isPharmacy ? "grid-cols-4" : "grid-cols-3")
            }
          >
            {isHospital && (
              <div className="bg-white border gap-y-2 flex flex-col rounded-sm shadow-sm p-4 ">
                <div className=" font-bold text-xl ">
                  {salesStats?.operations_count ?? 0}
                </div>{" "}
                <div className="">Opérations</div>
              </div>
            )}

            {isPharmacy && (
              <div className="bg-white border gap-y-2 flex flex-col rounded-sm shadow-sm p-4">
                <div className=" font-bold text-xl ">
                  {salesStats?.medicaments_count ?? 0}
                </div>{" "}
                <div className="">Ventes de medicaments</div>
              </div>
            )}
            {currentHospital?.municipal_tax_data === null && (
              <div className="bg-white border gap-y-2 flex flex-col rounded-sm shadow-sm p-4">
                <div className=" font-bold text-xl ">
                  {salesStats?.tickets_count ?? 0}
                </div>{" "}
                <div className="">tickets</div>
              </div>
            )}
            {/* {currentHospital?.municipal_tax_data !== null && (
              <div className="bg-white border gap-y-2 flex flex-col rounded-sm shadow-sm p-4">
                <div className=" font-bold text-xl ">
                  {salesStats?.refunds_count ?? 0}
                </div>{" "}
                <div className="">Remboursements</div>
              </div>
            )}
            {currentHospital?.municipal_tax_data !== null && (
              <div className="bg-white border gap-y-2 flex flex-col rounded-sm shadow-sm p-4">
                <div className=" font-bold text-xl ">
                  {salesStats?.alimentations_count ?? 0}
                </div>{" "}
                <div className="">Alimentations</div>
              </div>
            )} */}

            {isHospital && (
              <div className="bg-white border gap-y-2 flex flex-col rounded-sm shadow-sm p-4 ">
                <div className=" font-bold text-xl ">
                  {parseRevenue(salesStats?.operations_revenue ?? 0) + " MRU"}
                </div>{" "}
                <div className="">Opérations (revenue)</div>
              </div>
            )}
            {isHospital && (
              <div className="bg-white border gap-y-2 flex flex-col rounded-sm shadow-sm p-4">
                <div className=" font-bold text-xl ">
                  {parseRevenue(salesStats?.analyses_revenue ?? 0) + " MRU"}
                </div>{" "}
                <div className="">Analyses (revenue)</div>
              </div>
            )}
            {isPharmacy && (
              <div className="bg-white border gap-y-2 flex flex-col rounded-sm shadow-sm p-4">
                <div className=" font-bold text-xl ">
                  {parseRevenue(salesStats?.medicaments_revenue ?? 0) + " MRU"}
                </div>{" "}
                <div className="">Ventes de medicaments (revenue)</div>
              </div>
            )}
            {currentHospital?.municipal_tax_data === null && (
              <div className="bg-white border gap-y-2 flex flex-col rounded-sm shadow-sm p-4">
                <div className=" font-bold text-xl ">
                  {parseRevenue(salesStats?.tickets_revenue ?? 0) + " MRU"}
                </div>{" "}
                <div className="">tickets (revenue)</div>
              </div>
            )}
            {currentHospital?.municipal_tax_data !== null && (
              <div className="bg-white border gap-y-2 flex flex-col rounded-sm shadow-sm p-4">
                <div className=" font-bold text-xl ">
                  {parseRevenue(salesStats?.refunds_revenue ?? 0) + " MRU"}
                </div>{" "}
                <div className="">Remboursements (total)</div>
              </div>
            )}
            {currentHospital?.municipal_tax_data !== null && (
              <div className="bg-white border gap-y-2 flex flex-col rounded-sm shadow-sm p-4">
                <div className=" font-bold text-xl ">
                  {parseRevenue(salesStats?.alimentations_revenue ?? 0) +
                    " MRU"}
                </div>{" "}
                <div className="">Alimentations (total)</div>
              </div>
            )}
            {currentHospital?.municipal_tax_data !== null && (
              <div className="bg-white border gap-y-2 flex flex-col rounded-sm shadow-sm p-4">
                <div className=" font-bold text-xl ">
                  {parseRevenue(
                    (salesStats?.alimentations_revenue ?? 0) -
                      salesStats?.refunds_revenue ?? 0
                  ) + " MRU"}
                </div>
                <div className="">Reste (total)</div>
              </div>
            )}
          </div>
          <hr className="mx-16 my-4" />
          <div
            className={
              "grid  gap-y-2 gap-x-4 mx-16 mb-10 " +
              (isHospital && isPharmacy ? "grid-cols-4" : "grid-cols-3")
            }
          >
            <div className="bg-white border gap-y-2 flex flex-col rounded-sm shadow-sm p-4">
              <div className=" font-bold text-xl ">
                {parseRevenue(stats?.all_revenue ?? 0) + " MRU"}
              </div>
              <div className="">Revenue Total</div>
            </div>
            <div className="bg-white border gap-y-2 flex flex-col justify-between rounded-sm shadow-sm p-4">
              <div className=" font-bold text-xl ">
                {parseRevenue(stats?.all_payments ?? 0) + " MRU"}
              </div>
              <div className="">Total Versements</div>
            </div>
            <div className="bg-white border gap-y-2 flex flex-col rounded-sm shadow-sm p-4">
              <div className=" font-bold text-xl ">
                {parseRevenue(
                  (stats?.all_revenue ?? 0) - (stats?.all_payments ?? 0)
                ) + " MRU"}
              </div>
              <div className="">Reste</div>
            </div>
          </div>
        </div>
      )}

      {/* {
    "operations_count": 9,
    "analyses_count": 3,
    "medicaments_count": 0,
    "tickets_count": 7,
    "operations_revenue": 29274.0,
    "analyses_revenue": 310.0,
    "medicaments_revenue": 0.0,
    "tickets_revenue": 3200.0
} */}
    </div>
  );
};

export default StatisticDashboard;
