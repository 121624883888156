import React, { ReactNode } from "react";
import { IconType } from "react-icons/lib";

interface OperationStatus {
  status: string;
  icon: ReactNode;
  color: string;
}

const OperationStatus = ({ status, icon, color }: OperationStatus) => {
  return (
    <div
      style={{ color }}
      className="flex items-center justify-start gap-1 text-lg hover:cursor-default"
    >
      <div>{status}</div>
      <div>{icon}</div>
    </div>
  );
};

export default OperationStatus;
