import React, { useContext, useRef } from "react";
import { useState, useEffect } from "react";

import {
  MHeader,
  MTableCell,
  MActionsCell,
  ApiData,
  ActionType,
} from "../Components/table/Table";

import Header from "../Components/Header/Header";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import QuantityInput from "../Components/QuantityInput/QuantityInput";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { Header2 } from "../Components/Header/Header2";
import Pagination from "../Components/pagination/Pagination";
import { rootUrl } from "../constants";
import { CurrentUserContext } from "../App";
import { useReactToPrint } from "react-to-print";
import { Director } from "../interfaces";
import PrintList from "../Components/printable_list";
import { title } from "process";
import { paraseRefundCategory } from "../utils";

export const titles = [
  { label: "Regisseur", sortable: true },
  { label: "Numéro de téléphone", sortable: true },
  { label: "Alimentation", sortable: true },
  { label: "Remboursement", sortable: true },
  { label: "Reste", sortable: true },
];

function AddDirectorModal({ onDone }: { onDone: () => void }) {
  const [selectedDirector, setSelectedDirector] = useState<Director | null>(
    null
  );
  const token = useContext(CurrentUserContext).currentUserData!.token;
  const [name, setName] = useState<string>("");
  const [phone, setPhone] = useState<string>("");

  const currentSelectedHospitalId =
    useContext(CurrentUserContext).currentUserData!.selectedHospitalId;
  const currentHospital = useContext(
    CurrentUserContext
  ).currentUserData!.hospitals.find(
    (hospital) => hospital.id === currentSelectedHospitalId
  )!;

  function submit() {
    if (name.trim() === "" || phone.trim() === "") {
      alert("Veuillez remplir tous les champs");
      return;
    }

    axios
      .post(
        rootUrl + `/hospital/${currentSelectedHospitalId}/directors`,
        {
          name: name,
          phone_number: phone,
          municipal: currentHospital.municipal_tax_data!.id,
        },
        {
          headers: {
            Authorization: "Token " + token,
          },
        }
      )
      .then((response: { data: any }) => {
        onDone();
      })
      .catch((error: any) => {
        alert("Une erreur s'est produite" + error);
      });
  }

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className="flex flex-col bg-white shadow-xl p-4 rounded w-[500px]"
    >
      <h2 className=" self-start font-medium text-2xl">Ajouter un regisseur</h2>
      <input
        className=" mt-5 border border-gray-400 rounded-md px-4 py-2 w-max "
        placeholder="Nom du regisseur"
        value={name}
        onChange={(e) => {
          setName(e.target.value);
        }}
      />
      <input
        className=" mt-5 border border-gray-400 rounded-md px-4 py-2 w-max "
        placeholder="Numéro de téléphone"
        value={phone}
        onChange={(e) => {
          setPhone(e.target.value);
        }}
      />
      <button
        onClick={submit}
        className="mt-5 bg-[#187498] text-white rounded-md px-4 py-2 w-max hover:bg-[#187498] transition-all self-end"
      >
        Ajouter
      </button>
    </div>
  );
}

const DirectorsListPage = () => {
  const componentRef = useRef<HTMLDivElement>(null);
  const [selected, setSelected] = useState<number[]>([]);
  let [currentPage, setCurrentPage] = useState<number>(1);
  const [data, setData] = useState<ApiData<Director> | null>(null);
  const [searchedText, setSearchedText] = useState<string>("");
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const [isAll, setIsAll] = useState<boolean>(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);

  let currentSelectedHospitalId =
    useContext(CurrentUserContext).currentUserData!.selectedHospitalId;

  const currentHospital = useContext(
    CurrentUserContext
  ).currentUserData!.hospitals.find(
    (hospital) => hospital.id === currentSelectedHospitalId
  )!;

  let token = useContext(CurrentUserContext).currentUserData!.token;

  function FetchData() {
    setData(null);
    axios
      .get(rootUrl + "/hospital/" + currentSelectedHospitalId + "/directors", {
        params: {
          page: currentPage,
          search: searchedText,
          all: isAll ? "true" : undefined,
        },

        headers: {
          Authorization: "Token " + token,
        },
      })
      .then((response: { data: any }) => {
        console.log(data);
        setData(response.data);
      })
      .catch((error: any) => {});
  }

  //   function deleteOperation(id: string) {
  //     axios
  //       .delete(
  //         rootUrl + `/hospital/" + currentSelectedHospitalId + "/directors/${id}`,
  //         {
  //           headers: {
  //             Authorization: "Token " + token,
  //           },
  //         }
  //       )
  //       .then((response: { data: any }) => {
  //         console.log(data);
  //         FetchData();
  //       })
  //       .catch((error: any) => {
  //         alert("Une erreur s'est produite" + error);
  //       });
  //   }

  useEffect(() => {
    FetchData();
  }, [currentPage, currentSelectedHospitalId, isAll]);

  useEffect(() => {
    clearTimeout(typingTimeout);

    // Set a new timeout to delay the search
    const timeoutId = setTimeout(() => {
      setSelected([]);
      currentPage = 1; //should be updated but not render
      FetchData();
    }, 500);

    setTypingTimeout(timeoutId);

    return () => {
      clearTimeout(typingTimeout);
    };
  }, [searchedText]);

  const handlePrint = useReactToPrint({
    onBeforeGetContent() {},

    content: () => {
      return componentRef.current;
    },
    onAfterPrint: () => {},
  });

  return (
    <div className="operations-wrapper">
      <Header title="Regisseurs" />
      {
        <Header2
          inputPlaceholder={"Rechercher dans ce tableau"}
          onInputChange={function (text: string): void {
            setSearchedText(text);
          }}
          all={isAll}
          onAllClick={() => {
            setIsAll(!isAll);
          }}
          onAddButtonClick={() => {
            setIsAddModalOpen(true);
          }}
          handlePrint={handlePrint}
        />
      }
      {
        // why it is not working
        isAddModalOpen && (
          <div
            onClick={() => {
              setIsAddModalOpen(false);
            }}
            className="modal"
          >
            <div
              className={
                (isAddModalOpen
                  ? "opacity-100 scale-100"
                  : "opacity-20 scale-50") + " transition-all duration-200"
              }
            >
              <AddDirectorModal
                onDone={() => {
                  setIsAddModalOpen(false);
                  FetchData();
                }}
              />
            </div>
          </div>
        )
      }
      {data === null ? (
        // center loading
        //center
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress thickness={6} sx={{ color: "#0B63F8" }} />
        </div>
      ) : (
        <Table
          style={{
            backgroundColor: "white",
            marginRight: "40px",
            marginLeft: "40px",
          }}
        >
          <MHeader
            titles={titles}
            withActions={false}
            onCheckClick={() => {
              if (selected.length === data?.data.length) {
                setSelected([]);
              } else {
                setSelected(data?.data.map((item) => item.id) || []);
              }
            }}
            isAllSelected={
              data &&
              data!.data.length !== 0 &&
              selected.length === data?.data.length
            }
          />
          <TableBody>
            {data!.data.map((director, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Checkbox
                    checked={selected.includes(director.id)}
                    onChange={() => {
                      if (selected.includes(director.id)) {
                        setSelected(
                          selected.filter((item) => item !== director.id)
                        );
                      } else {
                        setSelected([...selected, director.id]);
                      }
                    }}
                  />
                </TableCell>
                <MTableCell label={director.name} isTitle={false} />

                <MTableCell label={director.phone_number} isTitle={false} />
                <MTableCell
                  label={`${director.total_alimentation} MRU`}
                  isTitle={false}
                />
                <MTableCell
                  label={Object.entries(director.total_refund).map(
                    ([category, amount]) => (
                      <div className="flex flex-row  gap-x-2 text-xs">
                        <span className="font-semibold capitalize">
                          {paraseRefundCategory(category)}
                        </span>
                        <span>{amount + " MRU"}</span>
                      </div>
                    )
                  )}
                  isTitle={false}
                />
                <MTableCell
                  label={`${
                    director.total_alimentation -
                    (Object.values(director.total_refund).reduce(
                      (a, b) => a + b,
                      0
                    ) as number)
                  } MRU`}
                  isTitle={false}
                />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      {data && (
        <Pagination
          currentPage={currentPage}
          totalPages={data!.total_pages}
          onPageChange={function (page: number): void {
            setCurrentPage(page);
          }}
        />
      )}
      <div ref={componentRef}>
        <PrintList
          centerName={currentHospital.name}
          title={"Liste des Regisseurs"}
          titles={titles.map((title) => title.label)}
          data={
            data?.data
              .filter(
                (director, index) =>
                  selected.length === 0 || selected.includes(director.id)
              )
              .map((director, index) => [
                director.name,
                director.phone_number,
                `${director.total_alimentation} MRU`,
                Object.entries(director.total_refund).map(
                  ([category, amount]) => (
                    <div className="flex flex-row  gap-x-2 text-xs">
                      <span className="font-semibold capitalize">
                        {paraseRefundCategory(category)}
                      </span>
                      <span>{amount + " MRU"}</span>
                    </div>
                  )
                ),
                `${
                  director.total_alimentation -
                  (Object.values(director.total_refund).reduce(
                    (a, b) => a + b,
                    0
                  ) as number)
                } MRU`,
              ]) ?? []
          }
        />
      </div>
    </div>
  );
};

export default DirectorsListPage;
