import React, { useContext, useRef } from "react";
import { useState, useEffect } from "react";

import {
  MHeader,
  MTableCell,
  MActionsCell,
  ApiData,
  ActionType,
} from "../Components/table/Table";

import Header from "../Components/Header/Header";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { Header2 } from "../Components/Header/Header2";
import Pagination from "../Components/pagination/Pagination";
import { rootUrl } from "../constants";
import { useReactToPrint } from "react-to-print";
import { duration } from "@mui/material";
import { CurrentUserContext } from "../App";
import PrintList from "../Components/printable_list";
import ModalSupression from "../Components/Modal/ModalSupression";
import { FilterComponent, FilterProps } from "../Components/filter_component";

interface VenteData {
  id: number;
  total: number;
  quantity: number;
  date: string;
  insurance_number: string;
  names: string[];
  patient: string;
  staff_name: string;
}

const Ventes = () => {
  const [printing, setPrinting] = useState<boolean>(false);

  const componentRef = useRef<HTMLDivElement>(null);
  const [selected, setSelected] = useState<number[]>([]);
  let [currentPage, setCurrentPage] = useState<number>(1);
  const [data, setData] = useState<ApiData<VenteData> | null>(null);
  const [searchedText, setSearchedText] = useState<string>("");
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const [isAll, setIsAll] = useState<boolean>(false);
  const [filterProps, setFilterProps] = useState<FilterProps>({
    statsType: null,
    selectedDate: null,
    selectedMonth: null,

    selectedYear: null,
    selectedUserId: null,
  });

  const [deletingId, setDeletingId] = useState<number | null>(null);

  const navigate = useNavigate();
  const [error, setError] = useState(null);

  let token = localStorage.getItem("token");

  let currentSelectedHospitalId =
    useContext(CurrentUserContext).currentUserData!.selectedHospitalId;
  const currentHospital = useContext(
    CurrentUserContext
  ).currentUserData!.hospitals.find((h) => h.id === currentSelectedHospitalId);
  const isAdmin = useContext(CurrentUserContext).currentUserData!.isAdmin;

  const titles = [
    // { label: "Médicaments", sortable: true },
    { label: "Date", sortable: false },
    { label: "Médicaments", sortable: false },
    { label: "Montant Payé", sortable: true },
    { label: "Quantité", sortable: true },
    { label: "Patient", sortable: true },
    { label: "Numéro du reçu", sortable: true },
    ...(isAdmin
      ? [
          { label: "Caissier Responsable", sortable: true, printable: false },
          { label: "", sortable: true, printable: false },
        ]
      : []),
  ];

  function FetchData() {
    let filterParams = {};

    if (filterProps.statsType === "daily") {
      filterParams = {
        year: filterProps.selectedDate?.split("-")[0] ?? undefined,
        month: filterProps.selectedDate?.split("-")[1] ?? undefined,
        day: filterProps.selectedDate?.split("-")[2] ?? undefined,
      };
    } else if (filterProps.statsType === "monthly") {
      filterParams = {
        year: filterProps.selectedYear ?? undefined,
        month: filterProps.selectedMonth ?? undefined,
      };
    }
    filterParams = {
      ...filterParams,
      user: filterProps.selectedUserId ?? undefined,
      all: isAll ? "true" : undefined,
    };
    axios
      .get(rootUrl + "/hospital/" + currentSelectedHospitalId + "/sales", {
        params: { page: currentPage, search: searchedText, ...filterParams },
        headers: {
          Authorization: "Token " + token,
        },
      })
      .then((response: { data: any }) => {
        let list = response.data.data;
        let sales: VenteData[] = [];
        for (let i = 0; i < list.length; i++) {
          const date = new Date(list[i].created_at);
          const formattedDate = date.toLocaleDateString("fr-FR");
          sales.push({
            names: list[i].items.map((item: any) => item.name),
            patient: list[i].patient,
            insurance_number: list[i].insurance_number,
            id: list[i].id,

            total: list[i].items.reduce(
              (a: any, b: any) => a + parseFloat(b.payed_price),
              0
            ),
            quantity: list[i].items.reduce(
              (a: any, b: any) => a + b.quantity,
              0
            ),
            date: formattedDate,
            staff_name: list[i].staff_name,
          });
          // alert(typeof sales[i].total);
        }

        setData({
          category: "",
          count: response.data.count as number,
          page: response.data.page as number,
          total_pages: response.data.total_pages as number,
          data: sales as VenteData[],
          total: response.data.total,
        });
      })
      .catch((error: any) => {
        setError(error);
      });
  }

  function deleteSale(id: number) {
    axios
      .delete(
        rootUrl + `/hospital/` + currentSelectedHospitalId + `/sales/${id}`,
        {
          headers: {
            Authorization: "Token " + token,
          },
        }
      )
      .then((response: { data: any }) => {
        console.log(data);
        FetchData();
      })
      .catch((error: any) => {
        alert("Une erreur s'est produite" + error);
      });
  }

  useEffect(() => {
    FetchData();
  }, [currentPage, filterProps, isAll]);
  useEffect(() => {
    setFilterProps({
      ...filterProps,
      selectedUserId: null,
    });
  }, [currentSelectedHospitalId]);

  useEffect(() => {
    clearTimeout(typingTimeout);

    // Set a new timeout to delay the search
    const timeoutId = setTimeout(() => {
      setSelected([]);
      currentPage = 1; //should be updated but not render
      FetchData();
    }, 500);

    setTypingTimeout(timeoutId);

    return () => {
      clearTimeout(typingTimeout);
    };
  }, [searchedText]);
  const handlePrint = useReactToPrint({
    onBeforeGetContent() {
      setPrinting(true);
    },

    content: () => {
      return componentRef.current;
    },
    onAfterPrint: () => {
      setPrinting(false);
    },
  });

  return (
    <div className="operations-wrapper">
      {deletingId && (
        <ModalSupression
          open={false}
          name={`la vente sélectionnée`}
          onClose={function (): void {
            setDeletingId(null);
          }}
          onConfirmClick={function (): void {
            deleteSale(deletingId);
            setDeletingId(null);
          }}
        />
      )}
      <Header title="Pharmacy" />
      {
        <Header2
          inputPlaceholder={"Rechercher dans ce tableau"}
          onInputChange={function (text: string): void {
            setSearchedText(text);
          }}
          onAddButtonClick={
            !isAdmin
              ? function (): void {
                  navigate("/ventes/Ajouter");
                }
              : null
          }
          all={isAll}
          onAllClick={function (): void {
            setIsAll(!isAll);
            setCurrentPage(1);
          }}
          handlePrint={handlePrint}
        />
      }
      {data === null ? (
        // center loading
        //center
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress thickness={6} sx={{ color: "#0B63F8" }} />
        </div>
      ) : (
        <div className="flex flex-col gap-y-2">
          {isAdmin && (
            <div className="flex flex-col  gap-y-2 items-start justify-center ml-10 mb-5">
              <FilterComponent
                filterProps={filterProps}
                setFilterProps={(props: FilterProps) => {
                  setFilterProps(props);
                  setCurrentPage(1);
                }}
              />
              {data.total !== undefined && (
                <div className="flex gap-x-2">
                  <span>Total :</span>
                  <span className="font-bold">
                    {(data.total ?? 0) + " MRU"}
                  </span>
                </div>
              )}
            </div>
          )}
          <Table
            style={{
              backgroundColor: "white",
              marginRight: "40px",
              marginLeft: "40px",
              width: "95.5%",
            }}
          >
            <MHeader
              titles={titles}
              withActions={false}
              onCheckClick={() => {
                if (selected.length === data?.data.length) {
                  setSelected([]);
                } else {
                  setSelected(data?.data.map((item) => item.id) || []);
                }
              }}
              isAllSelected={
                data &&
                data!.data.length !== 0 &&
                selected.length === data?.data.length
              }
            />
            <TableBody>
              {data!.data.map((vente, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Checkbox
                      checked={selected.includes(vente.id)}
                      onChange={() => {
                        if (selected.includes(vente.id)) {
                          setSelected(
                            selected.filter((item) => item !== vente.id)
                          );
                        } else {
                          setSelected([...selected, vente.id]);
                        }
                      }}
                    />
                  </TableCell>

                  <MTableCell label={vente.date} isTitle={false} />
                  <MTableCell label={vente.names.join(", ")} isTitle={false} />
                  <MTableCell label={`${vente.total} MRU`} isTitle={false} />

                  <MTableCell label={vente.quantity} isTitle={false} />
                  <MTableCell label={vente.patient} isTitle={false} />

                  <MTableCell
                    label={vente.id.toString().padStart(6, "0")}
                    isTitle={false}
                  />
                  {isAdmin && (
                    <MTableCell label={vente.staff_name} isTitle={false} />
                  )}
                  {isAdmin && (
                    <MTableCell
                      label={
                        <button
                          onClick={() => {
                            setDeletingId(vente.id);
                          }}
                          className="bg-red-400 border-2 text-sm font-medium text-white border-red-400 rounded py-2 px-3 hover:bg-transparent hover:text-red-500 active:scale-95 transition-all "
                        >
                          Supprimer
                        </button>
                      }
                      isTitle={false}
                    />
                  )}
                  {/* show the date in formtted way */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      )}
      {data && (
        <Pagination
          currentPage={currentPage}
          totalPages={data!.total_pages}
          onPageChange={function (page: number): void {
            setCurrentPage(page);
          }}
        />
      )}
      <div ref={componentRef}>
        <PrintList
          centerName={currentHospital?.name ?? ""}
          title="Liste des ventes"
          afterTable={
            <div className="flex justify-between w-full">
              <div className="flex gap-x-2">
                <span>Total :</span>
                <span className="font-bold">{(data?.total ?? 0) + " MRU"}</span>
              </div>
              {filterProps.selectedUserId && (
                <div>
                  <span>Caissier Responsable :</span>

                  <span className="font-bold">
                    {currentHospital!.staff_data.find(
                      (u) => u.id === filterProps.selectedUserId
                    )?.name ?? ""}
                  </span>
                </div>
              )}
            </div>
          }
          titles={titles
            .filter((t) => t.label !== "")
            .map((title) => title.label)}
          data={
            data?.data.map((vente) => [
              vente.date,
              vente.names.join(", "),
              vente.total,
              vente.quantity,
              vente.patient,

              vente.id.toString().padStart(6, "0"),
              ...(isAdmin ? [vente.staff_name] : []),
            ]) ?? []
          }
        />
      </div>
    </div>
  );
};

export default Ventes;
