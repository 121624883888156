import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { medicationSchema } from "../Validations/MedicamentValidation";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import "./ajouter.css";
import { useParams } from "react-router-dom";
import { MedicationItem } from "../interfaces";
import CircularProgress from "@mui/material/CircularProgress";
import { rootUrl } from "../constants";
import { CurrentUserContext } from "../App";
import { MDropDown } from "../Components/mdropdown/mdropdown";
import { MdLogout } from "react-icons/md";
import { FaRegBell } from "react-icons/fa";
import { useReactToPrint } from "react-to-print";

const UpdateAddMedicamentPage = () => {
  const [printing, setPrinting] = useState<boolean>(false);
  const componentRef = useRef<HTMLDivElement>(null);
  const [error, setError] = useState(null);
  const [categories, setCategories] = useState<string[]>([]);
  const { id: medicamentId } = useParams();
  const [medicamentData, setMedicamentData] = useState<MedicationItem | null>(
    null
  );
  const navigate = useNavigate();

  let currentSelectedHospitalId =
    useContext(CurrentUserContext).currentUserData!.selectedHospitalId;

  let token = useContext(CurrentUserContext).currentUserData!.token;

  useEffect(() => {
    if (medicamentId) {
      axios
        .get(
          rootUrl +
            "/hospital/" +
            currentSelectedHospitalId +
            "/inventory/" +
            medicamentId,
          {
            headers: {
              Authorization: "Token " + token,
            },
          }
        )
        .then((response: { data: any }) => {
          console.log("data returned : ");
          console.log(response);
          setMedicamentData(response.data);
          setQuantityValue(response.data.quantity);
        })
        .catch((error: any) => {
          navigate("/Médicaments");
        });
    }
  }, []);

  const [quantityValue, setQuantityValue] = useState(0);
  const increaseQuantity = () => {
    setQuantityValue((prevValue) => prevValue + 1);
  };
  const decreaseQuantity = () => {
    setQuantityValue((prevValue) => prevValue - 1);
  };

  const [criticalValue, setCriticalValue] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const increaseCritical = () => {
    setCriticalValue((prevValue) => prevValue + 1);
  };
  const decreaseCritical = () => {
    setCriticalValue((prevValue) => prevValue - 1);
  };

  const handletypingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(e.target.value, 10) || 0;
    setQuantityValue(newValue);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(medicationSchema),
  });

  const submitForm = (data: any) => {
    if (quantityValue < 0) {
      alert("La quantité ne peut pas être négative");
      return;
    }
    const newProduct = {
      name: data.name,
      price: data.price,
      description: data.description,
      quantity: quantityValue,
      category: data.category,
      critical: data.critical,
      hospital: currentSelectedHospitalId,
    };

    if (medicamentId) {
      axios
        .put(
          rootUrl +
            "/hospital/" +
            currentSelectedHospitalId +
            "/inventory/" +
            medicamentId,
          newProduct,
          {
            headers: {
              Authorization: "Token " + token,
            },
          }
        )
        .then((response) => {
          navigate("/Médicaments");
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      setIsSubmitting(true);

      axios
        .post(
          rootUrl + "/hospital/" + currentSelectedHospitalId + "/inventory",
          newProduct,
          {
            headers: {
              Authorization: "Token " + token,
            },
          }
        )
        .then((response) => {
          // handlePrint();
          navigate("/Médicaments");
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  };

  const isAdmin = useContext(CurrentUserContext).currentUserData!.isAdmin;
  return (
    <div className="ajouter-wrapper">
      <h1 className="title">
        {medicamentId ? "Mettre à jour" : "Ajouter un médicament"}{" "}
      </h1>
      <div className="positioned-buttons">
        <button className="notif-button">
          <FaRegBell />
        </button>
        <button className="logout-button">
          <MdLogout />
        </button>
      </div>
      {medicamentId && !medicamentData ? (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress thickness={6} sx={{ color: "#0B63F8" }} />
        </div>
      ) : (
        <form onSubmit={handleSubmit(submitForm)}>
          <div className="row">
            <div className="column-side">
              <div className="input-grp">
                <label className="label" htmlFor="name">
                  Le nom du médicament
                </label>
                <div className="input-p">
                  <input
                    className="input-1"
                    defaultValue={medicamentData?.name ?? ""}
                    {...register("name")}
                    type="text"
                    name="name"
                  />
                  <p className="error-msg">{errors.name?.message}</p>
                </div>
              </div>

              <div className="input-grp">
                <label className="label" htmlFor="quantity">
                  Quantité
                </label>
                <div className="input-q">
                  <input
                    className="input-1"
                    type="number"
                    disabled={isAdmin ? false : true}
                    value={quantityValue}
                    defaultValue={medicamentData?.quantity ?? ""}
                    name="quantity"
                    onChange={handletypingChange}
                  />
                  {/* {isAdmin && (
                    <div className="quantity-buttons flex items-center">
                      <div
                        onClick={decreaseQuantity}
                        className=" cursor-pointer hover:black text-black px-2"
                      >
                        -
                      </div>
                      |
                      <div
                        onClick={increaseQuantity}
                        className=" cursor-pointer hover:black text-black px-2"
                      >
                        +
                      </div>
                    </div>
                  )} */}
                </div>
              </div>
            </div>

            <div className="column-side">
              <div className="input-grp"></div>

              <div className="input-grp">
                <label className="label" htmlFor="price">
                  Prix
                </label>
                <div className="input-p">
                  <input
                    className="input-1"
                    type="number"
                    defaultValue={medicamentData?.price ?? ""}
                    {...register("price")}
                    name="price"
                  />
                  <p className="currency">MRU</p>
                  <p className="error-msg">{errors.price?.message}</p>
                </div>
              </div>
              {/* <div className="input-grp">
                <div
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <label className="label" htmlFor="quantity">
                    Niveau de Stock Critique
                  </label>
                  <div className="disclaimer">!</div>
                </div>

                 <div className="input-q">
                  <input
                    className="input-1"
                    value={criticalValue}
                    type="number"
                    defaultValue={medicamentData?.critical ?? ""}
                    {...register("critical")}
                    name="quantity"
                  />
                  <div className="quantity-buttons">
                    <button
                      className="minus-plus-buttons"
                      onClick={decreaseCritical}
                    >
                      -
                    </button>
                    |
                    <button
                      className="minus-plus-buttons"
                      onClick={increaseCritical}
                    >
                      +
                    </button>
                  </div>
                  <p className="error-msg">{errors.critical?.message}</p>
                </div>  
               </div> */}
            </div>
          </div>

          <input
            className="ajouter-button"
            disabled={isSubmitting}
            type="submit"
            value={medicamentId ? "Modifier" : "Ajouter"}
          />
        </form>
      )}
      <div
        ref={componentRef}
        className="print-only"
        style={{ fontFamily: "Poppins", margin: "5px" }}
      >
        <h1
          style={{
            textDecoration: "underline",
            fontSize: "16px",
            fontWeight: "bold",
          }}
        >
          Reçu de vente
        </h1>
        <div style={{ display: "flex", gap: "10px" }}>
          <p style={{ fontWeight: "bold" }}>Nom du patient:</p>
          <p>{medicamentData?.name}</p>
        </div>

        <div style={{ display: "flex", gap: "10px" }}>
          <p style={{ fontWeight: "bold" }}>Prix:</p>
          <p>{medicamentData?.price}</p>
        </div>
      </div>
    </div>
  );
};

export default UpdateAddMedicamentPage;
