import React, { useContext, useRef } from "react";
import { useState, useEffect } from "react";

import {
  MHeader,
  MTableCell,
  ApiData,
  ActionType,
} from "../Components/table/Table";
import "../Components/table/table.css";

import Header from "../Components/Header/Header";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import QuantityInput from "../Components/QuantityInput/QuantityInput";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { Header2 } from "../Components/Header/Header2";
import ModalSupression from "../Components/Modal/ModalSupression";

import { HospitalInterface, VersementInterface } from "../interfaces";
import Pagination from "../Components/pagination/Pagination";
import { rootUrl } from "../constants";
import { CurrentUserContext } from "../App";
import { useReactToPrint } from "react-to-print";
import PrintList from "../Components/printable_list";
export const titles = [
  { label: "Centre", sortable: true },
  { label: "Numéro de quittance", sortable: true },
  { label: "C.Bud", sortable: true },
  { label: "De", sortable: true },
  { label: "À", sortable: true },
  { label: "Montant", sortable: true },
];

const Versement = () => {
  const componentRef = useRef<HTMLDivElement>(null);
  const [printing, setPrinting] = useState<boolean>(false);

  const [selected, setSelected] = useState<number[]>([]);
  let [currentPage, setCurrentPage] = useState<number>(1);
  const [data, setData] = useState<ApiData<VersementInterface> | null>(null);
  const [searchedText, setSearchedText] = useState<string>("");
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const [quantityTimeouts, setQuantityTimeouts] = useState<
    Map<number, NodeJS.Timeout>
  >(new Map());

  const navigate = useNavigate();
  const [error, setError] = useState(null);

  let currentuserContexxt = useContext(CurrentUserContext);

  let token = useContext(CurrentUserContext).currentUserData!.token;

  const [selectedHospitalIndex, setSelectedHospitalIndex] = useState<number>(0);
  const [isPharmacy, setIsPharmacy] = useState<boolean>(false);

  function FetchData() {
    setData(null);
    const currentSelectedHospitalId =
      currentuserContexxt.currentUserData!.hospitals[selectedHospitalIndex].id;
    axios
      .get(rootUrl + "/hospital/" + currentSelectedHospitalId + "/payments", {
        params: {
          page: currentPage,
          search: searchedText,
          for_pharmacy: isPharmacy ? "True" : "False",
        },
        headers: {
          Authorization: "Token " + token,
        },
      })
      .then((response: { data: any }) => {
        console.log(data);
        setData(response.data);
      })
      .catch((error: any) => {
        setError(error);
      });
  }

  useEffect(() => {
    FetchData();
  }, [currentPage, selectedHospitalIndex, isPharmacy]);

  useEffect(() => {
    clearTimeout(typingTimeout);

    // Set a new timeout to delay the search
    const timeoutId = setTimeout(() => {
      setSelected([]);
      currentPage = 1; //should be updated but not render
      FetchData();
    }, 500);

    setTypingTimeout(timeoutId);

    return () => {
      clearTimeout(typingTimeout);
    };
  }, [searchedText]);
  const handlePrint = useReactToPrint({
    onBeforeGetContent() {
      setPrinting(true);
    },

    content: () => {
      return componentRef.current;
    },
    onAfterPrint: () => {
      setPrinting(false);
    },
  });

  return (
    <div className="hopitaux-wrapper">
      <Header title={"Liste des versements"} />
      <div className="flex gap-x-2 ml-10 font-pop font-medium">
        {currentuserContexxt.currentUserData?.hospitals.map((h, index) => {
          return (
            <button
              onClick={() => {
                setSelectedHospitalIndex(index);
                setSelected([]);
                setIsPharmacy(false);
                setCurrentPage(1);
              }}
              className={`font-semibold rounded-sm text-black  p-2 transition-all ${
                selectedHospitalIndex === index ? "bg-[#187498] text-white" : ""
              }`}
            >
              <p>{h.name}</p>
            </button>
          );
        })}
      </div>
      {currentuserContexxt.currentUserData?.hospitals[selectedHospitalIndex]
        .has_pharmacy && (
        <div className="flex flex-col gap-y-2 mt-2 ml-10">
          <hr className="w-96 border-slate-400" />

          <div className="flex gap-x-2 font-pop font-medium ">
            <button
              onClick={() => {
                setIsPharmacy(false);
                setCurrentPage(1);
              }}
              className={`font-semibold rounded-sm text-black  p-2 transition-all ${
                !isPharmacy ? "bg-[#187498] text-white" : ""
              }`}
            >
              <p>Hopital</p>
            </button>
            <button
              onClick={() => {
                setIsPharmacy(true);
                setCurrentPage(1);
              }}
              className={`font-semibold rounded-sm text-black  p-2 transition-all ${
                isPharmacy ? "bg-[#187498] text-white" : ""
              }`}
            >
              <p>Pharmacy</p>
            </button>
          </div>
        </div>
      )}
      {
        <Header2
          inputPlaceholder={"Rechercher dans ce tableau"}
          onInputChange={function (text: string): void {
            setSearchedText(text);
          }}
          handlePrint={handlePrint}
        />
      }
      <div className="flex ml-10 mb-4 ">
        <span className="font-semibold">Total : </span>
        <span>
          {data?.data
            .filter(
              (d, index) => selected.length === 0 || selected.includes(d.id)
            )
            .reduce((acc, item) => {
              return acc + item.amount;
            }, 0)}
        </span>
      </div>
      {data === null ? (
        // center loading
        //center
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress thickness={6} sx={{ color: "#0B63F8" }} />
        </div>
      ) : (
        <Table
          style={{
            backgroundColor: "white",
            marginRight: "40px",
            marginLeft: "40px",
            width: "95.5%",
          }}
        >
          <MHeader
            titles={titles}
            withActions={false}
            onCheckClick={() => {
              if (selected.length === data?.data.length) {
                setSelected([]);
              } else {
                setSelected(data?.data.map((item) => item.id) || []);
              }
            }}
            isAllSelected={
              data &&
              data!.data.length !== 0 &&
              selected.length === data?.data.length
            }
          />
          <TableBody>
            {data!.data.map((versement, index) => (
              <TableRow key={index}>
                <TableCell size="small">
                  <Checkbox
                    checked={selected.includes(versement.id)}
                    onChange={() => {
                      if (selected.includes(versement.id)) {
                        setSelected(
                          selected.filter((item) => item !== versement.id)
                        );
                      } else {
                        setSelected([...selected, versement.id]);
                      }
                    }}
                  />
                </TableCell>
                <MTableCell label={versement.hospital_name} isTitle={false} />

                <MTableCell
                  label={`${versement.quittance_number}`}
                  isTitle={false}
                />
                <MTableCell label={`${versement.account}`} isTitle={false} />
                <MTableCell label={`${versement.start_date}`} isTitle={false} />
                <MTableCell label={versement.payed_for} isTitle={false} />
                <MTableCell label={`${versement.amount}`} isTitle={false} />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      {data && (
        <Pagination
          currentPage={currentPage}
          totalPages={data!.total_pages}
          onPageChange={function (page: number): void {
            setCurrentPage(page);
          }}
        />
      )}
      <div ref={componentRef}>
        <PrintList
          centerName={
            currentuserContexxt.currentUserData!.hospitals[
              selectedHospitalIndex
            ].name
          }
          title={"Liste des versements"}
          titles={titles.map((title) => title.label)}
          showRegisseur={true}
          afterTable={
            <div className="flex">
              <span className="font-semibold">Total : </span>
              <span>
                {data?.data
                  .filter(
                    (d, index) =>
                      selected.length === 0 || selected.includes(d.id)
                  )
                  .reduce((acc, item) => {
                    return acc + item.amount;
                  }, 0)}
              </span>
            </div>
          }
          data={
            data?.data
              .filter(
                (versement) =>
                  selected.length === 0 || selected.includes(versement.id)
              )
              .map((versement) => [
                versement.hospital_name,
                versement.quittance_number,
                versement.account,
                versement.start_date,
                versement.payed_for,
                versement.amount,
              ]) ?? []
          }
        />
      </div>
    </div>
  );
};

export default Versement;

function CheckRefuseActions({
  onClick,
}: {
  onClick: (value: boolean) => void;
}) {
  return (
    <TableCell>
      <div className="flex flex-row gap-x-5">
        <svg
          onClick={() => {
            onClick(true);
          }}
          className="w-7 h-7 hover:scale-110 transition-all cursor-pointer items-center"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            {" "}
            <path
              d="M2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12Z"
              stroke="#3a9933"
              stroke-width="1.5"
            ></path>{" "}
            <path
              d="M8.5 12.5L10.5 14.5L15.5 9.5"
              stroke="#3a9933"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>{" "}
          </g>
        </svg>
        <svg
          onClick={() => {
            onClick(false);
          }}
          className="w-7 h-7 hover:scale-110 transition-all cursor-pointer items-center"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            {" "}
            <path
              d="M12.9998 8L6 14L12.9998 21"
              stroke="#f25f5f"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>{" "}
            <path
              d="M6 14H28.9938C35.8768 14 41.7221 19.6204 41.9904 26.5C42.2739 33.7696 36.2671 40 28.9938 40H11.9984"
              stroke="#f25f5f"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>{" "}
          </g>
        </svg>
      </div>
    </TableCell>
  );
}
