import React, { useContext, useEffect, useRef, useState } from "react";
import "./ajouter_vente.css";
import { MDropDown } from "../Components/mdropdown/mdropdown";
import { MedicationItem } from "../interfaces";
import { CurrentUserContext } from "../App";
import axios from "axios";
import { rootUrl } from "../constants";
import { useNavigate } from "react-router-dom";
import { FaRegBell } from "react-icons/fa";
import { MdLogout } from "react-icons/md";
import { useReactToPrint } from "react-to-print";
import {
  PrintableTicket,
  ticketPageStyle,
} from "../Components/printable_ticket";

interface SaleItem {
  medicament: MedicationItem;
  quantity: number;
}

interface SaleItemRowProps {
  saleItem: SaleItem;
  onInputChange: (text: string) => void;
  onQuantityChange: (value: number) => void;
}
const SaleItemRow = ({
  saleItem,
  onInputChange,
  onQuantityChange,
}: SaleItemRowProps) => {
  const { medicament, quantity } = saleItem;
  const navigate = useNavigate();

  return (
    <div className="sale-item-row">
      <div style={{ flexGrow: 1 }}>
        <MDropDown
          value={medicament.name}
          onChange={function (value: string): void {}}
          placeholder={"Saisir le médicament vendu"}
          values={["aijdd", "sdnaud", "ahdsad", "djnsdjfnsd", "idjjs"]}
        />
      </div>

      <div className="input-q" style={{ width: "180px" }}>
        <input
          className="sale-item-row-medicament"
          type="number"
          value={quantity}
          onChange={(e) => {
            onQuantityChange(parseInt(e.target.value));
          }}
          // defaultValue={medicamentData?.quantity ?? ""}
          // {...register("quantity")}
          name="quantity"
          // onChange={handletypingChange}
        />
        <div className="quantity-buttons">
          <button
            className="minus-plus-buttons"
            onClick={() => {
              onQuantityChange(quantity - 1);
            }}
          >
            -
          </button>
          |
          <button
            className="minus-plus-buttons"
            onClick={() => {
              onQuantityChange(quantity + 1);
            }}
          >
            +
          </button>
        </div>
      </div>
    </div>
  );
};

export const AddSalePage = () => {
  const componentRef = useRef<HTMLDivElement>(null);
  const [items, setItems] = React.useState<SaleItem[]>([]);
  const [searchResult, setSearchResult] = React.useState<MedicationItem[]>([]);
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  const [patient, setPatient] = React.useState<string>("");

  const [newSaleId, setNewSaleId] = React.useState<number | null>(null);

  let token = useContext(CurrentUserContext).currentUserData!.token;
  let currentSelectedHospitalId =
    useContext(CurrentUserContext).currentUserData!.selectedHospitalId;

  let currentHospital = useContext(
    CurrentUserContext
  ).currentUserData!.hospitals.find((h) => h.id === currentSelectedHospitalId)!;

  const navigate = useNavigate();

  const handlePrint = useReactToPrint({
    onBeforeGetContent() {},
    pageStyle: ticketPageStyle,

    content: () => {
      return componentRef.current;
    },
    onAfterPrint: () => {},
  });

  function searchMedicaments(text: string) {
    setSearchResult([]);
    axios
      .get(rootUrl + "/hospital/" + currentSelectedHospitalId + "/inventory", {
        params: { page: 1, search: text },
        headers: {
          Authorization: "Token " + token,
        },
      })
      .then((response: { data: any }) => {
        setSearchResult(response.data.data);
      })
      .catch((error: any) => {});
  }

  return (
    <div className="sale-add-wrapper">
      <div>
        <h1 className="title">{"Ajouter une vente"} </h1>
        <div className="positioned-buttons">
          <button className="notif-button">
            <FaRegBell />
          </button>
          <button className="logout-button">
            <MdLogout />
          </button>
        </div>
        <div className="flex flex-rows w-[min(600px,80%)] gap-x-3">
          <input
            className="input-1"
            placeholder="Patient"
            value={patient}
            onChange={function (e: React.ChangeEvent<HTMLInputElement>): void {
              setPatient(e.target.value);
            }}
          />
        </div>

        <hr className="my-10 border-1 bg-gray-800" />
        <div style={{ flex: "column" }}>
          {items.map((item) => {
            return (
              <SaleItemRow
                saleItem={item}
                onInputChange={function (text: string): void {}}
                onQuantityChange={function (value: number): void {
                  if (value === 0) {
                    setItems(
                      items.filter(
                        (i) => i.medicament.id !== item.medicament.id
                      )
                    );
                    return;
                  }
                  if (value > 0 && item.medicament.quantity >= value) {
                    item.quantity = value;
                    setItems([...items]);
                  }
                }}
              />
            );
          })}

          <div style={{ width: "min(600px, 80%)" }}>
            <MedicsDropDown
              onChange={(value: string) => {
                searchMedicaments(value);
              }}
              onSelected={function (value: number): void {
                let med = searchResult.find((med) => med.id === value)!;
                if (items.find((item) => item.medicament.id === med.id)) {
                  return;
                }
                if (med.quantity === 0) {
                  alert("Ce médicament est en rupture de stock");
                  return;
                }
                setItems([
                  ...items,
                  { medicament: med, quantity: med.quantity > 0 ? 1 : 0 },
                ]);
              }}
              placeholder={"Saisir le médicament vendu"}
              values={searchResult}
            />
            <button
              disabled={isSubmitting}
              className="add-button"
              onClick={() => {
                if (!patient) {
                  alert("Veuillez saisir le nom du patient");
                  return;
                } else if (items.length === 0) {
                  alert("Veuillez saisir au moins un médicament");
                  return;
                }

                let itemsToSend = items.map((item) => {
                  const price = item.medicament.price * item.quantity;

                  return {
                    quantity: item.quantity,
                    payed_price: price,
                    medicament: item.medicament.id,
                  };
                });
                setIsSubmitting(true);
                axios
                  .post(
                    rootUrl +
                      "/hospital/" +
                      currentSelectedHospitalId +
                      "/sales",
                    {
                      items: itemsToSend,
                      patient: patient,

                      hospital: currentSelectedHospitalId,
                    },
                    {
                      headers: {
                        Authorization: "Token " + token,
                      },
                    }
                  )
                  .then((response) => {
                    setNewSaleId(response.data.id);
                    setTimeout(() => {
                      handlePrint();
                      navigate("/ventes");
                    }, 10);

                    console.log(response);
                  })
                  .catch((error) => {
                    console.log(error);
                  })
                  .finally(() => {
                    setIsSubmitting(false);
                  });
              }}
              style={{ marginTop: "40px" }}
            >
              Ajouter
            </button>
          </div>
        </div>
      </div>
      <div ref={componentRef}>
        <PrintableTicket
          id={newSaleId?.toString() ?? ""}
          centerName={currentHospital.name}
          title={"Reçu de Vente de Médicaments"}
          data={{
            "Nom du patient": patient,
            Date:
              new Date().toLocaleDateString() +
              " - " +
              new Date().toLocaleTimeString(),
          }}
          moreData={[
            ...items.map((item) => {
              return (
                <div className="flex items-center gap-4">
                  <p className="font-semibold text-gray-600">Médicament : </p>
                  <p>
                    {item.medicament.name}
                    {item.medicament.name.length > 0 ? ":" : ""}
                  </p>
                  <p className="font-semibold text-gray-600">Quantité : </p>
                  <p>{item.quantity}</p>
                  <p className="font-semibold text-gray-600">Prix : </p>
                  <p>{item.medicament.price * item.quantity + " MRU"}</p>
                </div>
              );
            }),
            <div className="flex items-center gap-4">
              <p className="font-semibold text-gray-600">Total :</p>
              <p>
                {items
                  .reduce((acc, item) => {
                    return acc + item.medicament.price * item.quantity;
                  }, 0)
                  .toFixed(2) + " MRU"}
              </p>
            </div>,
          ]}
        />
      </div>
    </div>
  );
};
interface MedicsDropDownProps {
  onSelected: (value: number) => void;
  onChange: (value: string) => void;
  placeholder: string;
  values: MedicationItem[];
}
const MedicsDropDown = ({
  onSelected,
  placeholder,
  values,
  onChange,
}: MedicsDropDownProps) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [text, setText] = React.useState<string>("");
  const thisRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (thisRef.current && !thisRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div
      className="mdropdown-wrapper"
      ref={thisRef}
      onClick={() => {
        //dont remove this if statement !!
        if (!isOpen) {
          setIsOpen(true);
        }
      }}
    >
      <input
        value={text}
        className="input-1"
        placeholder={placeholder}
        onChange={(e) => {
          setText(e.target.value);
          onChange(e.target.value);
        }}
      ></input>
      {isOpen && (
        <div className="mdropdown-items-wrapper">
          {values.map((val) => {
            return (
              <div
                className="mdropdown-item"
                onClick={() => {
                  onSelected(val.id);
                  setText("");
                  onChange("");
                  setIsOpen(false);
                }}
              >
                <p> {val.name}</p>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
