import React, { useContext, useState } from "react";
import { Tab, Tabs, TabPanel, TabList } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Medicaments from "../Medicaments/Medicaments";
import Analyses from "../analyses/Analyses";
import Operations from "../Operations/Operations";
import OperationsDataPage from "./operations_donnees";
import AnalysesDataPage from "./analyses_donnees";
import { CurrentUserContext } from "../App";
import TicketsDataPage from "./tickets_donnees";

export const Donnees = () => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const handleTabSelect = (index: number) => {
    setSelectedTabIndex(index);
  };

  const currentSelectedHospitalId =
    useContext(CurrentUserContext).currentUserData!.selectedHospitalId;

  const currentHospital = useContext(
    CurrentUserContext
  ).currentUserData!.hospitals.find(
    (hospital) => hospital.id === currentSelectedHospitalId
  )!;

  return (
    <div>
      <Tabs
        selectedIndex={selectedTabIndex}
        onSelect={handleTabSelect}
        selectedTabClassName="font-semibold bg-[#187498] rounded-sm text-white"
      >
        <TabList className="flex flex-row items-center p-4 gap-4 font-pop font-medium ">
          {currentHospital.has_pharmacy && <Tab>Médicaments</Tab>}
          {currentHospital.is_hospital && <Tab>Analyses</Tab>}
          {currentHospital.is_hospital && <Tab>Operations</Tab>}
          <Tab>Tickets</Tab>
        </TabList>
        {currentHospital.has_pharmacy && (
          <TabPanel>
            <Medicaments />
          </TabPanel>
        )}
        {currentHospital.is_hospital && (
          <TabPanel>
            <AnalysesDataPage />
          </TabPanel>
        )}
        {currentHospital.is_hospital && (
          <TabPanel>
            <OperationsDataPage />
          </TabPanel>
        )}
        <TabPanel>
          <TicketsDataPage />
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default Donnees;
