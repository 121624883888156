import React, { Children, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Sidebar } from "./Sidebar";

const RenderSidebar = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const location = useLocation();

  useEffect(() => {
    console.log(location);
    if (location.pathname === "/Login") {
      setShowSidebar(false);
    } else {
      setShowSidebar(true);
    }
  }, [location]);

  return <div>{showSidebar && <Sidebar />}</div>;
};

export default RenderSidebar;
