import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { analyseSchema } from "../Validations/operationValidation";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { AnalyseInterface } from "../interfaces";
import CircularProgress from "@mui/material/CircularProgress";
import { rootUrl } from "../constants";
import "../Ajouter_medicament/ajouter.css";
import { CurrentUserContext } from "../App";
import { MDropDown } from "../Components/mdropdown/mdropdown";
import { FaRegBell } from "react-icons/fa";
import { MdLogout } from "react-icons/md";
import { useReactToPrint } from "react-to-print";

const UpdateAddAnalyseDataPage = () => {
  const [printing, setPrinting] = useState<boolean>(false);
  const componentRef = useRef<HTMLDivElement>(null);

  const { id: analyseId } = useParams();
  const [analyseData, setAnalyseData] = useState<AnalyseInterface | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const navigate = useNavigate();

  let token = useContext(CurrentUserContext).currentUserData!.token;

  let currentSelectedHospitalId =
    useContext(CurrentUserContext).currentUserData!.selectedHospitalId;

  useEffect(() => {
    if (analyseId) {
      axios
        .get(
          rootUrl +
            "/hospital/" +
            currentSelectedHospitalId +
            "/analyses/" +
            analyseId,
          {
            headers: {
              Authorization: "Token " + token,
            },
          }
        )
        .then((response: { data: any }) => {
          console.log("data returned : ");
          console.log(response);
          setAnalyseData(response.data);
        })
        .catch((error: any) => {
          navigate("/donnees");
        });
    }
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(analyseSchema),
  });

  const submitForm = (data: any) => {
    const newAnalyse = {
      name: data.name,
      price: data.price,
      hospital: currentSelectedHospitalId,
    };

    if (analyseId) {
      axios
        .put(
          rootUrl +
            "/hospital/" +
            currentSelectedHospitalId +
            "/analyses/" +
            analyseId,
          newAnalyse,
          {
            headers: {
              Authorization: "Token " + token,
            },
          }
        )
        .then((response) => {
          navigate("/donnees");
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      setIsSubmitting(true);
      axios
        .post(
          rootUrl + "/hospital/" + currentSelectedHospitalId + "/analyses",
          newAnalyse,
          {
            headers: {
              Authorization: "Token " + token,
            },
          }
        )
        .then((response) => {
          navigate("/donnees");
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  };

  return (
    <div className="ajouter-wrapper">
      <h1 className="title">
        {!analyseId ? "Ajouter une analyse" : "Mettre à jour"}{" "}
      </h1>
      <div className="positioned-buttons">
        <button className="notif-button">
          <FaRegBell />
        </button>
        <button className="logout-button">
          <MdLogout />
        </button>
      </div>
      {analyseId && !analyseData ? (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress thickness={6} sx={{ color: "#0B63F8" }} />
        </div>
      ) : (
        <form onSubmit={handleSubmit(submitForm)}>
          <div className="row">
            <div className="column-side">
              <div className="input-grp">
                <label className="label" htmlFor="name">
                  Le nom de l'analyse
                </label>
                <div className="input-p">
                  <input
                    className="input-1"
                    defaultValue={analyseData?.name ?? ""}
                    {...register("name")}
                    type="text"
                    name="name"
                  />
                  <p className="error-msg">{errors.name?.message}</p>
                </div>
              </div>
              <div className="input-grp">
                <label className="label" htmlFor="name">
                  Code de l'analyse
                </label>
                <div className="input-p">
                  <input
                    className="input-1"
                    // defaultValue={analyseData?.name ?? ""}
                    type="text"
                    name="code"
                  />
                </div>
              </div>
            </div>

            <div className="column-side">
              <div className="input-grp">
                <label className="label" htmlFor="price">
                  Prix
                </label>
                <div className="input-p">
                  <input
                    className="input-1"
                    type="number"
                    defaultValue={analyseData?.price ?? ""}
                    {...register("price")}
                    name="price"
                  />
                  <p className="currency">MRU</p>

                  <p className="error-msg">{errors.price?.message}</p>
                </div>
              </div>
            </div>
          </div>

          <input
            className="ajouter-button"
            type="submit"
            disabled={isSubmitting}
            value={analyseId ? "Modifier" : "Ajouter"}
          />
        </form>
      )}
    </div>
  );
};

export default UpdateAddAnalyseDataPage;
