import React, { useContext, useRef } from "react";
import { useState, useEffect } from "react";

import {
  MHeader,
  MTableCell,
  MActionsCell,
  ApiData,
  ActionType,
} from "../Components/table/Table";
import Header from "../Components/Header/Header";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import QuantityInput from "../Components/QuantityInput/QuantityInput";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { Header2, HeaderDelete } from "../Components/Header/Header2";
import ModalSupression from "../Components/Modal/ModalSupression";

import { HospitalInterface } from "../interfaces";
import Pagination from "../Components/pagination/Pagination";
import { rootUrl } from "../constants";
import { CurrentUserContext } from "../App";
import { useReactToPrint } from "react-to-print";
import PrintList from "../Components/printable_list";
export const titles = [
  { label: "Centres", sortable: true },
  { label: "Caissiers responsables", sortable: true },
];

const HospitalsPage = () => {
  const componentRef = useRef<HTMLDivElement>(null);
  const [printing, setPrinting] = useState<boolean>(false);

  const [selected, setSelected] = useState<number[]>([]);
  let [currentPage, setCurrentPage] = useState<number>(1);
  const [data, setData] = useState<ApiData<HospitalInterface> | null>(null);
  const [searchedText, setSearchedText] = useState<string>("");
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const [quantityTimeouts, setQuantityTimeouts] = useState<
    Map<number, NodeJS.Timeout>
  >(new Map());

  const navigate = useNavigate();
  const [error, setError] = useState(null);

  let currentuserContexxt = useContext(CurrentUserContext);

  const [deletingId, setDeletingId] = useState<number[]>([]);
  const [openedDescription, setOpenDescription] = useState<number | null>(null);

  let token = useContext(CurrentUserContext).currentUserData!.token;

  function FetchData() {
    setData(null);
    axios
      .get(rootUrl + "/hospital/", {
        params: { page: currentPage, search: searchedText },
        headers: {
          Authorization: "Token " + token,
        },
      })
      .then((response: { data: any }) => {
        console.log(data);
        setData(response.data);
      })
      .catch((error: any) => {
        setError(error);
      });
  }

  useEffect(() => {
    FetchData();
  }, [currentPage]);

  useEffect(() => {
    clearTimeout(typingTimeout);

    // Set a new timeout to delay the search
    const timeoutId = setTimeout(() => {
      setSelected([]);
      currentPage = 1; //should be updated but not render
      FetchData();
    }, 500);

    setTypingTimeout(timeoutId);

    return () => {
      clearTimeout(typingTimeout);
    };
  }, [searchedText]);
  const handlePrint = useReactToPrint({
    onBeforeGetContent() {
      setPrinting(true);
    },

    content: () => {
      return componentRef.current;
    },
    onAfterPrint: () => {
      setPrinting(false);
    },
  });

  return (
    <div className="hopitaux-wrapper">
      {deletingId.length > 0 && (
        <ModalSupression
          open={false}
          name={
            deletingId.length === 1
              ? data!.data.find((item) => item.id === deletingId[0])!.name
              : null
          }
          onClose={function (): void {
            setDeletingId([]);
          }}
          onConfirmClick={function (): void {
            alert("deleting : " + deletingId);
            setDeletingId([]);
          }}
        />
      )}

      <Header title="Centres" />
      {
        <Header2
          inputPlaceholder={"Rechercher dans ce tableau"}
          onInputChange={function (text: string): void {
            setSearchedText(text);
          }}
          // onAddButtonClick={function (): void {
          //   navigate("/Admin/Hopitaux/Ajouter");
          // }}
          handlePrint={handlePrint}
        />
      }
      {data === null ? (
        // center loading
        //center
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress thickness={6} sx={{ color: "#0B63F8" }} />
        </div>
      ) : (
        <Table
          style={{
            backgroundColor: "white",
            marginRight: "40px",
            marginLeft: "40px",
            width: "95.5%",
          }}
        >
          <MHeader
            titles={titles}
            withActions={false}
            onCheckClick={() => {
              if (selected.length === data?.data.length) {
                setSelected([]);
              } else {
                setSelected(data?.data.map((item) => item.id) || []);
              }
            }}
            isAllSelected={
              data &&
              data!.data.length !== 0 &&
              selected.length === data?.data.length
            }
          />
          <TableBody>
            {data!.data.map((hospital, index) => (
              <TableRow key={index}>
                <TableCell size="small">
                  <Checkbox
                    checked={selected.includes(hospital.id)}
                    onChange={() => {
                      if (selected.includes(hospital.id)) {
                        setSelected(
                          selected.filter((item) => item !== hospital.id)
                        );
                      } else {
                        setSelected([...selected, hospital.id]);
                      }
                    }}
                  />
                </TableCell>
                <MTableCell label={hospital.name} isTitle={false} />

                <MTableCell
                  label={`${hospital.staff.join(", ")}`}
                  isTitle={false}
                />

                {/* <MActionsCell
                  onClick={(a) => {
                    if (a === ActionType.DELETE) {
                      // setDeletingId([medicament.id]);
                    } else if (a === ActionType.VIEW) {
                      currentuserContexxt.setCurrentUser({
                        ...currentuserContexxt.currentUserData!,
                        selectedHospitalId: hospital.id,
                      });
                      navigate("/Médicaments");
                    } else if (a === ActionType.EDIT) {
                      // navigate("/Admin/Hopitaux/" + medicament.id +  "/Modifier/");
                    }
                  }}
                /> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      {data && (
        <Pagination
          currentPage={currentPage}
          totalPages={data!.total_pages}
          onPageChange={function (page: number): void {
            setCurrentPage(page);
          }}
        />
      )}
      <div ref={componentRef}>
        <PrintList
          centerName={null}
          title="LISTE DES CENTRES"
          titles={titles.map((title) => title.label)}
          data={
            data?.data
              .filter(
                (hospital) =>
                  selected.length === 0 || selected.includes(hospital.id)
              )
              .map((hospital, index) => [
                hospital.name,
                hospital.staff.join(", "),
              ]) ?? []
          }
        />
      </div>

      {/* <div
        ref={componentRef}
        style={{ margin: "5px" }}
        className="print-only p-4"
      >
        <div className=" mb-10 flex-row flex justify-between items-end">
          <div className="flex flex-col grow">
            <h1>REPUBLIQUE ISLAMIQUE DE MAURITANIE</h1>
            <h1>COMMUNE DE NOUADHIBOU</h1>
            <h1>DIVISION RECOUVREMENT</h1>
          </div>
          <div className="grow">
            <span className="font-semibold border p-2 rounded border-black">
              LIST DES CENTRES
            </span>
          </div>
          <div className="grow"></div>
        </div>

        <table
          style={{ fontFamily: "Poppins", width: "100%", alignItems: "left" }}
        >
          <tr style={{ alignItems: "left" }}>
            {titles.map((title) => (
              <th style={{ textAlign: "left" }}>{title.label}</th>
            ))}
          </tr>
          {data?.data
            .filter((hospital) => {
              selected.length === 0 || selected.includes(hospital.id);
            })
            .map((hospital, index) => (
              <tr key={index}>
                <td
                  style={{
                    border: "1px solid #000",
                    textAlign: "left",
                    padding: "8px",
                  }}
                >
                  {hospital.name}
                </td>
                <td
                  style={{
                    border: "1px solid #000",
                    textAlign: "left",
                    padding: "8px",
                  }}
                >
                  {hospital.addresse}
                </td>
              </tr>
            ))}
        </table>
      </div> */}
    </div>
  );
};

export default HospitalsPage;
