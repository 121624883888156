import { useContext, useRef } from "react";
import { useState, useEffect } from "react";

import {
  MHeader,
  MTableCell,
  MActionsCell,
  ApiData,
  ActionType,
} from "../Components/table/Table";

import Header from "../Components/Header/Header";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { Header2 } from "../Components/Header/Header2";
import Pagination from "../Components/pagination/Pagination";
import { rootUrl } from "../constants";
import { CurrentUserContext } from "../App";
import { useReactToPrint } from "react-to-print";
import PrintList from "../Components/printable_list";
import ModalSupression from "../Components/Modal/ModalSupression";
import { FilterComponent, FilterProps } from "../Components/filter_component";
import { Director } from "../interfaces";
import { DefDropdown } from "../Components/dropdown";

interface AlimentationInterface {
  id: number;
  staff_name: string | null;
  amount: number;
  created_at: string;
  director_name: string;
  director: number;
}

function AddAlimentationModal({ onDone }: { onDone: () => void }) {
  const [selectedDirector, setSelectedDirector] = useState<Director | null>(
    null
  );
  const [amount, setAmount] = useState<number>(0);
  const token = useContext(CurrentUserContext).currentUserData!.token;

  const currentSelectedHospitalId =
    useContext(CurrentUserContext).currentUserData!.selectedHospitalId;
  const currentHospital = useContext(
    CurrentUserContext
  ).currentUserData!.hospitals.find(
    (hospital) => hospital.id === currentSelectedHospitalId
  )!;

  function submit() {
    if (!selectedDirector) {
      alert("Veuillez selectionner un régisseur");
      return;
    }
    if (amount <= 0) {
      alert("Veuillez entrer un montant");
      return;
    }
    axios
      .post(
        rootUrl +
          `/hospital/${currentSelectedHospitalId}/municipal_alimentations`,
        {
          director: selectedDirector!.id,
          amount: amount,
        },
        {
          headers: {
            Authorization: "Token " + token,
          },
        }
      )
      .then((response: { data: any }) => {
        onDone();
      })
      .catch((error: any) => {
        alert("Une erreur s'est produite" + error);
      });
  }

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className="flex flex-col bg-white shadow-xl p-4 rounded w-[500px]"
    >
      <h2 className=" self-start font-medium text-2xl">
        Ajouter une alimentation
      </h2>
      <select
        className=" mt-5 border border-gray-400 rounded-md px-4 py-2 w-max pr-14 "
        value={selectedDirector?.id ?? ""}
        onChange={(e) => {
          setSelectedDirector(
            (currentHospital.municipal_tax_data?.directors ?? []).find(
              (director) => director.id === parseInt(e.target.value)
            )!
          );
        }}
      >
        {[
          <option value={""} disabled>
            Selectionner un régisseur
          </option>,
          ...(currentHospital.municipal_tax_data?.directors.map((director) => (
            <option value={director.id}>{director.name}</option>
          )) ?? []),
        ]}
      </select>
      <input
        type="number"
        className=" mt-5 border border-gray-400 rounded-md px-4 py-2 w-max "
        placeholder="Montant"
        value={amount === 0 ? "" : amount}
        onChange={(e) => {
          setAmount(parseInt(e.target.value));
        }}
      />
      <button
        onClick={submit}
        className="mt-5 bg-[#187498] text-white rounded-md px-4 py-2 w-max hover:bg-[#187498] transition-all self-end"
      >
        Ajouter
      </button>
    </div>
  );
}

const AlimentationsListPage = () => {
  const isAdmin = useContext(CurrentUserContext).currentUserData!.isAdmin;

  const [printing, setPrinting] = useState<boolean>(false);

  const componentRef = useRef<HTMLDivElement>(null);
  const [selected, setSelected] = useState<number[]>([]);
  let [currentPage, setCurrentPage] = useState<number>(1);
  const [data, setData] = useState<ApiData<AlimentationInterface> | null>(null);
  const [searchedText, setSearchedText] = useState<string>("");
  const [isAll, setIsAll] = useState<boolean>(false);
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const [filterProps, setFilterProps] = useState<FilterProps>({
    statsType: null,
    selectedDate: null,
    selectedMonth: null,
    selectedYear: null,
    selectedUserId: null,
  });
  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);

  const [deletingId, setDeletingId] = useState<number | null>(null);

  const navigate = useNavigate();
  const [error, setError] = useState(null);

  let currentSelectedHospitalId =
    useContext(CurrentUserContext).currentUserData!.selectedHospitalId;

  let token = useContext(CurrentUserContext).currentUserData!.token;

  const currentHospital = useContext(
    CurrentUserContext
  ).currentUserData!.hospitals.find(
    (hospital) => hospital.id === currentSelectedHospitalId
  )!;

  const titles = [
    "date",
    "regisseur",
    "montant",
    ...(isAdmin ? ["Caissier Responsable"] : []),
    "",
  ];

  function deleteAlimentation(id: number) {
    axios
      .delete(
        rootUrl +
          `/hospital/` +
          currentSelectedHospitalId +
          `/municipal_alimentations/${id}`,
        {
          headers: {
            Authorization: "Token " + token,
          },
        }
      )
      .then((response: { data: any }) => {
        console.log(data);
        FetchData();
      })
      .catch((error: any) => {
        alert("Une erreur s'est produite" + error);
      });
  }

  function FetchData() {
    let filterParams = {};

    if (filterProps.statsType === "daily") {
      filterParams = {
        year: filterProps.selectedDate?.split("-")[0] ?? undefined,
        month: filterProps.selectedDate?.split("-")[1] ?? undefined,
        day: filterProps.selectedDate?.split("-")[2] ?? undefined,
      };
    } else if (filterProps.statsType === "monthly") {
      filterParams = {
        year: filterProps.selectedYear ?? undefined,
        month: filterProps.selectedMonth ?? undefined,
      };
    }
    filterParams = {
      ...filterParams,
      user: filterProps.selectedUserId ?? undefined,
      all: isAll ? "true" : undefined,
    };
    setData(null);
    axios
      .get(
        rootUrl +
          "/hospital/" +
          currentSelectedHospitalId +
          "/municipal_alimentations",
        {
          params: { page: currentPage, search: searchedText, ...filterParams },
          headers: {
            Authorization: "Token " + token,
          },
        }
      )
      .then((response: { data: any }) => {
        setData(response.data);
      })
      .catch((error: any) => {
        setError(error);
      });
  }

  useEffect(() => {
    FetchData();
  }, [currentPage, filterProps, isAll]);

  useEffect(() => {
    setFilterProps({
      ...filterProps,
      selectedUserId: null,
    });
  }, [currentSelectedHospitalId]);

  useEffect(() => {
    clearTimeout(typingTimeout);

    // Set a new timeout to delay the search
    const timeoutId = setTimeout(() => {
      setSelected([]);
      currentPage = 1; //should be updated but not render
      FetchData();
    }, 500);

    setTypingTimeout(timeoutId);

    return () => {
      clearTimeout(typingTimeout);
    };
  }, [searchedText]);
  const handlePrint = useReactToPrint({
    onBeforeGetContent() {
      setPrinting(true);
    },

    content: () => {
      return componentRef.current;
    },
    onAfterPrint: () => {
      setPrinting(false);
    },
  });

  return (
    <div>
      {
        // why it is not working
        isAddModalOpen && (
          <div
            onClick={() => {
              setIsAddModalOpen(false);
            }}
            className="modal"
          >
            <div
              className={
                (isAddModalOpen
                  ? "opacity-100 scale-100"
                  : "opacity-20 scale-50") + " transition-all duration-200"
              }
            >
              <AddAlimentationModal
                onDone={() => {
                  setIsAddModalOpen(false);
                  FetchData();
                }}
              />
            </div>
          </div>
        )
      }
      <div className="operations-wrapper">
        {deletingId && (
          <ModalSupression
            open={false}
            name={`cette alimentation`}
            onClose={function (): void {
              setDeletingId(null);
            }}
            onConfirmClick={function (): void {
              deleteAlimentation(deletingId);
              setDeletingId(null);
            }}
          />
        )}
        <Header title="Alimentations" />
        {
          <Header2
            inputPlaceholder={"Rechercher dans ce tableau"}
            onInputChange={function (text: string): void {
              setSearchedText(text);
            }}
            handlePrint={handlePrint}
            all={isAll}
            onAddButtonClick={
              isAdmin
                ? null
                : function (): void {
                    setIsAddModalOpen(true);
                  }
            }
            onAllClick={function (): void {
              setIsAll(!isAll);
              setCurrentPage(1);
            }}
          />
        }

        {data === null ? (
          // center loading
          //center
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <CircularProgress thickness={6} sx={{ color: "#0B63F8" }} />
          </div>
        ) : (
          <div className="flex flex-col gap-y-2">
            {isAdmin && (
              <div className="flex flex-col  gap-y-2 items-start justify-center ml-10 mb-5">
                <FilterComponent
                  filterProps={filterProps}
                  setFilterProps={(props: FilterProps) => {
                    setFilterProps(props);
                    setCurrentPage(1);
                  }}
                />
                {/* {data.total !== undefined && (
                <div className="flex gap-x-2">
                  <span>Total :</span>
                  <span className="font-bold">
                    {(data.total ?? 0) + " MRU"}
                  </span>
                </div>
              )} */}
              </div>
            )}
            <Table
              style={{
                backgroundColor: "white",
                marginRight: "40px",
                marginLeft: "40px",
                width: "95.5%",
              }}
            >
              <MHeader
                titles={titles.map((t) => {
                  return { label: t, sortable: true };
                })}
                withActions={false}
                onCheckClick={() => {
                  if (selected.length === data?.data.length) {
                    setSelected([]);
                  } else {
                    setSelected(data?.data.map((item) => item.id) || []);
                  }
                }}
                isAllSelected={
                  data &&
                  data!.data.length !== 0 &&
                  selected.length === data?.data.length
                }
              />
              <TableBody>
                {data!.data.map((alimentation, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Checkbox
                        checked={selected.includes(alimentation.id)}
                        onChange={() => {
                          if (selected.includes(alimentation.id)) {
                            setSelected(
                              selected.filter(
                                (item) => item !== alimentation.id
                              )
                            );
                          } else {
                            setSelected([...selected, alimentation.id]);
                          }
                        }}
                      />
                    </TableCell>
                    <MTableCell
                      label={new Date(
                        alimentation.created_at
                      ).toLocaleDateString()}
                      isTitle={false}
                    />
                    <MTableCell
                      label={alimentation.director_name}
                      isTitle={false}
                    />

                    <MTableCell
                      label={alimentation.amount + " MRU"}
                      isTitle={false}
                    />
                    {isAdmin && (
                      <MTableCell
                        label={alimentation.staff_name ?? "-"}
                        isTitle={false}
                      />
                    )}
                    {isAdmin && (
                      <MTableCell
                        label={
                          <button
                            onClick={() => {
                              setDeletingId(alimentation.id);
                            }}
                            className="bg-red-400 border-2 text-sm font-medium text-white border-red-400 rounded py-2 px-3 hover:bg-transparent hover:text-red-500 active:scale-95 transition-all "
                          >
                            Supprimer
                          </button>
                        }
                        isTitle={false}
                      />
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        )}
        {data && (
          <Pagination
            currentPage={currentPage}
            totalPages={data!.total_pages}
            onPageChange={function (page: number): void {
              setCurrentPage(page);
            }}
          />
        )}
        <div ref={componentRef}>
          <PrintList
            centerName={currentHospital.name}
            title={"Liste des alimentations"}
            afterTable={
              <div className="flex justify-between w-full">
                {/* <div className="flex gap-x-2">
                <span>Total :</span>
                <span className="font-bold">{(data?.total ?? 0) + " MRU"}</span>
              </div> */}
                {filterProps.selectedUserId && (
                  <div>
                    <span>Caissier Responsable :</span>

                    <span className="font-bold">
                      {currentHospital!.staff_data.find(
                        (u) => u.id === filterProps.selectedUserId
                      )?.name ?? ""}
                    </span>
                  </div>
                )}
              </div>
            }
            titles={titles.filter((title) => title !== "")}
            data={
              data?.data
                .filter(
                  (alimentation, index) =>
                    selected.length === 0 || selected.includes(alimentation.id)
                )
                .map((alimentation, index) => [
                  new Date(alimentation.created_at).toLocaleDateString(),
                  alimentation.director_name,
                  alimentation.amount + " MRU",
                  ...(isAdmin ? [alimentation.staff_name ?? "-"] : []),
                ]) ?? []
            }
          />
        </div>
      </div>
    </div>
  );
};

export default AlimentationsListPage;
