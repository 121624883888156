import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { analyseActionSchema } from "../Validations/operationValidation";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { AnalyseInterface } from "../interfaces";
import CircularProgress from "@mui/material/CircularProgress";
import "react-datepicker/dist/react-datepicker.css";
import { InssuranceCompany, defInsurances, rootUrl } from "../constants";
import "../Ajouter_medicament/ajouter.css";
import { CurrentUserContext } from "../App";
import { MDropDown } from "../Components/mdropdown/mdropdown";
import { FaRegBell } from "react-icons/fa";
import { MdLogout } from "react-icons/md";
import { useReactToPrint } from "react-to-print";
import { DefDropdown } from "../Components/dropdown";
import {
  PrintableTicket,
  ticketPageStyle,
} from "../Components/printable_ticket";

const AddAnalyseActionPage = () => {
  const [printing, setPrinting] = useState<boolean>(false);
  const componentRef = useRef<HTMLDivElement>(null);
  const [patient, setPatient] = useState<string>("");
  const [insuranceNumber, setInsuranceNumber] = useState<string>("");
  const [selectedAnalyses, setSelectedAnalyses] = useState<AnalyseInterface[]>(
    []
  );

  const [newAnalyseId, setNewAnalyseId] = useState<number | null>(null);

  const navigate = useNavigate();

  let token = useContext(CurrentUserContext).currentUserData!.token;

  let currentSelectedHospitalId =
    useContext(CurrentUserContext).currentUserData!.selectedHospitalId;

  const [selectedInsurance, setSelectedInsurance] =
    useState<InssuranceCompany | null>(null);

  const currentHospital = useContext(
    CurrentUserContext
  ).currentUserData!.hospitals.find((h) => h.id === currentSelectedHospitalId)!;
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const handlePrint = useReactToPrint({
    pageStyle: ticketPageStyle,
    onBeforeGetContent() {
      setPrinting(true);
    },

    content: () => {
      return componentRef.current;
    },
    onAfterPrint: () => {
      setPrinting(false);
    },
  });

  // let adjustedPrice: number = getValues().price;

  const submitForm = () => {
    if (patient.trim() === "") {
      alert("Veillez entrer le nom du patient");
      return;
    }
    if (selectedAnalyses.length === 0) {
      alert("Veillez choisir une analyse");
      return;
    }
    const insurance_number =
      insuranceNumber.trim() === "" ? null : insuranceNumber;
    if (insuranceNumber.trim() !== "" && selectedInsurance === null) {
      alert("Veillez choisir le type d'assurance");
      return;
    }
    let itemsData = selectedAnalyses.map((a) => {
      let price;
      if (insurance_number) {
        price = (a.insurance_price ?? a.price) * selectedInsurance!.multiplier;
      } else {
        price = a.price;
      }
      return {
        analyse: a.id,
        payed_price: price,
      };
    });

    let data = {
      hospital: currentSelectedHospitalId,
      insurance_name: selectedInsurance?.value ?? null,
      insurance_number: insurance_number,
      patient: patient,
      items: itemsData,
    };
    setIsSubmitting(true);

    axios
      .post(
        rootUrl +
          "/hospital/" +
          currentSelectedHospitalId +
          "/analyses_actions",
        data,
        {
          headers: {
            Authorization: "Token " + token,
          },
        }
      )
      .then((response) => {
        setNewAnalyseId(response.data.id);
        setTimeout(() => {
          handlePrint();
          navigate("/analyses");
        }, 10);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <div className="ajouter-wrapper">
      <h1 className="title">Ajouter une analyse </h1>
      <div className="positioned-buttons">
        <button className="notif-button">
          <FaRegBell />
        </button>
        <button className="logout-button">
          <MdLogout />
        </button>
      </div>
      <div className="grid grid-cols-2 lg:grid-cols-3 gap-x-4 mr-20">
        <input
          className=" border-gray-300 rounded-sm bg-white border py-4 px-4 mt-4"
          placeholder="Nom du patient"
          value={patient}
          onChange={(e) => setPatient(e.target.value)}
        />
        <input
          className=" border-gray-300 rounded-sm bg-white border py-4 px-4 mt-4"
          placeholder="Matricule CNAM"
          value={insuranceNumber}
          onChange={(e) => setInsuranceNumber(e.target.value)}
        />
        <select
          value={selectedInsurance?.value ?? ""}
          className=" border-gray-300 rounded-sm bg-white border py-4 px-4 mt-4 "
          onChange={(e) => {
            if (e.target.value === "") {
              setSelectedInsurance(null);
            }
            setSelectedInsurance(
              defInsurances.find((item) => item.value === e.target.value)!
            );
          }}
        >
          <option disabled value="" className=" text-gray-300">
            Type d'assurance
          </option>
          {defInsurances.map((item) => {
            return <option value={item.value}>{item.name}</option>;
          })}
        </select>
      </div>
      <br />
      {selectedAnalyses.length > 0 && (
        <h2 className="font-bold text-2xl mb-4">Liste des analyses</h2>
      )}
      {selectedAnalyses.map((a) => {
        return (
          <div className="flex gap-x-3 items-center mb-3">
            <input
              value={a.name}
              disabled={true}
              className="w-96 border-gray-300 rounded-sm bg-white border py-2 px-2"
            />
            <button
              onClick={() => {
                setSelectedAnalyses(
                  selectedAnalyses.filter((s) => s.id !== a.id)
                );
              }}
            >
              <svg
                className="w-5 h-5 cursor-pointer hover:scale-110 transition-all"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                stroke="#ff0f0f"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M19.207 6.207a1 1 0 0 0-1.414-1.414L12 10.586 6.207 4.793a1 1 0 0 0-1.414 1.414L10.586 12l-5.793 5.793a1 1 0 1 0 1.414 1.414L12 13.414l5.793 5.793a1 1 0 0 0 1.414-1.414L13.414 12l5.793-5.793z"
                    fill="#ff0f0f"
                  ></path>
                </g>
              </svg>
            </button>
          </div>
        );
      })}
      <br />
      <h3 className="font-bold text-2xl mb-4">Ajouter</h3>
      <div className=" w-96">
        {DefDropdown<AnalyseInterface>({
          value: "",
          onSelected: (value: AnalyseInterface) => {
            if (selectedAnalyses.find((s) => s.id === value.id)) {
              return;
            }

            setSelectedAnalyses([...selectedAnalyses, value]);
          },
          placeholder: "nom de l'anlyse",
          url: "hospital/" + currentSelectedHospitalId + "/" + "analyses",
          search: true,
        })}
      </div>
      <button
        onClick={() => {
          submitForm();
        }}
        disabled={isSubmitting}
        className=" bg-[#187498] text-white py-3 text-xl font-medium px-4 rounded mt-10 self-end ml-auto mr-20 disabled:opacity-50 disabled:cursor-not-allowed"
      >
        Ajouter Analyse
      </button>

      <div ref={componentRef}>
        <PrintableTicket
          title="Reçu d'Analyse"
          centerName={currentHospital.name}
          id={newAnalyseId?.toString() ?? ""}
          data={{
            "Nom du patient": patient,
            // Analyse: selectedAnalyse?.name ?? "",

            Date:
              new Date().toLocaleDateString() +
              " - " +
              new Date().toLocaleTimeString(),
          }}
          moreData={[
            ...selectedAnalyses.map((item) => {
              return (
                <div className="flex items-center gap-4">
                  <p className="font-semibold text-gray-600">Analyse : </p>
                  <p>{item.name}</p>

                  <p className="font-semibold text-gray-600">Prix : </p>
                  <p>
                    {(insuranceNumber
                      ? (item.insurance_price ?? item!.price) *
                        (selectedInsurance?.multiplier ?? 0)
                      : item!.price) + " MRU"}
                  </p>
                </div>
              );
            }),
            <div className="flex items-center gap-4">
              <p className="font-semibold text-gray-600">Total :</p>
              <p>
                {selectedAnalyses
                  .reduce((acc, item) => {
                    return (
                      (insuranceNumber
                        ? (item.insurance_price ?? item!.price) *
                          (selectedInsurance?.multiplier ?? 0)
                        : item!.price) + acc
                    );
                  }, 0)
                  .toFixed(2) + " MRU"}
              </p>
            </div>,
          ]}
        />
      </div>
    </div>
  );
};

export default AddAnalyseActionPage;
