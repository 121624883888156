import * as yup from "yup";

export const medicationSchema = yup.object().shape({
  name: yup.string().required("Saisissez le nom d'un médicament"),

  price: yup
    .number()
    .required("Saisissez le prix")
    .typeError("Saisissez le prix"),
  // quantity: yup
  //   .number()
  //   .required("Saisissez une quantité")
  //   .typeError("Saisissez une quantité"),
});
