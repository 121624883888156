// Header2 component
import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";

interface Header2Props {
  inputPlaceholder: string;
  onInputChange: (text: string) => void;
  onAddButtonClick?: (() => void) | null;
  handlePrint?: () => void;
  all?: boolean;
  onAllClick?: () => void;
}

export const Header2 = (props: Header2Props) => {
  const componentRef = useRef<HTMLDivElement>(null);

  return (
    <div className="wrapper-input-button">
      <div>
        <div className="input-wrapper">
          <div className="search-icon"></div>
          {
            <input
              className="input-class"
              typeof="text"
              placeholder={props.inputPlaceholder}
              onChange={(e) => props.onInputChange(e.target.value)}
            />
          }
        </div>
      </div>
      <div className="header-left items-start ">
        <div className="flex flex-col items-end">
          <button onClick={props.handlePrint} className="print-button">
            Imprimer
          </button>
          {props.all !== undefined && (
            <div className="flex gap-x-2 items-center">
              <span className="font-semibold">Tous</span>
              <input
                onChange={(e) => {
                  props.onAllClick && props.onAllClick();
                }}
                checked={props.all ?? false}
                type="checkbox"
                className="p-2 w-5 h-5"
              />
            </div>
          )}
        </div>
        {props.onAddButtonClick && (
          <button className="add-button" onClick={props.onAddButtonClick}>
            Ajouter
          </button>
        )}
      </div>
    </div>
  );
};

interface HeaderDeleteProps {
  legend: string;
  onDeleteClick: () => void;
  selectedLength: number;
  handlePrint?: () => void;
}

export const HeaderDelete = (props: HeaderDeleteProps) => {
  const componentRef = useRef<HTMLDivElement>(null);

  return (
    <div className="wrapper-input-button">
      <div>
        <p className="selected-title">
          {props.selectedLength} {props.legend}(s) sélectionnée(s)
        </p>
      </div>
      <div className="header-left">
        <button onClick={props.handlePrint} className="print-button">
          Imprimer
        </button>

        <button className="delete-button" onClick={props.onDeleteClick}>
          Supprimer
        </button>
      </div>
    </div>
  );
};
