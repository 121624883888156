import React, { ReactNode, useContext, useRef } from "react";
import { useState, useEffect } from "react";

import {
  MHeader,
  MTableCell,
  ActionType,
  ApiData,
} from "../Components/table/Table";
import { BsStopwatch } from "react-icons/bs";
import { FaRegCalendarTimes } from "react-icons/fa";
import { MdFileDownloadDone } from "react-icons/md";

import Header from "../Components/Header/Header";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import QuantityInput from "../Components/QuantityInput/QuantityInput";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { Header2, HeaderDelete } from "../Components/Header/Header2";
import Pagination from "../Components/pagination/Pagination";
import { defInsurances, rootUrl } from "../constants";
import { CurrentUserContext } from "../App";
import { useReactToPrint } from "react-to-print";
import OperationStatus from "../Components/OperationStatus/OperationStatus";
import { InsuranceAction } from "../interfaces";
import PrintList from "../Components/printable_list";

const Assures = () => {
  const [printing, setPrinting] = useState<boolean>(false);

  const componentRef = useRef<HTMLDivElement>(null);
  const [selected, setSelected] = useState<string[]>([]);
  let [currentPage, setCurrentPage] = useState<number>(1);
  const [data, setData] = useState<ApiData<InsuranceAction> | null>(null);
  const [searchedText, setSearchedText] = useState<string>("");
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const [isAll, setIsAll] = useState<boolean>(false);

  const [selectedMonth, setSelectedMonth] = useState<number | null>(null);
  const [selectedYear, setSelectedYear] = useState<number | null>(null);

  const navigate = useNavigate();
  const [error, setError] = useState(null);

  let currentSelectedHospitalId =
    useContext(CurrentUserContext).currentUserData!.selectedHospitalId;

  const currentHospital = useContext(
    CurrentUserContext
  ).currentUserData!.hospitals.find(
    (hospital) => hospital.id === currentSelectedHospitalId
  )!;

  const [selectedInsurance, setSelectedInsurance] = useState<
    "CNAM_ALL" | "HILAL"
  >("CNAM_ALL");

  let token = useContext(CurrentUserContext).currentUserData!.token;
  const isAdmin = useContext(CurrentUserContext).currentUserData!.isAdmin;
  const titles = [
    ...[{ label: "Date", sortable: false }],
    ...(currentHospital.has_pharmacy || currentHospital.is_hospital
      ? [{ label: "Matricule CNAM", sortable: false }]
      : []),
    ...[
      { label: "Nature", sortable: false },
      { label: "Prix Normal", sortable: false },
      { label: "Montant payé", sortable: false },
      { label: "Montant à Rembourser", sortable: false },
      { label: "Assurance", sortable: false },
    ],
  ];

  function FetchData() {
    setData(null);

    axios
      .get(rootUrl + "/hospital/" + currentSelectedHospitalId + "/insurances", {
        params: {
          page: currentPage,
          search: searchedText,
          month: selectedMonth,
          year: selectedYear,
          all: isAll ? "true" : "false",
          insurances:
            selectedInsurance === "CNAM_ALL" ? "[CNAM, TAAZOUR]" : "[HILAL]",
        },
        headers: {
          Authorization: "Token " + token,
        },
      })
      .then((response: { data: any }) => {
        console.log(data);
        setData(response.data);
      })
      .catch((error: any) => {
        setError(error);
      });
  }

  useEffect(() => {
    FetchData();
  }, [
    currentPage,
    selectedMonth,
    selectedYear,
    currentSelectedHospitalId,
    selectedInsurance,
    isAll,
  ]);

  useEffect(() => {
    clearTimeout(typingTimeout);

    // Set a new timeout to delay the search
    const timeoutId = setTimeout(() => {
      setSelected([]);
      currentPage = 1; //should be updated but not render
      FetchData();
    }, 500);

    setTypingTimeout(timeoutId);

    return () => {
      clearTimeout(typingTimeout);
    };
  }, [searchedText]);
  const handlePrint = useReactToPrint({
    onBeforeGetContent() {
      setPrinting(true);
    },

    content: () => {
      return componentRef.current;
    },
    onAfterPrint: () => {
      setPrinting(false);
    },
  });

  return (
    <div className="operations-wrapper">
      <Header title="Liste Des Bénéficiaires" />
      {
        <Header2
          inputPlaceholder={"Rechercher dans ce tableau"}
          onInputChange={function (text: string): void {
            setSearchedText(text);
          }}
          handlePrint={handlePrint}
          all={isAll}
          onAllClick={() => {
            setIsAll(!isAll);
            setCurrentPage(1);
            setSelected([]);
          }}
        />
      }

      <div className="flex pl-10 gap-x-4 my-6 items-center">
        <select
          value={selectedMonth === null ? "tous" : selectedMonth}
          onChange={(e) => {
            if (e.target.value === "tous") {
              setSelectedMonth(null);
            } else {
              setSelectedMonth(parseInt(e.target.value));
            }
            setCurrentPage(1);
            setSelected([]);
          }}
          className="w-40 p-2  text-sm text-gray-900 border border-gray-300 rounded-lg bg-white"
        >
          <option value="tous">Tous</option>
          <option value="1">Janvier</option>
          <option value="2">Février</option>
          <option value="3">Mars</option>
          <option value="4">Avril</option>
          <option value="5">Mai</option>
          <option value="6">Juin</option>
          <option value="7">Juillet</option>
          <option value="8">Août</option>
          <option value="9">Septembre</option>
          <option value="10">Octobre</option>
          <option value="11">Novembre</option>
          <option value="12">Décembre</option>
        </select>
        <select
          value={selectedYear === null ? "tous" : selectedYear}
          onChange={(e) => {
            if (e.target.value === "tous") {
              setSelectedYear(null);
            } else {
              setSelectedYear(parseInt(e.target.value));
            }
            setCurrentPage(1);
            setSelected([]);
          }}
          className="w-24 p-2  text-sm text-gray-900 border border-gray-300 rounded-lg bg-white"
        >
          {[
            <option value="tous">Tous</option>,
            ...Array.from({ length: 5 }, (v, i) => {
              const now = new Date();
              const year = now.getFullYear() - 4 + i;
              return <option value={year}>{year}</option>;
            }),
          ]}
        </select>
        <div className="flex gap-x-2">
          <span>Arrêté le présente état à la somme de :</span>
          <span className="font-bold">{(data?.total ?? 0) + " MRU"}</span>
        </div>
      </div>
      <div className="flex gap-x-2 font-pop font-medium ml-10 mb-10">
        <button
          onClick={() => {
            setSelectedInsurance("CNAM_ALL");
            setCurrentPage(1);
          }}
          className={`font-semibold rounded-sm text-black  p-2 transition-all ${
            selectedInsurance === "CNAM_ALL" ? "bg-[#187498] text-white" : ""
          }`}
        >
          <p>CNAM</p>
        </button>
        <button
          onClick={() => {
            setSelectedInsurance("HILAL");
            setCurrentPage(1);
          }}
          className={`font-semibold rounded-sm text-black  p-2 transition-all ${
            selectedInsurance === "HILAL" ? "bg-[#187498] text-white" : ""
          }`}
        >
          <p>CONVENTION</p>
        </button>
      </div>
      {data === null ? (
        // center loading
        //center
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress thickness={6} sx={{ color: "#0B63F8" }} />
        </div>
      ) : (
        <Table
          style={{
            backgroundColor: "white",
            marginRight: "40px",
            marginLeft: "40px",
            width: "95.5%",
          }}
        >
          <MHeader
            titles={titles}
            withActions={false}
            onCheckClick={() => {
              if (selected.length === data?.data.length) {
                setSelected([]);
              } else {
                setSelected(data?.data.map((item) => item.iid) || []);
              }
            }}
            isAllSelected={
              data &&
              data!.data.length !== 0 &&
              selected.length === data?.data.length
            }
          />
          <TableBody>
            {data!.data.map((assure, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Checkbox
                    checked={selected.includes(assure.iid)}
                    onChange={() => {
                      if (selected.includes(assure.iid)) {
                        setSelected(
                          selected.filter((item) => item !== assure.iid)
                        );
                      } else {
                        setSelected([...selected, assure.iid]);
                      }
                    }}
                  />
                </TableCell>
                <MTableCell
                  label={new Date(assure.created_at).toLocaleDateString()}
                  isTitle={false}
                />
                <MTableCell label={assure.insurance_number} isTitle={false} />
                <MTableCell label={assure.name} isTitle={false} />
                <MTableCell
                  label={`${assure.normal_price} MRU`}
                  isTitle={false}
                />

                <MTableCell label={`${assure.revenue} MRU`} isTitle={false} />
                <MTableCell
                  label={`${assure.normal_price - assure.revenue} MRU`}
                  isTitle={false}
                />

                <MTableCell
                  label={
                    defInsurances.find(
                      (item) => item.value === assure.insurance_name
                    )?.name ?? "-"
                  }
                  isTitle={false}
                />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      {data && (
        <Pagination
          currentPage={currentPage}
          totalPages={data!.total_pages}
          onPageChange={function (page: number): void {
            setCurrentPage(page);
          }}
        />
      )}
      <div ref={componentRef}>
        <PrintList
          title={"LISTE DES BÉNÉFICIAIRES "}
          titles={titles.map((item) => item.label)}
          centerName={
            currentHospital.name === "cms1"
              ? "CENTRE DE SANTE MUNICIPAL N°1"
              : currentHospital.name === "cms3"
              ? "CENTRE DE SANTE MUNICIPAL N°3"
              : currentHospital.name
          }
          showRegisseur={false}
          afterTable={
            <div className="flex ">
              <div className="flex gap-x-2 w-full">
                <span>Arrêté le présente état à la somme de :</span>
                <span className="font-bold">{(data?.total ?? 0) + " MRU"}</span>
              </div>
              <div className="w-full flex flex-col font-pop pr-10  mt-10">
                <h1 className="self-end">RECEVEUR MUNICIPAL</h1>
              </div>
            </div>
          }
          data={
            [...(data?.data ?? []), ...(data?.data ?? [])]
              .filter((assure) => {
                return selected.length === 0 || selected.includes(assure.iid);
              })
              .map((assure, index) => {
                const inssuranceCompany = defInsurances.find(
                  (item) => item.value === assure.insurance_name
                );
                return [
                  new Date(assure.created_at).toLocaleDateString(),
                  assure.insurance_number,
                  assure.name,
                  assure.normal_price,
                  assure.revenue,
                  assure.normal_price - assure.revenue,
                  inssuranceCompany?.name ?? "-",
                ];
              }) ?? []
          }
        />
      </div>
    </div>
  );
};

export default Assures;
