import React from "react";
import { Line, Pie } from "react-chartjs-2";
import "./mainchart.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
interface LineChartProps {
  chartData: any;
}

const PieChart: React.FC<LineChartProps> = ({ chartData }) => {
  return (
    <div style={{ width: 400 }}>
      <Pie data={chartData} />
    </div>
  );
};

export default PieChart;
