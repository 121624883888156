import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiData, MHeader, MTableCell } from "../Components/table/Table";
import { CurrentUserContext } from "../App";
import axios from "axios";
import { rootUrl } from "../constants";
import { useReactToPrint } from "react-to-print";
import Header from "../Components/Header/Header";
import { Header2 } from "../Components/Header/Header2";
import {
  Checkbox,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import PrintList from "../Components/printable_list";
import { FilterComponent, FilterProps } from "../Components/filter_component";

const RevenueStatus = () => {
  const componentRef = useRef<HTMLDivElement>(null);
  const [printing, setPrinting] = useState<boolean>(false);
  const [filterProps, setFilterProps] = useState<FilterProps>({
    statsType: "daily",
    selectedDate: new Date().toISOString().split("T")[0],
    selectedMonth: null,
    selectedYear: null,
    selectedUserId: null,
    filterByUser: false,
  });

  const [selected, setSelected] = useState<number[]>([]);
  const [data, setData] = useState<{ [key: string]: string } | null>(null);
  const [searchedText, setSearchedText] = useState<string>("");
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();

  const navigate = useNavigate();
  const [error, setError] = useState(null);

  let currentuserContexxt = useContext(CurrentUserContext);

  const [deletingId, setDeletingId] = useState<number[]>([]);

  let token = useContext(CurrentUserContext).currentUserData!.token;

  function FetchData() {
    setData(null);
    let filterParams = {};

    if (filterProps.statsType === "daily") {
      filterParams = {
        year: filterProps.selectedDate?.split("-")[0] ?? undefined,
        month: filterProps.selectedDate?.split("-")[1] ?? undefined,
        day: filterProps.selectedDate?.split("-")[2] ?? undefined,
      };
    } else if (filterProps.statsType === "monthly") {
      filterParams = {
        year: filterProps.selectedYear ?? undefined,
        month: filterProps.selectedMonth ?? undefined,
      };
    }
    axios
      .get(rootUrl + "/hospital_renenue_status", {
        params: { ...filterParams },
        headers: {
          Authorization: "Token " + token,
        },
      })
      .then((response: { data: any }) => {
        console.log(data);
        setData(response.data);
      })
      .catch((error: any) => {
        setError(error);
      });
  }

  useEffect(() => {
    FetchData();
  }, [filterProps]);

  useEffect(() => {
    clearTimeout(typingTimeout);

    // Set a new timeout to delay the search
    const timeoutId = setTimeout(() => {
      setSelected([]);
      FetchData();
    }, 500);

    setTypingTimeout(timeoutId);

    return () => {
      clearTimeout(typingTimeout);
    };
  }, [searchedText]);

  const handlePrint = useReactToPrint({
    onBeforeGetContent() {
      setPrinting(true);
    },

    content: () => {
      return componentRef.current;
    },
    onAfterPrint: () => {
      setPrinting(false);
    },
  });

  function deleteUsers(ids: number[]) {
    for (let id of ids) {
      axios
        .delete(rootUrl + "/users/" + id + "/", {
          headers: {
            Authorization: "Token " + token,
          },
        })
        .then((response: { data: any }) => {
          FetchData();
        });
    }
  }

  return (
    <div className="hopitaux-wrapper">
      <Header title="État Recapitulatif de Recettes" />
      {
        <Header2
          inputPlaceholder={"Rechercher dans ce tableau"}
          onInputChange={function (text: string): void {
            setSearchedText(text);
          }}
          handlePrint={handlePrint}
        />
      }
      <div className="flex flex-col gap-y-4">
        <div className="ml-10">
          <FilterComponent
            filterProps={filterProps}
            setFilterProps={(props: FilterProps) => {
              if (props.statsType === "daily" && props.selectedDate === null) {
                props.selectedDate = new Date().toISOString().split("T")[0];
              } else if (props.statsType === "monthly") {
                if (props.selectedYear === null) {
                  props.selectedYear = new Date().getFullYear();
                }
                if (props.selectedMonth === null) {
                  props.selectedMonth = new Date().getMonth() + 1;
                }
              }
              setFilterProps(props);
            }}
          />
        </div>
        {data === null ? (
          // center loading
          //center
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <CircularProgress thickness={6} sx={{ color: "#0B63F8" }} />
          </div>
        ) : (
          <Table
            style={{
              backgroundColor: "white",
              marginRight: "40px",
              marginLeft: "40px",
              width: "95.5%",
            }}
          >
            <MHeader
              withActions={false}
              titles={Object.keys(data).map((t) => {
                return {
                  label: t,
                  sortable: false,
                };
              })}
              isAllSelected={null}
              onCheckClick={() => {}}
            />
            <TableBody>
              <TableRow key={1}>
                {Object.values(data || []).map((item, index) => (
                  <MTableCell label={item} isTitle={false} key={index} />
                ))}
              </TableRow>
            </TableBody>
          </Table>
        )}
      </div>

      <div ref={componentRef}>
        <PrintList
          showRegisseur={true}
          centerName={null}
          title={
            `État Recapitulatif de Recettes du ` +
            `${
              filterProps.statsType === "daily"
                ? filterProps.selectedDate
                : filterProps.selectedMonth?.toString().padStart(2, "0") +
                  "/" +
                  filterProps.selectedYear
            }`
          }
          afterTable={
            <div className="flex gap-x-2">
              <span className="text-lg font-bold">Total: </span>
              <span className="text-lg font-bold">
                {Object.values(data || []).reduce((a, b) => {
                  return a + Number(b);
                }, 0)}
              </span>
            </div>
          }
          titles={Object.keys(data || [])}
          data={[Object.values(data || [])]}
        />
      </div>
    </div>
  );
};

export default RevenueStatus;
