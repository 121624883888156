import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { operationActionSchema } from "../Validations/operationValidation";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { OperationInterface } from "../interfaces";
import CircularProgress from "@mui/material/CircularProgress";
import "react-datepicker/dist/react-datepicker.css";
import { InssuranceCompany, defInsurances, rootUrl } from "../constants";
import "../Ajouter_medicament/ajouter.css";
import { CurrentUserContext } from "../App";
import { MDropDown } from "../Components/mdropdown/mdropdown";
import { FaRegBell } from "react-icons/fa";
import { MdLogout } from "react-icons/md";
import { useReactToPrint } from "react-to-print";
import { DefDropdown } from "../Components/dropdown";
import {
  PrintableTicket,
  ticketPageStyle,
} from "../Components/printable_ticket";

const AddOperationActionPage = () => {
  const [printing, setPrinting] = useState<boolean>(false);
  const componentRef = useRef<HTMLDivElement>(null);
  const [newOperationId, setNewOperationId] = useState<number | null>(null);
  const [selectedOperation, setSelectedOperation] =
    useState<OperationInterface | null>(null);
  const [selectedInsurance, setSelectedInsurance] =
    useState<InssuranceCompany | null>(null);
  const navigate = useNavigate();
  let token = useContext(CurrentUserContext).currentUserData!.token;

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  let currentSelectedHospitalId =
    useContext(CurrentUserContext).currentUserData!.selectedHospitalId;

  const currentHospital = useContext(
    CurrentUserContext
  ).currentUserData!.hospitals.find((h) => h.id === currentSelectedHospitalId)!;

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(operationActionSchema),
  });
  const handlePrint = useReactToPrint({
    pageStyle: ticketPageStyle,
    onBeforeGetContent() {
      setPrinting(true);
    },

    content: () => {
      return componentRef.current;
    },
    onAfterPrint: () => {
      setPrinting(false);
    },
  });

  const submitForm = (data: any) => {
    if (!selectedOperation) {
      alert("Veillez choisir une opération");
      return;
    }
    const insurance_number: string | null =
      data.insurance_number.trim() === "" ? null : data.insurance_number;
    let price;

    if (insurance_number !== null && selectedInsurance === null) {
      alert("Veillez choisir le type d'assurance");
      return;
    }

    if (insurance_number) {
      price =
        (selectedOperation.insurance_price ?? selectedOperation.price) *
        (selectedInsurance?.multiplier ?? 0);
    } else {
      price = selectedOperation.price;
    }

    const newOperationAction = {
      operation: selectedOperation.id,
      patient: data.patient,
      doctor: data.doctor,
      duration: data.duration,
      payed_price: price,
      insurance_number: insurance_number,
      insurance_name: selectedInsurance?.value ?? null,
    };
    setIsSubmitting(true);
    axios
      .post(
        rootUrl +
          "/hospital/" +
          currentSelectedHospitalId +
          "/operation_actions",
        newOperationAction,
        {
          headers: {
            Authorization: "Token " + token,
          },
        }
      )
      .then((response) => {
        setNewOperationId(response.data.id);
        setTimeout(() => {
          handlePrint();

          navigate("/opérations");
        }, 10);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <div className="ajouter-wrapper">
      <h1 className="title">Ajouter une opération </h1>
      <div className="positioned-buttons">
        <button className="notif-button">
          <FaRegBell />
        </button>
        <button className="logout-button">
          <MdLogout />
        </button>
      </div>

      <form onSubmit={handleSubmit(submitForm)}>
        <div className="row">
          <div className="column-side">
            <div className="input-grp">
              <label className="label">Le nom de l'opération</label>
              {DefDropdown<OperationInterface>({
                value: selectedOperation?.name ?? "",
                onSelected: (value: OperationInterface) => {
                  setSelectedOperation(value);
                },
                placeholder: "nom de l'operation",
                url:
                  "hospital/" + currentSelectedHospitalId + "/" + "operations",
                search: true,
              })}

              {/* <p className="error-msg">{errors.name?.message}</p> */}
            </div>

            <div className="input-grp">
              <label className="label" htmlFor="duration">
                Durée (en minutes)
              </label>
              <div className="input-p">
                <input
                  className="input-1"
                  type="number"
                  {...register("duration")}
                  name="duration"
                />
                <p className="error-msg">{errors.duration?.message}</p>
              </div>
            </div>
          </div>

          <div className="column-side">
            <div className="input-grp">
              <label className="label" htmlFor="doctor">
                Docteur
              </label>
              <div className="input-p">
                <input
                  className="input-1"
                  {...register("doctor")}
                  name="doctor"
                />
                <p className="error-msg">{errors.doctor?.message}</p>
              </div>
            </div>
            <div className="input-grp">
              <label className="label" htmlFor="patient">
                Nom du patient
              </label>
              <div className="input-p">
                <input
                  className="input-1"
                  {...register("patient")}
                  name="patient"
                />
                <p className="error-msg">{errors.patient?.message}</p>
              </div>
            </div>
            <div className="input-grp">
              <label className="label" htmlFor="insurance_number">
                Matricule CNAM
              </label>
              <div className="input-p">
                <input
                  className="input-1"
                  {...register("insurance_number")}
                  name="insurance_number"
                />
                <p className="error-msg">{errors.insurance_number?.message}</p>
              </div>
            </div>
            <div className="input-grp">
              <label className="label" htmlFor="insurance_number">
                Nom de l'assurance
              </label>
              {
                <select
                  value={selectedInsurance?.value ?? ""}
                  className="input-1"
                  onChange={(e) => {
                    if (e.target.value === "") {
                      setSelectedInsurance(null);
                      return;
                    }
                    setSelectedInsurance(
                      defInsurances.find((i) => i.value === e.target.value)!
                    );
                  }}
                >
                  <option disabled value="" className=" text-gray-300">
                    Choisir le type d'assurance
                  </option>
                  {defInsurances.map((item) => {
                    return (
                      <option key={item.value} value={item.value}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              }
            </div>
          </div>
        </div>
        <input
          className="ajouter-button"
          type="submit"
          value={"Ajouter"}
          disabled={isSubmitting}
        />
      </form>

      <div ref={componentRef}>
        <PrintableTicket
          id={newOperationId?.toString() ?? ""}
          title={"Reçu d'opération"}
          centerName={currentHospital.name}
          data={{
            "Nom du patient": getValues()["patient"],
            Opération: selectedOperation?.name,

            Prix:
              (!selectedOperation
                ? null
                : getValues().insurance_number
                ? (selectedOperation!.insurance_price ??
                    selectedOperation!.price) *
                  (selectedInsurance?.multiplier ?? 0)
                : selectedOperation!.price) + " MRU",
            Docteur: getValues()["doctor"],
            Date:
              new Date().toLocaleDateString() +
              " - " +
              new Date().toLocaleTimeString(),
          }}
          moreData={[]}
        />
      </div>
    </div>
  );
};

export default AddOperationActionPage;
