import { useContext, useRef } from "react";
import { useState, useEffect } from "react";

import {
  MHeader,
  MTableCell,
  MActionsCell,
  ApiData,
  ActionType,
} from "../Components/table/Table";

import Header from "../Components/Header/Header";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { Header2 } from "../Components/Header/Header2";
import Pagination from "../Components/pagination/Pagination";
import { rootUrl } from "../constants";
import { CurrentUserContext } from "../App";
import { useReactToPrint } from "react-to-print";
import PrintList from "../Components/printable_list";
import ModalSupression from "../Components/Modal/ModalSupression";
import { FilterComponent, FilterProps } from "../Components/filter_component";

interface TicketAction {
  id: number;
  payed_price: number;
  ticket_name: string;
  insurance_number: string | null;
  patient: string | null;
  duration: number;
  insurance_name: string;
  created_at: string;
  staff_name: string;
  duration_type: string | null;
  client_name: string | null;
  payload: {
    [key: string]: any;
  } | null;
}

function parseDuration(duration: number, duration_type: string): string {
  let ret = "";

  switch (duration_type) {
    case "1": {
      ret = duration * 30 + " Min";
      break;
    }
    case "2": {
      ret = duration + " Heure(s)";
      break;
    }
    case "3": {
      ret = duration + " Jour(s)";
      break;
    }
    case "4": {
      ret = duration + " Mois";
      break;
    }
    case "5": {
      ret = duration + " An(s)";
      break;
    }
    default: {
      ret = duration + " " + duration_type;
    }
  }
  return ret;
}

const TicketsActions = () => {
  const isAdmin = useContext(CurrentUserContext).currentUserData!.isAdmin;

  const [printing, setPrinting] = useState<boolean>(false);

  const componentRef = useRef<HTMLDivElement>(null);
  const [selected, setSelected] = useState<number[]>([]);
  let [currentPage, setCurrentPage] = useState<number>(1);
  const [data, setData] = useState<ApiData<TicketAction> | null>(null);
  const [searchedText, setSearchedText] = useState<string>("");
  const [isAll, setIsAll] = useState<boolean>(false);
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const [filterProps, setFilterProps] = useState<FilterProps>({
    statsType: null,
    selectedDate: null,
    selectedMonth: null,
    selectedYear: null,
    selectedUserId: null,
  });

  const [deletingId, setDeletingId] = useState<number | null>(null);

  const navigate = useNavigate();
  const [error, setError] = useState(null);

  let currentSelectedHospitalId =
    useContext(CurrentUserContext).currentUserData!.selectedHospitalId;

  let token = useContext(CurrentUserContext).currentUserData!.token;

  const currentHospital = useContext(
    CurrentUserContext
  ).currentUserData!.hospitals.find(
    (hospital) => hospital.id === currentSelectedHospitalId
  )!;

  const [titles, setTitles] = useState<string[]>([]);
  const [payloadsKeys, setPayloadsKeys] = useState<string[]>([]);

  function deleteTicket(id: number) {
    axios
      .delete(
        rootUrl +
          `/hospital/` +
          currentSelectedHospitalId +
          `/ticket_actions/${id}`,
        {
          headers: {
            Authorization: "Token " + token,
          },
        }
      )
      .then((response: { data: any }) => {
        console.log(data);
        FetchData();
      })
      .catch((error: any) => {
        alert("Une erreur s'est produite" + error);
      });
  }

  function FetchData() {
    let filterParams = {};

    if (filterProps.statsType === "daily") {
      filterParams = {
        year: filterProps.selectedDate?.split("-")[0] ?? undefined,
        month: filterProps.selectedDate?.split("-")[1] ?? undefined,
        day: filterProps.selectedDate?.split("-")[2] ?? undefined,
      };
    } else if (filterProps.statsType === "monthly") {
      filterParams = {
        year: filterProps.selectedYear ?? undefined,
        month: filterProps.selectedMonth ?? undefined,
      };
    }
    filterParams = {
      ...filterParams,
      user: filterProps.selectedUserId ?? undefined,
      all: isAll ? "true" : undefined,
    };
    setData(null);
    axios
      .get(
        rootUrl + "/hospital/" + currentSelectedHospitalId + "/ticket_actions",
        {
          params: { page: currentPage, search: searchedText, ...filterParams },
          headers: {
            Authorization: "Token " + token,
          },
        }
      )
      .then((response: { data: any }) => {
        console.log(data);
        setData(response.data);
        setPayloadsKeys([]);
        for (let i = 0; i < response.data.data.length; i++) {
          if (response.data.data[i].payload) {
            const keys = Object.keys(response.data.data[i].payload);
            for (let j = 0; j < keys.length; j++) {
              const key = keys[j];
              if (!payloadsKeys.includes(key)) {
                setPayloadsKeys([...payloadsKeys, key]);
              }
            }

            break;
          }
        }
        setTitles([
          "Date",
          "Nom",
          "Prix",
          "Client",
          ...(currentHospital.has_pharmacy || currentHospital.is_hospital
            ? ["Matricule CNAM"]
            : []),
          "Numéro du reçu",
          "Valeur",
          ...(response.data?.data.filter(
            (item: TicketAction) => Object.keys(item.payload ?? {}).length > 0
          ).length > 0
            ? [...Object.keys(response.data?.data[0].payload ?? {})]
            : []),

          ...(isAdmin ? ["Caissier Responsable", ""] : []),
        ]);
      })
      .catch((error: any) => {
        setError(error);
      });
  }

  useEffect(() => {
    FetchData();
  }, [currentPage, filterProps, isAll]);

  useEffect(() => {
    setFilterProps({
      ...filterProps,
      selectedUserId: null,
    });
  }, [currentSelectedHospitalId]);

  useEffect(() => {
    clearTimeout(typingTimeout);

    // Set a new timeout to delay the search
    const timeoutId = setTimeout(() => {
      setSelected([]);
      currentPage = 1; //should be updated but not render
      FetchData();
    }, 500);

    setTypingTimeout(timeoutId);

    return () => {
      clearTimeout(typingTimeout);
    };
  }, [searchedText]);
  const handlePrint = useReactToPrint({
    onBeforeGetContent() {
      setPrinting(true);
    },

    content: () => {
      return componentRef.current;
    },
    onAfterPrint: () => {
      setPrinting(false);
    },
  });

  return (
    <div className="operations-wrapper">
      {deletingId && (
        <ModalSupression
          open={false}
          name={`le ticket ${
            data!.data.find((item) => item.id === deletingId)!.ticket_name
          }`}
          onClose={function (): void {
            setDeletingId(null);
          }}
          onConfirmClick={function (): void {
            deleteTicket(deletingId);
            setDeletingId(null);
          }}
        />
      )}
      <Header title="Tickets" />
      {
        <Header2
          inputPlaceholder={"Rechercher dans ce tableau"}
          onInputChange={function (text: string): void {
            setSearchedText(text);
          }}
          handlePrint={handlePrint}
          all={isAll}
          onAllClick={function (): void {
            setIsAll(!isAll);
            setCurrentPage(1);
          }}
        />
      }
      {data === null ? (
        // center loading
        //center
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress thickness={6} sx={{ color: "#0B63F8" }} />
        </div>
      ) : (
        <div className="flex flex-col gap-y-2">
          {isAdmin && (
            <div className="flex flex-col  gap-y-2 items-start justify-center ml-10 mb-5">
              <FilterComponent
                filterProps={filterProps}
                setFilterProps={(props: FilterProps) => {
                  setFilterProps(props);
                  setCurrentPage(1);
                }}
              />
              {data.total !== undefined && (
                <div className="flex gap-x-2">
                  <span>Total :</span>
                  <span className="font-bold">
                    {(data.total ?? 0) + " MRU"}
                  </span>
                </div>
              )}
            </div>
          )}
          <Table
            style={{
              backgroundColor: "white",
              marginRight: "40px",
              marginLeft: "40px",
              width: "95.5%",
            }}
          >
            <MHeader
              titles={titles.map((t) => {
                return { label: t, sortable: true };
              })}
              withActions={false}
              onCheckClick={() => {
                if (selected.length === data?.data.length) {
                  setSelected([]);
                } else {
                  setSelected(data?.data.map((item) => item.id) || []);
                }
              }}
              isAllSelected={
                data &&
                data!.data.length !== 0 &&
                selected.length === data?.data.length
              }
            />
            <TableBody>
              {data!.data.map((ticket, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Checkbox
                      checked={selected.includes(ticket.id)}
                      onChange={() => {
                        if (selected.includes(ticket.id)) {
                          setSelected(
                            selected.filter((item) => item !== ticket.id)
                          );
                        } else {
                          setSelected([...selected, ticket.id]);
                        }
                      }}
                    />
                  </TableCell>
                  <MTableCell
                    label={new Date(ticket.created_at).toLocaleDateString()}
                    isTitle={false}
                  />
                  <MTableCell label={ticket.ticket_name} isTitle={false} />

                  <MTableCell
                    label={ticket.payed_price + " MRU"}
                    isTitle={false}
                  />
                  <MTableCell
                    label={ticket.client_name ?? ticket.patient}
                    isTitle={false}
                  />
                  {(currentHospital.has_pharmacy ||
                    currentHospital.is_hospital) && (
                    <MTableCell
                      label={
                        ticket.insurance_number
                          ? ticket.insurance_number +
                            ` (${ticket.insurance_name} )`
                          : "-"
                      }
                      isTitle={false}
                    />
                  )}
                  <MTableCell
                    label={ticket.id.toString().padStart(6, "0")}
                    isTitle={false}
                  />
                  <MTableCell
                    label={
                      ticket.duration && ticket.duration_type
                        ? parseDuration(ticket.duration, ticket.duration_type)
                        : "-"
                    }
                    isTitle={false}
                  />
                  {ticket.payload &&
                    Object.values(ticket.payload).map((value) => {
                      return <MTableCell label={value} isTitle={false} />;
                    })}
                  {Array.from({
                    length:
                      payloadsKeys.length -
                      Object.keys(ticket.payload ?? {}).length,
                  }).map(() => (
                    <MTableCell label={""} isTitle={false} />
                  ))}

                  {isAdmin && (
                    <MTableCell label={ticket.staff_name} isTitle={false} />
                  )}
                  {isAdmin && (
                    <MTableCell
                      label={
                        <button
                          onClick={() => {
                            setDeletingId(ticket.id);
                          }}
                          className="bg-red-400 border-2 text-sm font-medium text-white border-red-400 rounded py-2 px-3 hover:bg-transparent hover:text-red-500 active:scale-95 transition-all "
                        >
                          Supprimer
                        </button>
                      }
                      isTitle={false}
                    />
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      )}
      {data && (
        <Pagination
          currentPage={currentPage}
          totalPages={data!.total_pages}
          onPageChange={function (page: number): void {
            setCurrentPage(page);
          }}
        />
      )}
      <div ref={componentRef}>
        <PrintList
          centerName={currentHospital.name}
          title={"Liste des tickets"}
          afterTable={
            <div className="flex justify-between w-full">
              <div className="flex gap-x-2">
                <span>Total :</span>
                <span className="font-bold">{(data?.total ?? 0) + " MRU"}</span>
              </div>
              {filterProps.selectedUserId && (
                <div>
                  <span>Caissier Responsable :</span>

                  <span className="font-bold">
                    {currentHospital!.staff_data.find(
                      (u) => u.id === filterProps.selectedUserId
                    )?.name ?? ""}
                  </span>
                </div>
              )}
            </div>
          }
          titles={titles.filter((title) => title !== "")}
          data={
            data?.data
              .filter(
                (ticket, index) =>
                  selected.length === 0 || selected.includes(ticket.id)
              )
              .map((ticket, index) => [
                ticket.created_at,
                ticket.ticket_name,
                ticket.payed_price + " MRU",
                ticket.client_name ?? ticket.patient,
                ...(currentHospital.has_pharmacy || currentHospital.is_hospital
                  ? [
                      ticket.insurance_number
                        ? ticket.insurance_number +
                          ` (${ticket.insurance_name} )`
                        : "-",
                    ]
                  : []),
                ticket.id.toString().padStart(6, "0"),
                ticket.duration && ticket.duration_type
                  ? parseDuration(ticket.duration, ticket.duration_type)
                  : "-",
                ...(ticket.payload && Object.keys(ticket.payload).length > 0
                  ? [
                      <div className="flex flex-col">
                        {Object.keys(ticket.payload ?? {}).map((key) => (
                          <div>{ticket.payload![key]}</div>
                        )) ?? <div></div>}
                      </div>,
                    ]
                  : []),
                ...Array.from({
                  length:
                    payloadsKeys.length -
                    Object.keys(ticket.payload ?? {}).length,
                }).map(() => ""),

                ...(isAdmin ? [ticket.staff_name] : []),
              ]) ?? []
          }
        />
      </div>
    </div>
  );
};

export default TicketsActions;
