import React, { ReactNode, useContext, useRef } from "react";
import { useState, useEffect } from "react";

import {
  MHeader,
  MTableCell,
  MActionsCell,
  ApiData,
  ActionType,
} from "../Components/table/Table";
import { BsStopwatch } from "react-icons/bs";
import { FaRegCalendarTimes } from "react-icons/fa";
import { MdFileDownloadDone } from "react-icons/md";

import Header from "../Components/Header/Header";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import QuantityInput from "../Components/QuantityInput/QuantityInput";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { Header2 } from "../Components/Header/Header2";
import Pagination from "../Components/pagination/Pagination";
import { rootUrl } from "../constants";
import { CurrentUserContext } from "../App";
import { useReactToPrint } from "react-to-print";
import OperationStatus from "../Components/OperationStatus/OperationStatus";
import PrintList from "../Components/printable_list";
import ModalSupression from "../Components/Modal/ModalSupression";
import { FilterComponent, FilterProps } from "../Components/filter_component";

// interface OperationItem {
//   id: number;
//   name: string;
//   date: Date;
//   price: number;
//   code: string;
//   duration: number;
//   description: string;
//   category: string | null;
//   doctor: string;
//   matricule: string;
//   cnam: string;
//   patient: string;
// }

interface OperatoinAction {
  id: number;
  operation_name: string;
  payed_price: number;
  patient: string;
  duration: number;
  staff_name: string;
  doctor: string;
  created_at: string;
  insurance_number: string;
  insurance_name: string;
}

interface OperationStatusResult {
  status: string;
  color: string;
}

const Operations = () => {
  const [printing, setPrinting] = useState<boolean>(false);
  const [deletingId, setDeletingId] = useState<number | null>(null);

  const componentRef = useRef<HTMLDivElement>(null);
  const [selected, setSelected] = useState<number[]>([]);
  let [currentPage, setCurrentPage] = useState<number>(1);
  const [data, setData] = useState<ApiData<OperatoinAction> | null>(null);
  const [searchedText, setSearchedText] = useState<string>("");
  const [isAll, setIsAll] = useState<boolean>(false);
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();

  const navigate = useNavigate();
  const [error, setError] = useState(null);

  const [filterProps, setFilterProps] = useState<FilterProps>({
    statsType: null,
    selectedDate: null,
    selectedMonth: null,
    selectedYear: null,
    selectedUserId: null,
  });

  let currentSelectedHospitalId =
    useContext(CurrentUserContext).currentUserData!.selectedHospitalId;

  const currentHospital = useContext(
    CurrentUserContext
  ).currentUserData!.hospitals.find((h) => h.id === currentSelectedHospitalId);

  let token = useContext(CurrentUserContext).currentUserData!.token;
  const isAdmin = useContext(CurrentUserContext).currentUserData!.isAdmin;
  const titles = [
    { label: "Opération", sortable: true },
    { label: "Statut", sortable: true },
    // { label: "Catégorie", sortable: true },
    { label: "Docteur", sortable: true },
    // { label: "Code", sortable: true },
    { label: "Prix", sortable: true },

    { label: "Durée", sortable: true },
    { label: "Nom du patient", sortable: true },

    { label: "Matricule CNAM", sortable: true },
    { label: "Numéro du reçu", sortable: true },
    ...(isAdmin
      ? [
          { label: "Caissier responsable", sortable: true },
          { label: "", sortable: true, printable: false },
        ]
      : []),
  ];
  function FetchData() {
    let filterParams = {};

    if (filterProps.statsType === "daily") {
      filterParams = {
        year: filterProps.selectedDate?.split("-")[0] ?? undefined,
        month: filterProps.selectedDate?.split("-")[1] ?? undefined,
        day: filterProps.selectedDate?.split("-")[2] ?? undefined,
      };
    } else if (filterProps.statsType === "monthly") {
      filterParams = {
        year: filterProps.selectedYear ?? undefined,
        month: filterProps.selectedMonth ?? undefined,
      };
    }
    filterParams = {
      ...filterParams,
      user: filterProps.selectedUserId ?? undefined,
      all: isAll ? "true" : undefined,
    };

    axios
      .get(
        rootUrl +
          "/hospital/" +
          currentSelectedHospitalId +
          "/operation_actions",
        {
          params: {
            page: currentPage,
            search: searchedText,
            ...filterParams,
          },

          headers: {
            Authorization: "Token " + token,
          },
        }
      )
      .then((response: { data: any }) => {
        console.log(data);
        setData(response.data);
      })
      .catch((error: any) => {
        setError(error);
      });
  }

  function deleteOperation(id: number) {
    axios
      .delete(
        rootUrl +
          `/hospital/` +
          currentSelectedHospitalId +
          `/operation_actions/${id}`,
        {
          headers: {
            Authorization: "Token " + token,
          },
        }
      )
      .then((response: { data: any }) => {
        console.log(data);
        FetchData();
      })
      .catch((error: any) => {
        alert("Une erreur s'est produite" + error);
      });
  }

  useEffect(() => {
    FetchData();
  }, [currentPage, filterProps, isAll]);

  useEffect(() => {
    setFilterProps({
      ...filterProps,
      selectedUserId: null,
    });
  }, [currentSelectedHospitalId]);

  useEffect(() => {
    clearTimeout(typingTimeout);

    // Set a new timeout to delay the search
    const timeoutId = setTimeout(() => {
      setSelected([]);
      currentPage = 1; //should be updated but not render
      FetchData();
    }, 500);

    setTypingTimeout(timeoutId);

    return () => {
      clearTimeout(typingTimeout);
    };
  }, [searchedText]);
  const handlePrint = useReactToPrint({
    onBeforeGetContent() {
      setPrinting(true);
    },

    content: () => {
      return componentRef.current;
    },
    onAfterPrint: () => {
      setPrinting(false);
    },
  });

  function getStatusForOperation(
    operation: OperatoinAction
  ): OperationStatusResult {
    const scheduledDateTime = new Date(operation.created_at);
    const currentDateTime = new Date();
    const operationEndTime = new Date(
      scheduledDateTime.getTime() + operation.duration * 60000
    ); // Convert minutes to milliseconds

    if (scheduledDateTime > currentDateTime) {
      return { status: "Planifiée", color: "blue" };
    } else if (
      currentDateTime >= scheduledDateTime &&
      currentDateTime <= operationEndTime
    ) {
      return { status: "En cours", color: "orange" };
    } else {
      return { status: "Terminée", color: "green" };
    }
  }

  function getIconForStatus(status: string): ReactNode {
    switch (status) {
      case "Planifiée":
        return <FaRegCalendarTimes />;
      case "En cours":
        return <BsStopwatch />;
      case "Terminée":
        return <MdFileDownloadDone />;
    }
  }

  function parseDuration(duration: number) {
    const hours = Math.floor(duration / 60);
    const minutes = duration % 60;

    return `${hours}h${minutes}`;
  }

  return (
    <div className="operations-wrapper">
      {deletingId && (
        <ModalSupression
          open={false}
          name={`l'opération ${
            data!.data.find((item) => item.id === deletingId)!.operation_name
          }`}
          onClose={function (): void {
            setDeletingId(null);
          }}
          onConfirmClick={function (): void {
            deleteOperation(deletingId);
            setDeletingId(null);
          }}
        />
      )}
      <Header title="Opérations" />
      {
        <Header2
          inputPlaceholder={"Rechercher dans ce tableau"}
          onInputChange={function (text: string): void {
            setSearchedText(text);
          }}
          all={isAll}
          onAllClick={() => {
            setIsAll(!isAll);
            setCurrentPage(1);
          }}
          onAddButtonClick={
            !isAdmin
              ? function (): void {
                  navigate("/Opérations/Ajouter");
                }
              : null
          }
          handlePrint={handlePrint}
        />
      }
      {data === null ? (
        // center loading
        //center
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress thickness={6} sx={{ color: "#0B63F8" }} />
        </div>
      ) : (
        <div className="flex flex-col gap-y-1">
          {isAdmin && (
            <div className="flex flex-col  gap-y-2 items-start justify-center ml-10 mb-5">
              <FilterComponent
                filterProps={filterProps}
                setFilterProps={(props: FilterProps) => {
                  setFilterProps(props);
                  setCurrentPage(1);
                }}
              />
              {data.total !== undefined && (
                <div className="flex gap-x-2">
                  <span>Total :</span>
                  <span className="font-bold">
                    {(data.total ?? 0) + " MRU"}
                  </span>
                </div>
              )}
            </div>
          )}
          <Table
            style={{
              backgroundColor: "white",
              marginRight: "40px",
              marginLeft: "40px",
              width: "95.5%",
            }}
          >
            <MHeader
              titles={titles}
              onCheckClick={() => {
                if (selected.length === data?.data.length) {
                  setSelected([]);
                } else {
                  setSelected(data?.data.map((item) => item.id) || []);
                }
              }}
              withActions={false}
              isAllSelected={
                data &&
                data!.data.length !== 0 &&
                selected.length === data?.data.length
              }
            />
            <TableBody>
              {data!.data.map((operation, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Checkbox
                      checked={selected.includes(operation.id)}
                      onChange={() => {
                        if (selected.includes(operation.id)) {
                          setSelected(
                            selected.filter((item) => item !== operation.id)
                          );
                        } else {
                          setSelected([...selected, operation.id]);
                        }
                      }}
                    />
                  </TableCell>
                  <MTableCell
                    label={operation.operation_name}
                    isTitle={false}
                  />
                  <TableCell>
                    <OperationStatus
                      status={getStatusForOperation(operation).status}
                      icon={getIconForStatus(
                        getStatusForOperation(operation).status
                      )}
                      color={getStatusForOperation(operation).color}
                    />
                  </TableCell>
                  <MTableCell
                    label={
                      operation.doctor === null ? "-" : `#${operation.doctor}`
                    }
                    isTitle={false}
                    clickable={true}
                  />
                  <MTableCell
                    label={`${operation.payed_price}`}
                    isTitle={false}
                  />
                  <MTableCell
                    label={`${parseDuration(operation.duration)} Min`}
                    isTitle={false}
                  />
                  <MTableCell label={operation.patient} isTitle={false} />

                  <MTableCell
                    label={
                      operation.insurance_number
                        ? operation.insurance_number +
                          ` (${operation.insurance_name} )`
                        : "-"
                    }
                    isTitle={false}
                  />
                  <MTableCell
                    label={operation.id.toString().padStart(6, "0")}
                    isTitle={false}
                  />
                  {isAdmin && (
                    <MTableCell label={operation.staff_name} isTitle={false} />
                  )}
                  {isAdmin && (
                    <MTableCell
                      label={
                        <button
                          onClick={() => {
                            setDeletingId(operation.id);
                          }}
                          className="bg-red-400 border-2 text-sm font-medium text-white border-red-400 rounded py-2 px-3 hover:bg-transparent hover:text-red-500 active:scale-95 transition-all "
                        >
                          Supprimer
                        </button>
                      }
                      isTitle={false}
                    />
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      )}
      {data && (
        <Pagination
          currentPage={currentPage}
          totalPages={data!.total_pages}
          onPageChange={function (page: number): void {
            setCurrentPage(page);
          }}
        />
      )}
      <div ref={componentRef}>
        <PrintList
          centerName={currentHospital!.name}
          titles={titles
            .filter((i) => i.printable !== false)
            .map((item) => item.label)}
          title={"Liste des opérations"}
          afterTable={
            <div className="flex justify-between w-full ">
              <div className="flex gap-x-2">
                <span>Total :</span>
                <span className="font-bold">{(data?.total ?? 0) + " MRU"}</span>
              </div>
              {filterProps.selectedUserId && (
                <div>
                  <span>Caissier Responsable :</span>

                  <span className="font-bold">
                    {currentHospital!.staff_data.find(
                      (u) => u.id === filterProps.selectedUserId
                    )?.name ?? ""}
                  </span>
                </div>
              )}
            </div>
          }
          data={
            data?.data
              .filter((operation) => {
                return selected.length === 0
                  ? true
                  : selected.includes(operation.id);
              })
              .map((operation, index) => [
                operation.operation_name,
                getStatusForOperation(operation).status,
                operation.doctor === null ? "-" : `${operation.doctor}`,
                operation.payed_price,
                parseDuration(operation.duration),
                operation.patient,

                operation.insurance_number
                  ? operation.insurance_number +
                    ` (${operation.insurance_name ?? ""} )`
                  : "-",
                operation.id.toString().padStart(6, "0"),
                ...(isAdmin ? [operation.staff_name] : []),
              ]) ?? []
          }
        />
      </div>
    </div>
  );
};

export default Operations;
