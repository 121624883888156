import React, { FC, useEffect, useState } from "react";
import "./modalsupression.css";

interface ModalProps {
  open: boolean;
  onClose: () => void;
  onConfirmClick: () => void;
  name: string | null;
}
const ModalSupression: FC<ModalProps> = ({
  open,
  onClose,
  name,
  onConfirmClick,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    setModalOpen(open);
  }, [open]);

  const closeModal = () => {
    setModalOpen(false);
    setTimeout(() => {
      onClose();
    }, 500); // Assuming the closing animation duration is 0.5s
  };

  return (
    <div className={`modal${modalOpen ? " open" : ""}`}>
      <div className={`modal-content${modalOpen ? " opening" : ""}`}>
        <div className="title-svg-wrapper">
          <h2 className="popup-title">Confirmer l’action</h2>
          <div className="close-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              onClick={onClose}
            >
              <path
                d="M19.5459 17.9541C19.7572 18.1654 19.876 18.452 19.876 18.7509C19.876 19.0498 19.7572 19.3365 19.5459 19.5478C19.3346 19.7592 19.0479 19.8779 18.749 19.8779C18.4501 19.8779 18.1635 19.7592 17.9521 19.5478L12 13.5937L6.0459 19.5459C5.83455 19.7573 5.54791 19.876 5.24902 19.876C4.95014 19.876 4.66349 19.7573 4.45215 19.5459C4.2408 19.3346 4.12207 19.0479 4.12207 18.7491C4.12207 18.4502 4.2408 18.1635 4.45215 17.9522L10.4062 12L4.45402 6.04593C4.24268 5.83459 4.12395 5.54795 4.12395 5.24906C4.12395 4.95017 4.24268 4.66353 4.45402 4.45218C4.66537 4.24084 4.95201 4.12211 5.2509 4.12211C5.54978 4.12211 5.83643 4.24084 6.04777 4.45218L12 10.4062L17.954 4.45125C18.1654 4.2399 18.452 4.12117 18.7509 4.12117C19.0498 4.12117 19.3364 4.2399 19.5478 4.45125C19.7591 4.66259 19.8778 4.94924 19.8778 5.24812C19.8778 5.54701 19.7591 5.83365 19.5478 6.045L13.5937 12L19.5459 17.9541Z"
                fill="black"
                fill-opacity="0.6"
              />
            </svg>
          </div>
        </div>

        <p className="paragraph">
          Voulez-vous vraiment supprimer{" "}
          {name ?? "toutes les données sélectionnées"} ?
        </p>
        <div className="button-wrapper">
          <button className="cancel-button" onClick={onClose}>
            ANNULER
          </button>
          <button className="confirm-button" onClick={onConfirmClick}>
            CONFIRMER
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalSupression;
