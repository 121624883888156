import * as yup from "yup";

export const operationActionSchema = yup.object().shape({
  // name: yup
  //   .string()
  //   .required("Saisissez le nom de l'action")
  //   .min(3, "Le nom doit contenir au moins 3 caractères"),
  // name will be handled customly
  duration: yup
    .number()
    .required("Saisissez une durée en minutes")
    .typeError("Saisissez une durée en minutes"),

  doctor: yup.string().required("Saisissez le nom du docteur"),
  patient: yup.string().required("Saisissez le nom du patient"),
  insurance_number: yup.string().notRequired(),
});

export const analyseActionSchema = yup.object().shape({
  price: yup
    .number()
    .required("Saisissez le prix")
    .typeError("Saisissez le prix"),
  patient: yup.string().required("Saisissez le nom du patient"),
  insurance_number: yup.string().notRequired(),
});

export const operationSchema = yup.object().shape({
  name: yup
    .string()
    .required("Saisissez le nom de l'opération")
    .min(3, "Le nom doit contenir au moins 3 caractères"),

  price: yup
    .number()
    .required("Saisissez le prix")
    .typeError("Saisissez le prix"),
});

export const assureSchema = yup.object().shape({
  name: yup
    .string()
    .required("Saisissez le nom du patient")
    .min(3, "Le nom doit contenir au moins 3 caractères"),
  matricule: yup
    .string()
    .required("Saisissez un Matricule CNAM")
    .min(3, "Le code doit contenir au moins 3 caractères"),
  action: yup.string().required("Saisissez une action"),
  price: yup
    .number()
    .required("Saisissez le prix")
    .typeError("Saisissez le prix"),
  cnam: yup
    .number()
    .required("Saisissez le prix CNAM")
    .typeError("Saisissez le prix CNAM"),
});

export const analyseSchema = yup.object().shape({
  name: yup
    .string()
    .required("Saisissez le nom de l'analyse")
    .min(3, "Le nom doit contenir au moins 3 caractères"),

  price: yup
    .number()
    .required("Saisissez le prix")
    .typeError("Saisissez le prix"),
});
