import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { operationSchema } from "../Validations/operationValidation";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { rootUrl } from "../constants";
import "../Ajouter_medicament/ajouter.css";
import { CurrentUserContext } from "../App";
import { MDropDown } from "../Components/mdropdown/mdropdown";
import { FaRegBell } from "react-icons/fa";
import { MdLogout } from "react-icons/md";
import { useReactToPrint } from "react-to-print";
import { OperationInterface } from "../interfaces";

const UpdateAddOperationDataPage = () => {
  const [printing, setPrinting] = useState<boolean>(false);
  const componentRef = useRef<HTMLDivElement>(null);

  const [error, setError] = useState(null);
  const [category, setCategory] = useState<string[]>([]);
  const { id: operationId } = useParams();
  const [operationData, setOperationData] = useState<OperationInterface | null>(
    null
  );
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  let token = useContext(CurrentUserContext).currentUserData!.token;

  let currentSelectedHospitalId =
    useContext(CurrentUserContext).currentUserData!.selectedHospitalId;

  function FetchCategory() {
    //add params page = page
    axios
      .get(rootUrl + "/hospital/" + currentSelectedHospitalId + "/ope", {
        headers: {
          Authorization: "Token " + token,
        },
      })
      .then((response) => {
        console.log(response.data);
        setCategory(response.data.map((item: any) => item.name));
      })
      .catch((error) => {
        setError(error);
      });
  }

  useEffect(() => {
    if (operationId) {
      axios
        .get(
          rootUrl +
            "/hospital/" +
            currentSelectedHospitalId +
            "/operations/" +
            operationId,
          {
            headers: {
              Authorization: "Token " + token,
            },
          }
        )
        .then((response: { data: any }) => {
          console.log("data returned : ");
          console.log(response);
          setOperationData(response.data);
        })
        .catch((error: any) => {
          navigate("/donnees");
        });
    }

    FetchCategory();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(operationSchema),
  });

  const submitForm = (data: any) => {
    const newOperation = {
      name: data.name,
      price: data.price,
      description: data.description,
      code: data.code,
      category: data.category,

      hospital: currentSelectedHospitalId,
    };

    if (operationId) {
      axios
        .put(
          rootUrl +
            "/hospital/" +
            currentSelectedHospitalId +
            "/operations/" +
            operationId,
          newOperation,
          {
            headers: {
              Authorization: "Token " + token,
            },
          }
        )
        .then((response) => {
          navigate("/donnees");
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      setIsSubmitting(true);
      axios
        .post(
          rootUrl + "/hospital/" + currentSelectedHospitalId + "/operations",
          newOperation,
          {
            headers: {
              Authorization: "Token " + token,
            },
          }
        )
        .then((response) => {
          navigate("/donnees");
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  };

  return (
    <div className="ajouter-wrapper">
      <h1 className="title">
        {!operationId ? "Ajouter une opération" : "Mettre à jour"}{" "}
      </h1>
      <div className="positioned-buttons">
        <button className="notif-button">
          <FaRegBell />
        </button>
        <button className="logout-button">
          <MdLogout />
        </button>
      </div>
      {operationId && !operationData ? (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress thickness={6} sx={{ color: "#0B63F8" }} />
        </div>
      ) : (
        <form onSubmit={handleSubmit(submitForm)}>
          <div className="row">
            <div className="column-side">
              <div className="input-grp">
                <label className="label" htmlFor="name">
                  Le nom de l'opération
                </label>
                <div className="input-p">
                  <input
                    className="input-1"
                    defaultValue={operationData?.name ?? ""}
                    {...register("name")}
                    type="text"
                    name="name"
                  />
                  <p className="error-msg">{errors.name?.message}</p>
                </div>
              </div>
              <div className="input-grp">
                <label className="label" htmlFor="name">
                  Code de l'opération
                </label>
                <div className="input-p">
                  <input
                    className="input-1"
                    // defaultValue={analyseData?.name ?? ""}
                    type="text"
                    name="code"
                  />
                </div>
              </div>
            </div>

            <div className="column-side">
              <div className="input-grp">
                <label className="label" htmlFor="price">
                  Prix
                </label>
                <div className="input-p">
                  <input
                    className="input-1"
                    type="number"
                    defaultValue={operationData?.price ?? ""}
                    {...register("price")}
                    name="price"
                  />
                  <p className="currency">MRU</p>

                  <p className="error-msg">{errors.price?.message}</p>
                </div>
              </div>
            </div>
          </div>

          <input
            className="ajouter-button"
            type="submit"
            disabled={isSubmitting}
            value={operationId ? "Modifier" : "Ajouter"}
          />
        </form>
      )}
      <div
        ref={componentRef}
        className="print-only"
        style={{ fontFamily: "Poppins", margin: "5px" }}
      >
        <h1
          style={{
            textDecoration: "underline",
            fontSize: "16px",
            fontWeight: "bold",
          }}
        >
          Opération
        </h1>
        <div style={{ display: "flex", gap: "10px" }}>
          <p style={{ fontWeight: "bold" }}>Opération:</p>
          <p>{operationData?.name}</p>
        </div>
        <div style={{ display: "flex", gap: "10px" }}>
          <p style={{ fontWeight: "bold" }}>Prix:</p>
          <p>{operationData?.price + " MRU"}</p>
        </div>
      </div>
    </div>
  );
};

export default UpdateAddOperationDataPage;
