import { useContext, useRef } from "react";
import { useState, useEffect } from "react";

import {
  MHeader,
  MTableCell,
  MActionsCell,
  ApiData,
  ActionType,
} from "../Components/table/Table";

import Header from "../Components/Header/Header";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { Header2 } from "../Components/Header/Header2";
import Pagination from "../Components/pagination/Pagination";
import { rootUrl } from "../constants";
import { CurrentUserContext } from "../App";
import { useReactToPrint } from "react-to-print";
import React from "react";
import PrintList from "../Components/printable_list";

// interface Subscriber {
//   id: number;
//   patient: string;
//   ticket_name: string;
//   start_date_epoch: number;
//   end_date_epoch: number;
//   start_date: string;
//   status: boolean;
//   client_name: string;
//   duration: number;
//   duration_type: number;
//   end_date: string;
//   staff_name: string;
//   payload: { [key: string]: string };
// }

// "id": 3,
//             "tickets": [],
//             "is_active": false,
//             "last_sub": null,
//             "name": "client khalil",
//             "hospital": 4
interface ClientInterface {
  id: number;
  is_active: boolean;
  name: string;
  tickets: {
    id: number;
    ticket_name: string;
    created_at: string;
    price: number;
    duration: number;
    duration_type: string;
    client_name: string;
    payload: { [key: string]: string };
    is_hospital_subscription: boolean;
  }[];
}

const SubscriptionsPage = () => {
  const isAdmin = useContext(CurrentUserContext).currentUserData!.isAdmin;

  const [printing, setPrinting] = useState<boolean>(false);

  const componentRef = useRef<HTMLDivElement>(null);
  const [selected, setSelected] = useState<number[]>([]);
  let [currentPage, setCurrentPage] = useState<number>(1);
  const [data, setData] = useState<ApiData<ClientInterface> | null>(null);
  const [searchedText, setSearchedText] = useState<string>("");
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();

  const navigate = useNavigate();
  const [error, setError] = useState(null);

  const [selectedStatus, setSelectedStatus] = useState<boolean | null>(null);

  let currentSelectedHospitalId =
    useContext(CurrentUserContext).currentUserData!.selectedHospitalId;

  let token = useContext(CurrentUserContext).currentUserData!.token;

  const currentHospital = useContext(
    CurrentUserContext
  ).currentUserData!.hospitals.find(
    (hospital) => hospital.id === currentSelectedHospitalId
  )!;

  const [withPayload, setWithPayload] = useState<boolean>(false);

  const titles = [
    { label: "Nom du client", sortable: true },
    { label: "Status", sortable: true },
    { label: "Nature", sortable: true },
    ...(!isAdmin ? [] : [{ label: "Caissier Responsable", sortable: true }]),
  ];

  function FetchData() {
    setData(null);
    axios
      .get(rootUrl + "/hospital/" + currentSelectedHospitalId + "/clients", {
        params: { page: currentPage, search: searchedText, status: 1 },
        headers: {
          Authorization: "Token " + token,
        },
      })
      .then((response: { data: any }) => {
        console.log(data);
        setData(response.data);
        for (let i = 0; i < response.data.data.length; i++) {
          const sub = response.data.data[i] as ClientInterface;
          // if (Object.keys(sub.payload).length > 0) {
          //   setWithPayload(true);
          //   break;
          // }
        }
      })
      .catch((error: any) => {
        setError(error);
      });
  }

  useEffect(() => {
    FetchData();
  }, [currentPage, currentSelectedHospitalId]);

  useEffect(() => {
    clearTimeout(typingTimeout);

    // Set a new timeout to delay the search
    const timeoutId = setTimeout(() => {
      setSelected([]);
      currentPage = 1; //should be updated but not render
      FetchData();
    }, 500);

    setTypingTimeout(timeoutId);

    return () => {
      clearTimeout(typingTimeout);
    };
  }, [searchedText]);
  const handlePrint = useReactToPrint({
    onBeforeGetContent() {
      setPrinting(true);
    },

    content: () => {
      return componentRef.current;
    },
    onAfterPrint: () => {
      setPrinting(false);
    },
  });

  return (
    <div className="operations-wrapper">
      <Header title={"Inscriptions"} />

      {
        <Header2
          inputPlaceholder={"Rechercher dans ce tableau"}
          onInputChange={function (text: string): void {
            setSearchedText(text);
          }}
          handlePrint={handlePrint}
        />
      }
      {data === null ? (
        // center loading
        //center
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress thickness={6} sx={{ color: "#0B63F8" }} />
        </div>
      ) : (
        <div>
          <Table
            style={{
              backgroundColor: "white",
              marginRight: "40px",
              marginLeft: "40px",
              width: "95.5%",
            }}
          >
            <MHeader
              titles={titles}
              withActions={false}
              onCheckClick={() => {
                if (selected.length === data?.data.length) {
                  setSelected([]);
                } else {
                  setSelected(data?.data.map((item) => item.id) || []);
                }
              }}
              isAllSelected={
                data &&
                data!.data.length !== 0 &&
                selected.length === data?.data.length
              }
            />
            <TableBody>
              {data!.data.map((client, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Checkbox
                      checked={selected.includes(client.id)}
                      onChange={() => {
                        if (selected.includes(client.id)) {
                          setSelected(
                            selected.filter((item) => item !== client.id)
                          );
                        } else {
                          setSelected([...selected, client.id]);
                        }
                      }}
                    />
                  </TableCell>
                  {/* { label: "Nom du client", sortable: true },
    { label: "Ticket", sortable: true },
    {label : "De", sortable: true},
    {label : "à", sortable: true},
    { label: "Status", sortable: true },
    ...(!isAdmin ? [] : [{ label: "Caissier Responsable", sortable: true }]), */}
                  <MTableCell label={client.name} isTitle={false} />
                  <MTableCell
                    label={
                      client.tickets.filter((t) => {
                        if (t.is_hospital_subscription) {
                          return false;
                        }
                        const duration =
                          (t.duration_type === "4" ? 30 : 365) * t.duration;
                        const startDate = new Date(t.created_at);
                        const endDate = new Date(
                          startDate.getTime() + duration * 86400000
                        );
                        return endDate.getTime() > Date.now();
                      }).length > 0 ? (
                        <span style={{ color: "green" }}>Actif</span>
                      ) : (
                        <span style={{ color: "red" }}>Inactif</span>
                      )
                    }
                    isTitle={false}
                  />
                  <MTableCell
                    label={client.tickets
                      .filter((t) => t.is_hospital_subscription)
                      .map((t, index) => {
                        let ret = t.payload["nom de formation"] ?? "";
                        if (
                          index !==
                          client.tickets.filter(
                            (t) => t.is_hospital_subscription
                          ).length -
                            1
                        ) {
                          ret += ", ";
                        }
                        return ret;
                      })}
                    isTitle={false}
                  />

                  {/* {isAdmin && (
                    <MTableCell label={client.staff_name} isTitle={false} />
                  )} */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      )}
      {data && (
        <Pagination
          currentPage={currentPage}
          totalPages={data!.total_pages}
          onPageChange={function (page: number): void {
            setCurrentPage(page);
          }}
        />
      )}
      <div ref={componentRef}>
        <PrintList
          centerName={currentHospital.name}
          title={"LISTE DES INSCRIPTIONS"}
          titles={titles.map((item) => item.label)}
          data={
            data?.data
              .filter(
                (client, index) =>
                  selected.length === 0 || selected.includes(client.id)
              )
              .map((client, index) => [
                client.name,
                client.tickets.filter((t) => {
                  if (t.is_hospital_subscription) {
                    return false;
                  }
                  const duration =
                    (t.duration_type === "4" ? 30 : 365) * t.duration;
                  const startDate = new Date(t.created_at);
                  const endDate = new Date(
                    startDate.getTime() + duration * 86400000
                  );
                  return endDate.getTime() > Date.now();
                }).length > 0
                  ? "Actif"
                  : "Inactif",
                client.tickets
                  .filter((t) => t.is_hospital_subscription)
                  .map((t, index) => {
                    let ret = t.payload["nom de formation"] ?? "";
                    if (
                      index !==
                      client.tickets.filter((t) => t.is_hospital_subscription)
                        .length -
                        1
                    ) {
                      ret += ", ";
                    }
                    return ret;
                  }),
              ]) ?? []
          }
        />
      </div>
    </div>
  );
};

export default SubscriptionsPage;
