import React from "react";
import "./pagination.css";

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
}) => {
  const pageNumbers = [];
  const startPage = Math.max(1, currentPage - 2);
  const endPage = Math.min(totalPages, currentPage + 2);

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }
  return (
    <div className="pagination" style={{ color: "red" }}>
      {currentPage > 1 && (
        <button
          className="navigation-button"
          onClick={() => onPageChange(currentPage - 1)}
        >
          Précédent
        </button>
      )}
      {pageNumbers.map((page) => (
        <button
          key={page}
          onClick={() => onPageChange(page)}
          className={currentPage === page ? "active-page" : "inactive-page"}
        >
          {page}
        </button>
      ))}
      {currentPage < totalPages && (
        <button
          className="navigation-button"
          onClick={() => onPageChange(currentPage + 1)}
        >
          Suivant
        </button>
      )}
    </div>
  );
};

export default Pagination;
