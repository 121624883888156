import React, { FC, useContext, useEffect, useState } from "react";
import "./modalsupression.css";
import axios from "axios";
import { CurrentUserContext } from "../../App";
import { rootUrl } from "../../constants";

interface ModalProps {
  open: boolean;
  onClose: () => void;
  onConfirmClick: (paymentData: PaymentData) => void;
  name: string | null;
  initalRevenue: number | null;
}

export interface PaymentData {
  amount: number;
  quittance_number: string;
}

const PaymentModal: FC<ModalProps> = ({
  open,
  onClose,
  name,
  onConfirmClick,
  initalRevenue,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [numeroQuittance, setNumeroQuittance] = useState("");
  const [revenue, setRevenue] = useState<number | null>(initalRevenue);

  useEffect(() => {
    setModalOpen(open);
  }, [open]);

  const closeModal = () => {
    setModalOpen(false);
    setTimeout(() => {
      onClose();
    }, 500);
  };

  const handlePayment = () => {
    if (!numeroQuittance) {
      alert("Veuillez remplir tous les champs");
      return;
    }
    if (
      !numeroQuittance ||
      isNaN(parseInt(numeroQuittance)) ||
      parseInt(numeroQuittance) <= 0
    ) {
      alert("Veuillez entrer un numero de quittance valide");
      return;
    }
    if (revenue === null || isNaN(revenue) || revenue < 0) {
      alert("Veuillez entrer un montant valide");
      return;
    }

    const paymentData: PaymentData = {
      amount: revenue!,
      quittance_number: numeroQuittance,
    };

    onConfirmClick(paymentData);
    onClose();
  };

  return (
    <div className={`modal${modalOpen ? " open" : ""}`}>
      <div className={`modal-content${modalOpen ? " opening" : ""}`}>
        <div className="title-svg-wrapper">
          <h2 className="popup-title">Versement </h2>

          <div className="close-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              onClick={onClose}
            >
              {/* ... (your close icon path) */}
            </svg>
          </div>
        </div>
        {/* {for_validation && (
          <h2 className=" text-gray-400 ml-1">
            Veuillez re-entrer le numero de quittance
          </h2>
        )} */}
        <div className="input-wrapper pt-5">
          <input
            placeholder="Montant payé (MRU)"
            disabled={initalRevenue !== null}
            className="name-input"
            onChange={(e) => {
              if (e.target.value) {
                if (isNaN(parseInt(e.target.value))) {
                  return;
                }
                setRevenue(parseInt(e.target.value));
              } else {
                setRevenue(null);
              }
            }}
            type="text"
            id="montantPaye"
            value={revenue ?? ""}
          />
        </div>
        <div className="input-wrapper">
          <input
            placeholder="Numéro de quittance"
            className="name-input"
            type="text"
            id="numeroQuittance"
            value={numeroQuittance}
            onChange={(e) => setNumeroQuittance(e.target.value)}
          />
        </div>

        <div className="button-wrapper">
          <button className="cancel-button" onClick={onClose}>
            ANNULER
          </button>
          <button className="confirm-button" onClick={handlePayment}>
            CONFIRMER
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaymentModal;
