import { ReactNode, useEffect } from "react";
import { string } from "yup";

function PrintHeader() {
  return (
    <div className="flex flex-col items-end -mx-6">
      <img src="\images\joumhouria_image.png" className="pr-36 w-96" />

      <div className="my-2 w-full  relative">
        <hr className="border-[#006400] border-[1.5px] w-full " />
        <hr className="border-[#FFD700] border-[1.5px] w-full" />

        <hr className="border-[#FF0000] border-[1.5px] w-full " />
        <img
          src="\images\rim.png"
          className="absolute w-24 h-24 right-4 inset-y-0 -translate-y-12 "
        />
      </div>
      <div className="flex flex-row justify-between w-full mb-6 pl-4 pr-36 text-xs">
        <div className="flex flex-col leading-4  items-center  w-fit font-semibold">
          <h1>
            وزارة الــــــــــــداخليـــــــــــــــة و
            اللامــــركزيــــــــــــــــــة
          </h1>
          <h1>Ministère de la l'Intérieur et de la Décentralisation</h1>
          <h1>بلديــــــــــــة انواذيبـــــــــــــو</h1>
          <h1>Commune de Nouadhibou</h1>
        </div>
        <div className="flex flex-col leading-4  items-center  w-fit ml-2 font-medium text-sm">
          <h1 className=" font-semibold">RÉPUBLIQUE ISLAMIQUE DE MAURITANIE</h1>
          <h1 className=" text-xs">Honeur - Fraternité - Justice</h1>
        </div>
      </div>
    </div>
  );
}
export default function PrintList({
  title,
  titles,
  data,
  beforeTable,
  afterTable,
  showRegisseur = false,
  centerName,
}: {
  title: string;
  titles: string[];
  data: ReactNode[][];
  beforeTable?: ReactNode;
  afterTable?: ReactNode;
  showRegisseur?: boolean;
  centerName: string | null;
}) {
  return (
    <div className="print-only p-6 flex flex-col justify-between w-full h-full">
      <PrintHeader />
      <div className="w-full flex justify-between mb-4 px-6 font-semibold">
        <span>{centerName ?? ""}</span>
        {new Date().toLocaleDateString()}
      </div>
      <div className="font-semibold border p-2 mb-6 rounded border-black self-center">
        {title}
      </div>
      {beforeTable}
      <table className=" border-collapse w-full ">
        <tr>
          {titles.map((title) => (
            <th className=" border-black border-2">{title}</th>
          ))}
        </tr>
        {data.map((row) => (
          <tr className="">
            {row.map((cell) => (
              <td className="flex-grow border p-2 border-black ">{cell}</td>
            ))}
          </tr>
        ))}
      </table>
      {afterTable}
      {showRegisseur && (
        <div className="w-full flex flex-col font-pop pr-10  mt-10">
          <h1 className="self-end">REGISSEUR PRINCIPAL</h1>
          <h1 className="self-end font-semibold">Ahmed Limam</h1>
        </div>
      )}
    </div>
  );
}
