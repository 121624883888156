import React, { useContext } from "react";
import { useState, useEffect, useRef } from "react";
import { FaFileImport } from "react-icons/fa6";
import {
  MHeader,
  MTableCell,
  MActionsCell,
  ApiData,
  ActionType,
} from "../Components/table/Table";
import Header from "../Components/Header/Header";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import QuantityInput from "../Components/QuantityInput/QuantityInput";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { Header2, HeaderDelete } from "../Components/Header/Header2";
import ModalSupression from "../Components/Modal/ModalSupression";
import "./medicaments.css";
import { MedicationItem } from "../interfaces";
import Pagination from "../Components/pagination/Pagination";
import { rootUrl } from "../constants";
import { CurrentUserContext } from "../App";
import { useReactToPrint } from "react-to-print";
import Fileuploadmodal from "../Components/Modal/fileuploadmodal";
import PrintList from "../Components/printable_list";
export const titles = [
  { label: "Médicament", sortable: true },
  { label: "Prix", sortable: true },
  { label: "Quantité", sortable: true },
];

const Medicaments = () => {
  const [printing, setPrinting] = useState<boolean>(false);
  const componentRef = useRef<HTMLDivElement>(null);
  const [selected, setSelected] = useState<number[]>([]);
  let [currentPage, setCurrentPage] = useState<number>(1);
  const [data, setData] = useState<ApiData<MedicationItem> | null>(null);
  const [searchedText, setSearchedText] = useState<string>("");
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const [quantityTimeouts, setQuantityTimeouts] = useState<
    Map<number, NodeJS.Timeout>
  >(new Map());

  const navigate = useNavigate();
  const [error, setError] = useState(null);

  let currentSelectedHospitalId =
    useContext(CurrentUserContext).currentUserData!.selectedHospitalId;
  const currentHospital = useContext(
    CurrentUserContext
  ).currentUserData!.hospitals.find((h) => h.id === currentSelectedHospitalId);

  const [deletingId, setDeletingId] = useState<number[]>([]);
  const [openedDescription, setOpenDescription] = useState<number | null>(null);
  const [openedFileUpload, setOpenedFileUpload] = useState(false);
  const [isAll, setIsAll] = useState(false);

  const isAdmin = useContext(CurrentUserContext).currentUserData!.isAdmin;

  const closeFileUploadModal = () => {
    setOpenedFileUpload(false);
  };

  let token = useContext(CurrentUserContext).currentUserData!.token;

  function updateQuantity(id: number, quantity: number) {
    const timeoutId = quantityTimeouts.get(id);
    console.log(timeoutId);
    if (timeoutId !== undefined) {
      clearTimeout(timeoutId);
    }
    const timeout = setTimeout(() => {
      // alert("update quantity for " + id + " to " + quantity);
      axios
        .patch(
          rootUrl +
            "/hospital/" +
            currentSelectedHospitalId +
            "/inventory/" +
            id,
          {
            quantity: quantity,
          },
          {
            headers: {
              Authorization: "Token " + token,
            },
          }
        )
        .then((response: { data: any }) => {
          console.log("data returned : ");
          console.log(response);
        })
        .catch((error: any) => {
          alert("Une erreur s'est produite" + error);
        });
    }, 500);
    quantityTimeouts.set(id, timeout);
  }

  function FetchData() {
    // setData(null);
    axios
      .get(rootUrl + "/hospital/" + currentSelectedHospitalId + "/inventory", {
        params: {
          page: currentPage,
          search: searchedText,
          all: isAll ? "true" : undefined,
        },
        headers: {
          Authorization: "Token " + token,
        },
      })
      .then((response: { data: any }) => {
        console.log(data);
        setData(response.data);
      })
      .catch((error: any) => {
        setError(error);
      });
  }

  const deleteMedicament = (id: number) => {
    axios
      .delete(
        rootUrl + `/hospital/${currentSelectedHospitalId}/inventory/${id}`,
        {
          headers: {
            Authorization: "Token " + token,
          },
        }
      )
      .then((response: { data: any }) => {
        console.log(response);
        // Fetch data again after successful deletion
        FetchData();
      })
      .catch((error: any) => {
        alert("Une erreur s'est produite" + error);
      });
  };

  useEffect(() => {
    FetchData();
  }, [currentPage, currentSelectedHospitalId, isAll]);

  useEffect(() => {
    clearTimeout(typingTimeout);

    // Set a new timeout to delay the search
    const timeoutId = setTimeout(() => {
      setSelected([]);
      currentPage = 1; //should be updated but not render
      FetchData();
    }, 500);

    setTypingTimeout(timeoutId);

    return () => {
      clearTimeout(typingTimeout);
    };
  }, [searchedText]);

  const handlePrint = useReactToPrint({
    onBeforeGetContent() {
      setPrinting(true);
    },

    content: () => {
      return componentRef.current;
    },
    onAfterPrint: () => {
      setPrinting(false);
    },
  });

  return (
    <div className="medicament-wrapper">
      {deletingId.length > 0 && (
        <ModalSupression
          open={false}
          name={
            deletingId.length === 1
              ? data!.data.find((item) => item.id === deletingId[0])!.name
              : null
          }
          onClose={function (): void {
            setDeletingId([]);
          }}
          onConfirmClick={function (): void {
            deletingId.forEach((id) => deleteMedicament(id));
            setDeletingId([]);
          }}
        />
      )}

      <Header title="Médicaments" />
      {selected.length > 0 ? (
        <HeaderDelete
          legend={"Médicament"}
          onDeleteClick={() => {
            setDeletingId([...selected]);
          }}
          selectedLength={selected.length}
          handlePrint={handlePrint}
        />
      ) : (
        <Header2
          inputPlaceholder={"Rechercher dans ce tableau"}
          all={isAll}
          onAllClick={() => {
            setIsAll(!isAll);
            setCurrentPage(1);
          }}
          onInputChange={function (text: string): void {
            setSearchedText(text);
          }}
          onAddButtonClick={function (): void {
            navigate("/Médicaments/Ajouter");
          }}
          handlePrint={handlePrint}
        />
      )}
      {data === null ? (
        // center loading
        //center
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress thickness={6} sx={{ color: "#0B63F8" }} />
        </div>
      ) : (
        <div>
          <button
            className="ml-[40px] transition-all active:scale-95 flex items-center gap-2 text-[#36AE7C] hover:text-white border border-[#36AE7C] hover:bg-green-800  font-medium rounded text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-[#36AE7C] dark:text-[#36AE7C] dark:hover:text-white dark:hover:bg-[#36AE7C] 
"
            onClick={() => {
              setOpenedFileUpload(true);
            }}
          >
            <FaFileImport /> Importer
          </button>
          {openedFileUpload && (
            <Fileuploadmodal
              onFileUpload={(file) => {}}
              setOpenedFileUpload={() => closeFileUploadModal()}
            />
          )}
          <Table
            style={{
              backgroundColor: "white",
              marginRight: "40px",
              marginLeft: "40px",
              width: "95.5%",
            }}
          >
            <MHeader
              titles={titles}
              onCheckClick={() => {
                if (selected.length === data?.data.length) {
                  setSelected([]);
                } else {
                  setSelected(data?.data.map((item) => item.id) || []);
                }
              }}
              isAllSelected={
                data &&
                data!.data.length !== 0 &&
                selected.length === data?.data.length
              }
            />
            <TableBody>
              {data!.data.map((medicament, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Checkbox
                      checked={selected.includes(medicament.id)}
                      onChange={() => {
                        if (selected.includes(medicament.id)) {
                          setSelected(
                            selected.filter((item) => item !== medicament.id)
                          );
                        } else {
                          setSelected([...selected, medicament.id]);
                        }
                      }}
                    />
                  </TableCell>
                  <MTableCell label={medicament.name} isTitle={false} />

                  <MTableCell
                    label={`${medicament.price} MRU`}
                    isTitle={false}
                  />

                  <MTableCell
                    isTitle={false}
                    label={
                      <span
                        className={
                          "font-semibold text-xl mx-auto " +
                          (medicament.quantity < 10 ? "text-red-500" : "")
                        }
                      >
                        {medicament.quantity}
                      </span>
                    }
                  />

                  {/* <QuantityInput
                      quantity={medicament.quantity}
                      onQuantityChange={(value) => {
                        if (value >= 0) {
                          let id = medicament.id;
                          let newData = data.data.map((item) => {
                            if (item.id === id) {
                              item.quantity = value;
                            }
                            return item;
                          });
                          setData({ ...data, data: newData });
                          updateQuantity(id, value);
                        }
                      }}
                    /> */}

                  {isAdmin ? (
                    <MActionsCell
                      onClick={(a) => {
                        if (a === ActionType.DELETE) {
                          setDeletingId([medicament.id]);
                        } else if (a === ActionType.VIEW) {
                          setOpenDescription(medicament.id);
                        } else if (a === ActionType.EDIT) {
                          navigate(
                            "/Médicaments/" + medicament.id + "/Modifier/"
                          );
                        }
                      }}
                    />
                  ) : (
                    <TableCell />
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      )}
      {data && (
        <Pagination
          currentPage={currentPage}
          totalPages={data!.total_pages}
          onPageChange={function (page: number): void {
            setCurrentPage(page);
          }}
        />
      )}
      <div ref={componentRef}>
        <PrintList
          centerName={currentHospital!.name}
          title="Liste des médicaments"
          data={
            data?.data.map((medicament) => {
              return [medicament.name, medicament.price, medicament.quantity];
            }) ?? []
          }
          titles={titles.map((title) => title.label)}
        />
      </div>
    </div>
  );
};

export default Medicaments;
