import React, { useContext, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Password } from "@mui/icons-material";
import axios from "axios";
import { UserDataInterface } from "../interfaces";
import { CurrentUserContext } from "../App";
import { rootUrl } from "../constants";
import { HiOutlineKey } from "react-icons/hi2";
import { FaRegUser } from "react-icons/fa6";

const Login = () => {
  const [loginError, setLoginError] = useState("");

  const loginSchema = yup.object().shape({
    username: yup.string().required("Veuillez saisir votre identifiant "),
    password: yup.string().required("Veuillez saisir votre mot de passe"),
  });

  let setCurrentUser = useContext(CurrentUserContext).setCurrentUser;

  function login({
    username,
    password,
  }: {
    username: string;
    password: string;
  }) {
    axios
      .post(rootUrl + "/login/", {
        username: username,
        password: password,
      })
      .then((response: { data: any }) => {
        const user: UserDataInterface = {
          token: response.data.token,
          isAdmin: response.data.is_admin,
          name: response.data.name,
          hospitals: response.data.hospitals,
          selectedHospitalId: response.data.is_admin
            ? null
            : response.data.hospitals[0].id,
        };
        localStorage.setItem("token", user.token!);
        localStorage.removeItem("selectedHospitalId");
        setCurrentUser(user);
      })
      .catch((e) => {
        setLoginError("Identifiant ou mot de passe incorrect");
        // alert(e);
      });
  }

  const submitForm = (data: any) => {
    const userLogin = {
      username: data.username,
      password: data.password,
    };
    login(userLogin);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginSchema),
  });
  const handleInputChange = () => {
    setLoginError("");
  };

  return (
    <div className="flex flex-1 flex-row h-screen">
      <div className="w-[50%] flex flex-col justify-center items-center">
        <div className="flex flex-col justify-between gap-20">
          <h1 className="text-center font-pop text-3xl text-black font-semibold">
            Connectez-vous pour <br /> continuer
          </h1>
          <form
            className="flex flex-col gap-10"
            onSubmit={handleSubmit(submitForm)}
          >
            <div className="flex flex-col justify-around gap-5">
              <div className="relative shadow-sm">
                <input
                  type="text"
                  className={`p-4 pr-20 outline-none font-pop  ${
                    errors.username || loginError ? "" : ""
                  }`}
                  placeholder="Identifiant"
                  {...register("username")}
                  onChange={handleInputChange}
                />
                <div
                  className={`absolute p-2 bg-[#187498] text-white text-[40px]  top-0 right-0  ${
                    errors.username || loginError ? "bg-red-500" : ""
                  }`}
                >
                  <FaRegUser />
                </div>
              </div>
              <p className="font-pop text-sm text-red-500">
                {errors.username?.message}
              </p>
            </div>

            <div className="flex flex-col justify-around gap-5">
              <div className="shadow-sm flex">
                <input
                  type="password"
                  className={`p-4 outline-none pr-20 font-pop  ${
                    errors.password || loginError ? "" : ""
                  }`}
                  placeholder="Mot de passe"
                  {...register("password")}
                  onChange={handleInputChange}
                />
                <div
                  className={`bg-[#187498] p-2 text-white text-[40px]  top-0 right-0 ${
                    errors.password || loginError ? "bg-red-500" : ""
                  }`}
                >
                  <HiOutlineKey />
                </div>
              </div>
              <p className="font-pop text-sm text-red-500">
                {errors.password?.message}
              </p>
              {loginError && (
                <p className="font-pop text-sm text-red-500">{loginError}</p>
              )}
            </div>
            <input
              type="submit"
              className="bg-[#187498] py-3  text-white font-pop shadow-sm cursor-pointer transition-all duration-300 hover:bg-[#25627c] active:bg-[#1c4a5d]"
              value="Se connecter"
            />
          </form>
        </div>
      </div>
      <div className="w-[50%] overflow-hidden ">
        <img
          className="h-full w-full object-scale"
          src="\images\gestion.jpg"
          alt=""
        />
      </div>
    </div>
  );
};

export default Login;
