
// create a component called mdropdown  
// this component will be used to create a dropdown menu


import React, { Component, useEffect, useRef } from 'react';
import './mdropdown.css';

interface MDropDownProps {
    value? : string,
    onChange : (value : string) => void,
    placeholder : string,
    values : string[],
    name ?: string,
    register? : any,
    defaultValue ?: string
}
export const MDropDown = ({value, onChange, placeholder, values, name , register, defaultValue} : MDropDownProps) => {
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const [filteredValues, setFilteredValues] = React.useState<string[]>(values);
    const thisRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setFilteredValues(values);
    }
    , [values]);

    useEffect(() => {
        const handleClickOutside = (event : MouseEvent ) => {
            
          if (thisRef.current && !thisRef.current.contains(event.target as Node)) {
            setIsOpen(false);   
          }
        };

        document.addEventListener('click', handleClickOutside);
    
        return () => {
          document.removeEventListener('click', handleClickOutside);
        };
      }, []);

    return (
        <div className="mdropdown-wrapper"  ref={thisRef} onClick={()=> {
            //dont remove this if statement !!
            if(!isOpen){
                setIsOpen(true);

            }
        }}>
            <input className='input-1' value = {value}  defaultValue={defaultValue ?? ""}
             onChange={(event)=>{
                const text = event.target.value;
                setFilteredValues(values.filter((val) => val.toLowerCase().includes(text.toLowerCase())));
                onChange(text);
            }}></input>
           {
           isOpen && <div className="mdropdown-items-wrapper">
                 {filteredValues.map((val) => {
                return <div className="mdropdown-item" onClick={() =>{
                    onChange(val);
                    setIsOpen(false)
                    }}>
                    <p> {val}</p>
                </div>

            }
            )}
            </div>
           }

        </div>
    )
}
