import { Sidebar } from "./Components/Sidebar/Sidebar";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Operations from "./Operations/Operations";
import Dashboard from "./Home/Dashboard";
import "./app.css";
import Medicaments from "./Medicaments/Medicaments";
import UpdateAddMedicamentPage from "./Ajouter_medicament/Ajouter";
import AddAnalyseActionPage from "./ajouter/ajouter_analyse_action";
import Login from "./Login/Login";
import Ventes from "./Ventes/Vente";
import { createContext, useContext, useEffect, useState } from "react";
import { UserDataInterface } from "./interfaces";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import HospitalsPage from "./hospitals/hospitals";
import AddOperationActionPage from "./ajouter/Ajouter_operation_action";
import AdminDashboard from "./admin_dashboard/admin_dashboard";
import RenderSidebar from "./Components/Sidebar/RenderSidebar";
import AjouterHospital from "./Ajouter_hospital/Ajouterhospital";
import { AddSalePage } from "./Ajouter_vente/Ajouter_vente";
import Analyses from "./analyses/Analyses";
import MainChart from "./Components/Charts/MainChart";
import Versement from "./versement/Versement";
import Donnees from "./Donnees/Donnees";
import Assures from "./Assures/Assures";
import { rootUrl } from "./constants";
import TicketsActions from "./tickets actions/tickets_actions";
import UsersPage from "./admin_users/Admin_users";
import AjouterUtilisateur from "./ajouter_utilisateurs/Ajouter_utilisateurs";
import UpdateAddAnalyseDataPage from "./Ajouter_data/Ajouteranalyse_data";
import UpdateAddOperationDataPage from "./Ajouter_data/ajouter_operation_data";
import SubscriptionsPage from "./subscriptions_page/subscription_page";
import PiroguePage from "./subscriptions_page/pirogue_page";
import VersementNotPayed from "./versement/versement_notpayed";
import RevenueStatus from "./admin_dashboard/admin_payment_status";
import DirectorsListPage from "./taxe_communal/directors_list_page";
import AlimentationsListPage from "./taxe_communal/alimentations_list_page";
import RefundListPage from "./taxe_communal/refunds_list_page";
import InsurancePaymentsPage from "./insurance_payments/insurance_payments_page";

export const CurrentUserContext = createContext<UserContextInterface>({
  currentUserData: null,
  inited: false,
  setCurrentUser: () => {},
});

interface UserContextInterface {
  currentUserData: UserDataInterface | null;
  inited: boolean;
  setCurrentUser: React.Dispatch<
    React.SetStateAction<UserDataInterface | null>
  >;
}
function App() {
  const [currentUser, setCurrentUser] = useState<UserDataInterface | null>(
    null
  );
  const [inited, setInited] = useState<boolean>(false);
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      setInited(true);
    } else {
      axios
        .post(rootUrl + "/login_token/", {
          token: token,
        })
        .then((response: { data: any }) => {
          let storedSelectedHospitalId =
            localStorage.getItem("selectedHospitalId");
          if (storedSelectedHospitalId !== null) {
            if (
              !response.data.hospitals.find(
                (hospital: any) =>
                  hospital.id === parseInt(storedSelectedHospitalId!)
              )
            ) {
              localStorage.removeItem("selectedHospitalId");
              storedSelectedHospitalId = null;
            }
          }
          const user: UserDataInterface = {
            token: response.data.token,
            isAdmin: response.data.is_admin,
            hospitals: response.data.hospitals,
            selectedHospitalId: response.data.is_admin
              ? storedSelectedHospitalId
                ? parseInt(storedSelectedHospitalId)
                : null
              : response.data.hospitals[0].id,
            name: response.data.name,
          };
          localStorage.setItem("token", user.token!);
          setInited(true);
          setCurrentUser(user);
        })
        .catch((e) => {
          setInited(true);
        });
    }
  }, []);

  return (
    <CurrentUserContext.Provider
      value={{
        currentUserData: currentUser,
        inited: inited,
        setCurrentUser,
      }}
    >
      {!inited ? (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress thickness={6} sx={{ color: "#0B63F8" }} />
        </div>
      ) : !currentUser ? (
        <Login />
      ) : (
        <Router>
          <div className="App">
            <div>
              <RenderSidebar />
            </div>
            <div className="main-content">
              <Routes>
                <Route path="/Médicaments" element={<Medicaments />} />
                <Route path="/Opérations" element={<Operations />} />
                <Route path="/Tickets" element={<TicketsActions />} />
                <Route
                  path="/Médicaments/Ajouter"
                  element={<UpdateAddMedicamentPage />}
                />
                <Route
                  path="/Médicaments/:id/modifier"
                  element={<UpdateAddMedicamentPage />}
                />
                <Route
                  path="/operations/:id/modifier"
                  element={<AddOperationActionPage />}
                />
                <Route path="/Ventes" element={<Ventes />} />
                <Route path="/Login" element={<Login />} />
                <Route path="/admin/hopitaux" element={<HospitalsPage />} />
                <Route path="/admin/utilisateurs" element={<UsersPage />} />

                <Route
                  path="/Opérations/Ajouter"
                  element={<AddOperationActionPage />}
                />
                <Route path="/admin/" element={<AdminDashboard />} />
                <Route path="/" element={<AdminDashboard />} />
                <Route path="/Analyses" element={<Analyses />} />
                <Route
                  path="/Admin/Hopitaux/Ajouter"
                  element={<AjouterHospital />}
                />
                <Route
                  path="/Admin/Utilisateurs/Ajouter"
                  element={<AjouterUtilisateur />}
                />
                <Route
                  path="/Admin/Utilisateurs/:id/Modifier"
                  element={<AjouterUtilisateur />}
                />
                <Route
                  path="/Analyses/Ajouter"
                  element={<AddAnalyseActionPage />}
                />
                <Route path="/ventes/ajouter" element={<AddSalePage />} />
                <Route path="admin/versement" element={<Versement />} />
                <Route
                  path="admin/versement_assurance"
                  element={<InsurancePaymentsPage />}
                />
                <Route
                  path="admin/weeks_not_payed"
                  element={<VersementNotPayed />}
                />
                <Route path="/donnees" element={<Donnees />} />

                <Route
                  path="/donnees/analyses/Ajouter"
                  element={<UpdateAddAnalyseDataPage />}
                />
                <Route
                  path="/donnees/analyses/:id/Modifier"
                  element={<UpdateAddAnalyseDataPage />}
                />

                <Route path="/regisseurs" element={<DirectorsListPage />} />
                <Route
                  path="/alimentations"
                  element={<AlimentationsListPage />}
                />
                <Route path="/remboursements" element={<RefundListPage />} />
                <Route
                  path="/donnees/operations/Ajouter"
                  element={<UpdateAddOperationDataPage />}
                />
                <Route
                  path="/donnees/operations/:id/Modifier"
                  element={<UpdateAddOperationDataPage />}
                />
                <Route path="/CNAM" element={<Assures />} />
                <Route path="/subscriptions" element={<SubscriptionsPage />} />
                <Route path="/pirogues" element={<PiroguePage />} />
                <Route
                  path="/admin/renenue_status"
                  element={<RevenueStatus />}
                />
              </Routes>
            </div>
          </div>
        </Router>
      )}
    </CurrentUserContext.Provider>
  );
}

export default App;
