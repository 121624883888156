import axios from "axios";
import React, { useContext, useEffect } from "react";
import { useRef } from "react";
import { rootUrl } from "../constants";
import { CurrentUserContext } from "../App";
import { Type } from "typescript";

export function DefSearchInput({
  placeHolder,
  onChange = () => {},
  value,
  mini = false,
}: {
  placeHolder: string;
  onChange?: (value: string) => void;
  mini?: boolean;
  value?: string;
}) {
  return (
    <div className={`relative h-min ${mini ? "w-max" : "w-96"}`}>
      <div className="pointer-events-none absolute bottom-0 top-0 flex items-center ps-3">
        <svg
          className="h-4 w-4 text-gray-500 dark:text-gray-400"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 20 20"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
          />
        </svg>
      </div>

      <input
        type="search"
        className="focus:border-1  w-full rounded-sm border border-slate-300  bg-gray-50 p-2 ps-10 text-sm text-gray-900 focus:border-blue-500"
        placeholder={placeHolder}
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        required
      />
    </div>
  );
}

export function DefDropdown<T>({
  title,
  value,
  onSelected,
  placeholder,
  small = false,
  search = false,
  url,
}: {
  title?: string;
  value: string;
  onSelected: (value: T) => void;
  placeholder: string;
  small?: boolean;
  search?: boolean;

  url: string;
}) {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const thisRef = useRef<HTMLDivElement>(null);
  const [searchValue, setSearchValue] = React.useState<string>("");
  const [items, setItems] = React.useState<T[]>([]);

  const token = useContext(CurrentUserContext).currentUserData!.token;

  async function close() {
    setIsOpen(false);
    await new Promise((resolve) => setTimeout(resolve, 200));
    setSearchValue("");
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (thisRef.current && !thisRef.current.contains(event.target as Node)) {
        close();
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      setSearchValue("");
    }
  }, [isOpen]);

  useEffect(() => {
    axios
      .get(rootUrl + "/" + url, {
        headers: {
          Authorization: "Token " + token,
        },
        params: {
          search: searchValue,
        },
      })
      .then((response) => {
        setItems(response.data.data);
      });
  }, [searchValue, url]);

  return (
    <div ref={thisRef} className="gap-y flex flex-col gap-y-1">
      {title && <h3 className=" text-sm text-slate-400">{title}</h3>}
      <div className={`relative`}>
        <div
          className={`flex cursor-pointer items-center justify-between rounded-sm shadow-sm py-5 border border-slate-300 bg-white pl-2 pr-4 ${
            small ? "py-px text-xs" : "py-1"
          }`}
          onClick={() => {
            if (!isOpen) {
              setIsOpen(true);
            } else {
              close();
            }
          }}
        >
          <span>{value}</span>
        </div>
        {
          <div
            className={`absolute z-20 mt-2 w-full origin-top-left rounded-sm border border-slate-300 bg-white py-px shadow transition-all duration-200 ${
              isOpen ? "scale-100 opacity-100" : "scale-0 opacity-0"
            }`}
          >
            {search && isOpen && (
              <div className="my-2 h-min w-52 self-start mx-4">
                <DefSearchInput
                  placeHolder={""}
                  mini={true}
                  value={searchValue}
                  onChange={(v) => {
                    setSearchValue(v);
                  }}
                />
              </div>
            )}
            {search && <hr />}
            {items.map((val, index) => {
              return (
                <div
                  className="cursor-pointer  px-4 py-2 first:rounded-t-sm last:rounded-b-sm hover:bg-gray-200"
                  onClick={() => {
                    onSelected(val);
                    setIsOpen(false);
                  }}
                >
                  {(val as any).name}
                </div>
              );
            })}
          </div>
        }
      </div>
    </div>
  );
}
