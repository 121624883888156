import { useContext, useRef } from "react";
import { useState, useEffect } from "react";

import {
  MHeader,
  MTableCell,
  ApiData,
  ActionType,
} from "../Components/table/Table";

import Header from "../Components/Header/Header";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { Header2 } from "../Components/Header/Header2";
import Pagination from "../Components/pagination/Pagination";
import { defInsurances, rootUrl } from "../constants";
import { CurrentUserContext } from "../App";
import { useReactToPrint } from "react-to-print";
import PrintList from "../Components/printable_list";
import ModalSupression from "../Components/Modal/ModalSupression";
import { FilterComponent, FilterProps } from "../Components/filter_component";

// interface AnalyseItem {
//   id: number;
//   name: string;
//   price: number;
//   code: string;
//   matricule: string;
//   patient: string;
//   description: string;
//   category: string | null;
//   cnam: string;
// }

interface AnalyseAction {
  id: number;
  total: number;
  insurance_number: string;
  insurance_name: string;
  patient: string;
  staff_name: "test";
  created_at: string;
  items: AnalyseActionItem[];
}
interface AnalyseActionItem {
  analyse: number;
  payed_price: number;
  analyse_name: string;
}

const Analyses = () => {
  const isAdmin = useContext(CurrentUserContext).currentUserData!.isAdmin;
  const titles = [
    { label: "Analyse", sortable: true },
    { label: "Date", sortable: true },
    { label: "Prix", sortable: true },
    { label: "Nom du patient", sortable: true },

    { label: "Matricule CNAM", sortable: true },
    { label: "Numéro du reçu", sortable: true },
    ...(!isAdmin
      ? []
      : [
          { label: "Caissier Responsable", sortable: true },
          { label: "", sortable: true },
        ]),
  ];

  const [printing, setPrinting] = useState<boolean>(false);

  const componentRef = useRef<HTMLDivElement>(null);
  const [selected, setSelected] = useState<number[]>([]);
  let [currentPage, setCurrentPage] = useState<number>(1);
  const [data, setData] = useState<ApiData<AnalyseAction> | null>(null);
  const [searchedText, setSearchedText] = useState<string>("");
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const [deletingId, setDeletingId] = useState<number | null>(null);

  const navigate = useNavigate();
  const [error, setError] = useState(null);
  let currentSelectedHospitalId =
    useContext(CurrentUserContext).currentUserData!.selectedHospitalId;

  const currentHospital = useContext(
    CurrentUserContext
  ).currentUserData!.hospitals.find((h) => h.id === currentSelectedHospitalId);

  let token = useContext(CurrentUserContext).currentUserData!.token;

  const [filterProps, setFilterProps] = useState<FilterProps>({
    statsType: null,
    selectedDate: null,
    selectedMonth: null,
    selectedYear: null,
    selectedUserId: null,
  });

  const [isAll, setIsAll] = useState<boolean>(false);

  function FetchData() {
    const stratTime = new Date().getTime();
    let filterParams = {};

    if (filterProps.statsType === "daily") {
      filterParams = {
        year: filterProps.selectedDate?.split("-")[0] ?? undefined,
        month: filterProps.selectedDate?.split("-")[1] ?? undefined,
        day: filterProps.selectedDate?.split("-")[2] ?? undefined,
      };
    } else if (filterProps.statsType === "monthly") {
      filterParams = {
        year: filterProps.selectedYear ?? undefined,
        month: filterProps.selectedMonth ?? undefined,
      };
    }
    filterParams = {
      ...filterParams,
      user: filterProps.selectedUserId ?? undefined,
      all: isAll ? "true" : false,
    };
    axios
      .get(
        rootUrl +
          "/hospital/" +
          currentSelectedHospitalId +
          "/analyses_actions",
        {
          params: { page: currentPage, search: searchedText, ...filterParams },

          headers: {
            Authorization: "Token " + token,
          },
        }
      )
      .then((response: { data: any }) => {
        console.log(data);
        setData(response.data);
        const timeToFetch = new Date().getTime() - stratTime;
      })
      .catch((error: any) => {
        setError(error);
      });
  }

  useEffect(() => {
    FetchData();
  }, [currentPage, filterProps, isAll]);

  useEffect(() => {
    setFilterProps({
      ...filterProps,
      selectedUserId: null,
    });
  }, [currentSelectedHospitalId]);

  useEffect(() => {
    clearTimeout(typingTimeout);

    // Set a new timeout to delay the search
    const timeoutId = setTimeout(() => {
      setSelected([]);
      currentPage = 1; //should be updated but not render
      FetchData();
    }, 500);

    setTypingTimeout(timeoutId);

    return () => {
      clearTimeout(typingTimeout);
    };
  }, [searchedText]);

  const handlePrint = useReactToPrint({
    onBeforeGetContent() {
      setPrinting(true);
    },

    content: () => {
      return componentRef.current;
    },
    onAfterPrint: () => {
      setPrinting(false);
    },
  });

  function deleteAnalyseAction(id: number) {
    axios
      .delete(
        rootUrl +
          "/hospital/" +
          currentSelectedHospitalId +
          "/analyses_actions/" +
          id,
        {
          headers: {
            Authorization: "Token " + token,
          },
        }
      )
      .then((response: { data: any }) => {
        console.log(data);
        FetchData();
      })
      .catch((error: any) => {
        alert("Une erreur s'est produite" + error);
      });
  }
  return (
    <div className="operations-wrapper">
      {deletingId && (
        <ModalSupression
          open={false}
          name={`l'analyse sélectionnée`}
          onClose={function (): void {
            setDeletingId(null);
          }}
          onConfirmClick={function (): void {
            deleteAnalyseAction(deletingId);
            setDeletingId(null);
          }}
        />
      )}
      <Header title="Analyses" />
      {
        <Header2
          inputPlaceholder={"Rechercher dans ce tableau"}
          onInputChange={function (text: string): void {
            setSearchedText(text);
          }}
          onAddButtonClick={
            !isAdmin
              ? function (): void {
                  navigate("/Analyses/Ajouter");
                }
              : null
          }
          all={isAll}
          onAllClick={() => {
            setIsAll(!isAll);
            setCurrentPage(1);
          }}
          handlePrint={handlePrint}
        />
      }
      {data === null ? (
        // center loading
        //center
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress thickness={6} sx={{ color: "#0B63F8" }} />
        </div>
      ) : (
        <div className="flex flex-col gap-y-1">
          {isAdmin && (
            <div className="flex flex-col  gap-y-2 items-start justify-center ml-10 mb-5">
              <FilterComponent
                filterProps={filterProps}
                setFilterProps={(props: FilterProps) => {
                  setFilterProps(props);
                  setCurrentPage(1);
                }}
              />
              {data.total !== undefined && (
                <div className="flex gap-x-2">
                  <span>Total :</span>
                  <span className="font-bold">
                    {(data.total ?? 0) + " MRU"}
                  </span>
                </div>
              )}
            </div>
          )}
          <Table
            style={{
              backgroundColor: "white",
              marginRight: "40px",
              marginLeft: "40px",
              width: "95.5%",
            }}
          >
            <MHeader
              titles={titles}
              withActions={false}
              onCheckClick={() => {
                if (selected.length === data?.data.length) {
                  setSelected([]);
                } else {
                  setSelected(data?.data.map((item) => item.id) || []);
                }
              }}
              isAllSelected={
                data &&
                data!.data.length !== 0 &&
                selected.length === data?.data.length
              }
            />
            <TableBody>
              {data!.data.map((analyse, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Checkbox
                      checked={selected.includes(analyse.id)}
                      onChange={() => {
                        if (selected.includes(analyse.id)) {
                          setSelected(
                            selected.filter((item) => item !== analyse.id)
                          );
                        } else {
                          setSelected([...selected, analyse.id]);
                        }
                      }}
                    />
                  </TableCell>
                  <MTableCell
                    label={analyse.items.map((i) => i.analyse_name).join(", ")}
                    isTitle={false}
                  />

                  <MTableCell
                    label={
                      new Date(analyse.created_at).toISOString().split("T")[0]
                    }
                    isTitle={false}
                  />
                  <MTableCell label={analyse.total + " MRU"} isTitle={false} />
                  <MTableCell label={analyse.patient} isTitle={false} />
                  <MTableCell
                    label={
                      analyse.insurance_number
                        ? analyse.insurance_number +
                          ` (${analyse.insurance_name} )`
                        : "-"
                    }
                    isTitle={false}
                  />
                  <MTableCell
                    label={analyse.id.toString().padStart(6, "0")}
                    isTitle={false}
                  />
                  {isAdmin && (
                    <MTableCell label={analyse.staff_name} isTitle={false} />
                  )}
                  {isAdmin && (
                    <MTableCell
                      label={
                        <button
                          onClick={() => {
                            setDeletingId(analyse.id);
                          }}
                          className="bg-red-400 border-2 text-sm font-medium text-white border-red-400 rounded py-2 px-3 hover:bg-transparent hover:text-red-500 active:scale-95 transition-all "
                        >
                          Supprimer
                        </button>
                      }
                      isTitle={false}
                    />
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      )}
      {data && (
        <Pagination
          currentPage={currentPage}
          totalPages={data!.total_pages}
          onPageChange={function (page: number): void {
            setCurrentPage(page);
          }}
        />
      )}
      <div ref={componentRef}>
        <PrintList
          centerName={currentHospital!.name}
          title={"Liste des analyses"}
          titles={titles
            .filter((t) => t.label !== "")
            .map((item) => item.label)}
          afterTable={
            <div className="flex justify-between w-full">
              <div className="flex gap-x-2">
                <span>Total :</span>
                <span className="font-bold">{(data?.total ?? 0) + " MRU"}</span>
              </div>
              {filterProps.selectedUserId && (
                <div>
                  <span>Caissier Responsable :</span>

                  <span className="font-bold">
                    {currentHospital!.staff_data.find(
                      (u) => u.id === filterProps.selectedUserId
                    )?.name ?? ""}
                  </span>
                </div>
              )}
            </div>
          }
          data={
            data?.data
              .filter(
                (analyse, index) =>
                  selected.length === 0 || selected.includes(analyse.id)
              )
              .map((analyse, index) => [
                ...[
                  analyse.items.map((i) => i.analyse_name).join(", "),
                  new Date(analyse.created_at).toISOString().split("T")[0],
                  analyse.total,

                  analyse.patient,
                  analyse.insurance_number
                    ? analyse.insurance_number + ` (${analyse.insurance_name} )`
                    : "-",
                  analyse.id.toString().padStart(6, "0"),
                ],
                ...(isAdmin ? [analyse.staff_name] : []),
              ]) ?? []
          }
        />
      </div>
    </div>
  );
};

export default Analyses;
