import React, { FC, useContext, useRef, useState } from "react";
import "./modalsupression.css";
import axios from "axios";
import { useReactToPrint } from "react-to-print";
import { ApiData } from "../table/Table";
import { Ticket, TicketData } from "../../interfaces";
import { CurrentUserContext } from "../../App";
import { InssuranceCompany, defInsurances, rootUrl } from "../../constants";
import { DefDropdown } from "../dropdown";
import { PrintableTicket, ticketPageStyle } from "../printable_ticket";

interface ModalProps {
  open: boolean;
  onClose: () => void;
  onConfirmClick: (
    selectedOption: string,
    selectedDate: string,
    selectedHoraire: string,
    name: string
  ) => void;
}

interface ClientInterface {
  id: number;
  name: string;
}

const TicketModal: FC<ModalProps> = ({ open, onClose, onConfirmClick }) => {
  const [printing, setPrinting] = useState<boolean>(false);
  const [patient, setPatient] = useState<string>("");
  const [insuranceNumber, setInsuranceNumber] = useState<string>("");
  const [durationValue, setDurationValue] = useState<number>(1);
  const [payload, setPayload] = useState<{ [key: string]: string }>({});
  const [newTicketId, setNewTicketId] = useState<number | null>(null);

  const componentRef = useRef<HTMLDivElement>(null);

  const currentUserData = useContext(CurrentUserContext).currentUserData;
  const ticketList: TicketData[] =
    currentUserData?.hospitals.find(
      (hospital) => hospital.id === currentUserData.selectedHospitalId
    )?.tickets || [];

  const [selectedInsurance, setSelectedInsurance] =
    useState<InssuranceCompany | null>(null);

  const [selectedTicket, setSelectedTicket] = useState<TicketData>(
    ticketList[0]
  );

  const [isAddingClient, setIsAddingClient] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [selectedClient, setSelectedClient] =
    useState<ClientInterface | null>();

  const token = useContext(CurrentUserContext).currentUserData!.token;
  const currentSelectedHospitalId =
    useContext(CurrentUserContext).currentUserData!.selectedHospitalId;

  const currentHospital = useContext(
    CurrentUserContext
  ).currentUserData!.hospitals.find(
    (hospital) => hospital.id === currentSelectedHospitalId
  )!;

  const handleConfirmClick = async () => {
    if (selectedTicket!.required_payload) {
      for (const [title, value] of Object.entries(
        selectedTicket!.required_payload
      )) {
        if (!payload[title]) {
          alert("Veuillez saisir " + title);
          return;
        }
      }
    }

    if (insuranceNumber.trim().length > 0 && selectedInsurance === null) {
      alert("Veuillez choisir le type d'assurance");
      return;
    }
    let clientId;
    if (selectedTicket.is_subscription) {
      if (isAddingClient) {
        if (patient.trim().length === 0) {
          alert("Veuillez saisir le nom du client");
          return;
        }
        setIsSubmitting(true);
        const clientData = await axios.post(
          rootUrl + "/hospital/" + currentSelectedHospitalId + "/clients",
          {
            name: patient,
            hospital: currentSelectedHospitalId,
          },
          {
            headers: {
              Authorization: "Token " + token,
            },
          }
        );
        clientId = clientData.data.id;
      } else {
        if (!selectedClient) {
          alert("Veuillez choisir le client");
          return;
        }
        clientId = selectedClient?.id;
      }
    } else {
      if (patient.trim().length === 0) {
        alert("Veuillez saisir le nom du client");
        return;
      }
    }
    const insurance_number =
      insuranceNumber.trim().length > 0 ? insuranceNumber : null;
    let price;

    if (insurance_number) {
      price =
        (selectedTicket.insurance_price ?? selectedTicket.price) *
        durationValue *
        (selectedInsurance?.multiplier ?? 0);
    } else {
      price = selectedTicket.price * durationValue;
    }
    const ticketData = {
      // option: selectedOption,
      // name: name,
      patient: !selectedTicket.is_subscription ? patient : undefined,
      client: selectedTicket.is_subscription ? clientId : undefined,

      insurance_number: insurance_number,

      ticket: selectedTicket!.id,
      duration: selectedTicket.duration_type ? durationValue : undefined,
      payed_price: price,
      payload: payload,
      insurance_name: selectedInsurance?.value ?? null,
    };

    axios
      .post(
        rootUrl + "/hospital/" + currentSelectedHospitalId + "/ticket_actions",
        ticketData,
        {
          headers: {
            Authorization: "Token " + token,
          },
        }
      )
      .then((response) => {
        setNewTicketId(response.data.id);
        setTimeout(() => {
          handlePrint();
          onClose();
        }, 10);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handlePrint = useReactToPrint({
    pageStyle: ticketPageStyle,
    onBeforeGetContent() {
      setPrinting(true);
    },

    content: () => {
      return componentRef.current;
    },
    onAfterPrint: () => {
      setPrinting(false);
    },
  });

  function parseDuration(duration: number, duration_type: string): string {
    let ret = "";

    switch (duration_type) {
      case "1": {
        ret = duration * 30 + " Min";
        break;
      }
      case "2": {
        ret = duration + " Heure(s)";
        break;
      }
      case "3": {
        ret = duration + " Jour(s)";
        break;
      }
      case "4": {
        ret = duration + " Mois";
        break;
      }
      case "5": {
        ret = duration + " An(s)";
        break;
      }
      default: {
        ret = duration + " " + duration_type;
      }
    }
    return ret;
  }
  const durationChoices = [];

  switch (selectedTicket?.duration_type) {
    case "1":
      for (let i = 1; i <= 12; i++) {
        durationChoices.push(30 * i + " min");
      }
      break;
    case "2":
      for (let i = 1; i <= 12; i++) {
        durationChoices.push(i + " H" + (i > 1 ? "s" : ""));
      }
      break;
    case "3":
      for (let i = 1; i <= 12; i++) {
        durationChoices.push(i + " Jr" + (i > 1 ? "s" : ""));
      }

      break;
    case "4":
      for (let i = 1; i <= 12; i++) {
        durationChoices.push(i + " Mois");
      }
      break;
    case "5":
      durationChoices.push(1 + " An");
      break;
    default:
      for (let i = 1; i <= 12; i++) {
        durationChoices.push(i + " " + (selectedTicket?.duration_type ?? ""));
      }
      break;
  }

  return (
    <div className="modal">
      <div className="modal-content">
        <div className="title-svg-wrapper">
          <h2 className="popup-title">Ticket</h2>
          <div className="close-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              onClick={onClose}
            >
              <path
                d="M19.5459 17.9541C19.7572 18.1654 19.876 18.452 19.876 18.7509C19.876 19.0498 19.7572 19.3365 19.5459 19.5478C19.3346 19.7592 19.0479 19.8779 18.749 19.8779C18.4501 19.8779 18.1635 19.7592 17.9521 19.5478L12 13.5937L6.0459 19.5459C5.83455 19.7573 5.54791 19.876 5.24902 19.876C4.95014 19.876 4.66349 19.7573 4.45215 19.5459C4.2408 19.3346 4.12207 19.0479 4.12207 18.7491C4.12207 18.4502 4.2408 18.1635 4.45215 17.9522L10.4062 12L4.45402 6.04593C4.24268 5.83459 4.12395 5.54795 4.12395 5.24906C4.12395 4.95017 4.24268 4.66353 4.45402 4.45218C4.66537 4.24084 4.95201 4.12211 5.2509 4.12211C5.54978 4.12211 5.83643 4.24084 6.04777 4.45218L12 10.4062L17.954 4.45125C18.1654 4.2399 18.452 4.12117 18.7509 4.12117C19.0498 4.12117 19.3364 4.2399 19.5478 4.45125C19.7591 4.66259 19.8778 4.94924 19.8778 5.24812C19.8778 5.54701 19.7591 5.83365 19.5478 6.045L13.5937 12L19.5459 17.9541Z"
                fill="black"
                fillOpacity="0.6"
              />
            </svg>
          </div>
        </div>
        <p className="paragraph">
          Veuillez confirmer les informations suivantes pour imprimer le reçu du
          client
        </p>
        <select
          className="form-select mt-5"
          value={selectedTicket?.name}
          onChange={(e) => {
            setSelectedTicket(
              ticketList.find((ticket) => ticket.name === e.target.value)!
            );
            setPayload({});
            setIsAddingClient(false);
            setDurationValue(1);
          }}
        >
          {[
            <option key="default" value="" disabled>
              Choisir la raison de visite
            </option>,
            ticketList.map((ticket) => (
              <option key={ticket.name} value={ticket.name}>
                {ticket.name}
              </option>
            )),
          ]}
        </select>
        {
          <div
            className={
              "flex flex-col gap-y-2 mt-6 " +
              (selectedTicket.is_subscription && !isAddingClient
                ? ""
                : "hidden")
            }
          >
            {DefDropdown<ClientInterface>({
              title: "Choisir le client",
              value: selectedClient?.name ?? "",
              onSelected: (value) => {
                setSelectedClient(value);
              },
              search: true,
              placeholder: "Choisir le client",
              url:
                "hospital/" +
                currentSelectedHospitalId +
                "/clients?is_active=active",
            })}
            {selectedTicket.is_hospital_subscription && (
              <p
                onClick={() => {
                  setIsAddingClient(true);
                }}
                className=" text-blue-300 cursor-pointer"
              >
                Ajouter nouveau
              </p>
            )}
          </div>
        }

        <input
          className={
            "name-input " +
            (selectedTicket.is_subscription && !isAddingClient ? "hidden" : "")
          }
          type="text"
          value={patient}
          onChange={(e) => setPatient(e.target.value)}
          placeholder={
            selectedTicket?.is_hospital_subscription
              ? "Nom du nouveau client"
              : "Le nom et prénom du client"
          }
        />

        {selectedTicket &&
          selectedTicket.required_payload &&
          Object.entries(selectedTicket.required_payload).map(
            ([title, value]) => (
              <input
                className="name-input"
                type="text"
                value={payload[title] ?? ""}
                onChange={(e) =>
                  setPayload({
                    ...payload,
                    [title]: e.target.value,
                  })
                }
                placeholder={title}
              />
            )
          )}
        {(currentHospital.is_hospital || currentHospital.has_pharmacy) && (
          <input
            className="name-input"
            type="text"
            value={insuranceNumber}
            onChange={(e) => setInsuranceNumber(e.target.value)}
            placeholder="numéro d'assurance"
          />
        )}
        {insuranceNumber.trim().length > 0 && (
          <select
            value={selectedInsurance?.value ?? ""}
            className="form-select mt-5"
            onChange={(e) => {
              if (e.target.value === "") {
                setSelectedInsurance(null);
                return;
              }
              setSelectedInsurance(
                defInsurances.find((i) => i.value === e.target.value)!
              );
            }}
          >
            <option disabled value={""}>
              Choisir le type d'assurance
            </option>
            {defInsurances.map((item) => {
              return (
                <option key={item.value} value={item.value}>
                  {item.name}
                </option>
              );
            })}
          </select>
        )}
        {selectedTicket?.duration_type && (
          <select
            className="form-select mt-5"
            value={durationValue}
            onChange={(e) => {
              setDurationValue(parseInt(e.target.value));
            }}
          >
            {durationChoices.map((title, index) => (
              <option key={index + 1} value={index + 1}>
                {title}
              </option>
            ))}
          </select>
        )}
        <div className="button-wrapper">
          <button className="cancel-button" onClick={onClose}>
            ANNULER
          </button>
          <button
            className="confirm-button"
            disabled={isSubmitting}
            onClick={handleConfirmClick}
          >
            CONFIRMER
          </button>
        </div>
      </div>
      <div ref={componentRef}>
        <PrintableTicket
          title="Reçu"
          centerName={currentHospital.ticket_name ?? currentHospital.name}
          id={newTicketId?.toString() ?? ""}
          data={{
            Pour:
              selectedTicket.is_subscription && !isAddingClient
                ? selectedClient?.name ?? ""
                : patient,
            ...{ ...payload },

            Nature: selectedTicket?.name,
            Prix:
              (!selectedTicket
                ? null
                : (insuranceNumber
                    ? (selectedTicket!.insurance_price ??
                        selectedTicket!.price) *
                      (selectedInsurance?.multiplier ?? 0)
                    : selectedTicket!.price) * durationValue) + " MRU",

            Date:
              new Date().toLocaleDateString() +
              " - " +
              new Date().toLocaleTimeString(),
          }}
          moreData={
            selectedTicket.duration_type
              ? [
                  parseDuration(
                    durationValue,
                    selectedTicket.duration_type ?? ""
                  ),
                ]
              : []
          }
        />
        {/*
       
        {selectedTicket?.duration_type && (
          <div className="flex items-center gap-4">
            <p className="text-lg font-bold text-black">
              {parseDuration(durationValue, selectedTicket.duration_type)}
            </p>
          </div>
        )}
        <div className="flex items-center gap-4">
          <p className="font-semibold text-gray-600">Prix:</p>
          <p className="text-lg font-bold text-black">
            {insuranceNumber
              ? `${(selectedTicket?.price * durationValue * 0.1).toFixed(
                  2
                )} MRU`
              : `${selectedTicket?.price * durationValue} MRU`}
          </p>
        </div>
        {selectedTicket?.required_payload &&
          Object.entries(selectedTicket?.required_payload).map(
            ([title, value]) => (
              <div className="flex items-center gap-4">
                <p className="font-semibold text-gray-600">{title}:</p>
                <p className="text-lg font-bold text-black">{payload[title]}</p>
              </div>
            )
          )}

        <div className="flex justify-between w-full">
          <div className="flex items-center gap-4">
            <p className="font-semibold text-gray-600">Date:</p>
            <p className="text-lg font-bold text-black">
              {new Date().toLocaleDateString() +
                " - " +
                new Date().toLocaleTimeString()}
            </p>
          </div>
          <div className="flex items-end">
            {currentHospital.name === "Etat Civil" && (
              <div className="w-full flex flex-col font-pop mx-4  mt-10 text-sm ">
                <h1 className="self-start">REGISSEUR PRINCIPAL</h1>
              </div>
            )}
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default TicketModal;
