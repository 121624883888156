import { ReactNode } from "react";

interface TicketProps {
  id: string;
  title: string;
  data: { [key: string]: any };
  moreData: ReactNode[];
  centerName: string;
}
export const ticketPageStyle = `@media print {
    @page {
      size: 200mm 80mm;
      margin: 0;
      padding: 0;
    }
    body {
    
    }
  }`;

export function PrintableTicket({
  id,
  title,
  data,
  moreData,
  centerName,
}: TicketProps) {
  if (centerName.toLocaleLowerCase() === "cms3") {
    centerName = "centre municipal de santé 3";
  } else if (centerName.toLocaleLowerCase() === "cms1") {
    centerName = "centre municipal de santé 1";
  }

  return (
    <div className="print-only font-inter rounded bg-white w-[200mm] px-2">
      <div className="flex items-center w-ful border-b-2 border-gray-600 text-center text-black  py-3 ">
        <div className="grow" />
        <h1 className="text-3xl font-bold grow justify-start">{title}</h1>
        <span className="font-medium h-min text-2xl grow justify-start items-start">
          N° {id.padStart(4, "0")}
        </span>
      </div>

      <div className="flex flex-col items-start  h-full grow border-b-2 border-gray-600 uppercase text-lg py-2">
        {Object.entries(data).map(([key, value]) => {
          return (
            <div className="flex items-center gap-4">
              <p className="font-semibold text-gray-600">
                {key}
                {key.length > 0 ? ":" : ""}
              </p>
              <p className="text-lg font-bold text-black">{value}</p>
            </div>
          );
        })}
        {moreData.map((v) => {
          return <p className="text-lg font-bold text-black">{v}</p>;
        })}
      </div>
      <div className="flex items-center justify-center gap-4 py-2 font-bold text-2xl uppercase">
        {centerName}
      </div>
    </div>
  );
}
