import React, { useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "./sidebar.css";
import { CurrentUserContext } from "../../App";
import { HospitalInterface } from "../../interfaces";
import { GrInsecure, GrMoney, GrSecure, GrTransaction } from "react-icons/gr";
import { FaDatabase } from "react-icons/fa";
import { FaUserShield } from "react-icons/fa";
import { PiUsersThreeBold } from "react-icons/pi";

interface MDropDownProps {
  title: string | undefined;
  isAdmin: boolean;
  hospitals: HospitalInterface[];
  isOpen: boolean;
  onSelected: (hospitalId: number | null) => void;
  onClick: () => void;
}

export const MDropDown = ({
  title,
  isAdmin,
  hospitals,
  isOpen,
  onSelected,
  onClick,
}: MDropDownProps) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        cursor: "pointer",
      }}
      onClick={() => {
        onClick();
      }}
    >
      <div className="dropdown">
        <img
          src={
            !title
              ? "/images/admin-icon.png"
              : "/images/streamline-emojis_hospital.svg"
          }
          width={32}
          height={32}
          alt=""
        />
        {title ?? "Admin"}
        <div style={{ flexGrow: "1" }}></div>
        {(isAdmin || hospitals.length > 1) && (
          <p
            style={{
              rotate: "-90deg",
              marginRight: "10px",
              fontFamily: "Poppins",
            }}
          >
            &#60;
          </p>
        )}
      </div>

      <div className="dropdown-item-wrapper">
        {[
          isAdmin && isOpen && (
            <div
              className="dropdown-item"
              onClick={() => {
                onSelected(null);
              }}
            >
              <img
                src="\images\admin-icon.png"
                width={32}
                height={32}
                alt=""
                style={{ cursor: "pointer" }}
              />
              <img src="\images\streamline-emoji-add.svg" alt="" />
              Admin
            </div>
          ),
          // add a divider
          isAdmin && isOpen && (
            <div
              style={{
                border: "1px solid #000",
                opacity: ".2",
                width: "100px",
              }}
            ></div>
          ),
          isOpen &&
            hospitals.map((h) => {
              return (
                <div
                  className="dropdown-item"
                  onClick={() => {
                    onSelected(h.id);
                  }}
                >
                  <img src="\images\streamline-emojis_hospital.svg" alt="" />
                  {h.name}
                </div>
              );
            }),
        ]}
      </div>
    </div>
  );
};

export const Sidebar = () => {
  let userContext = useContext(CurrentUserContext);
  let currentHospital = userContext.currentUserData!.hospitals.find((h) => {
    return h.id === userContext.currentUserData!.selectedHospitalId;
  });

  const navigate = useNavigate();

  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <div className="sidebar-wrapper">
      <img className="w-28 ml-10 " src="images/gvim_logo.png" />
      {/* <div className="logo">MédiGestion.</div> */}

      <MDropDown
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        onSelected={(id) => {
          if (!id) {
            navigate("/admin/hopitaux");
          } else {
            let currentSelectedId =
              userContext.currentUserData!.selectedHospitalId;
            if (!currentSelectedId) {
              navigate("/");
            }
          }
          if (!id) {
            localStorage.removeItem("selectedHospitalId");
          } else {
            localStorage.setItem("selectedHospitalId", id?.toString());
          }
          userContext.setCurrentUser((prev) => {
            if (prev) {
              return { ...prev, selectedHospitalId: id };
            }
            return prev;
          });

          setIsOpen(false);
        }}
        isOpen={isOpen}
        title={
          userContext.currentUserData!.hospitals.find((h) => {
            return h.id === userContext.currentUserData!.selectedHospitalId;
          })?.name
        }
        isAdmin={userContext.currentUserData!.isAdmin}
        hospitals={userContext.currentUserData!.hospitals}
      />
      {
        <div className="menu">
          {userContext.currentUserData!.selectedHospitalId
            ? [
                <NavLink className="menu-item" to="/">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M22 8.52V3.98C22 2.57 21.36 2 19.77 2H15.73C14.14 2 13.5 2.57 13.5 3.98V8.51C13.5 9.93 14.14 10.49 15.73 10.49H19.77C21.36 10.5 22 9.93 22 8.52Z"
                      fill="#187498"
                    />
                    <path
                      d="M22 19.77V15.73C22 14.14 21.36 13.5 19.77 13.5H15.73C14.14 13.5 13.5 14.14 13.5 15.73V19.77C13.5 21.36 14.14 22 15.73 22H19.77C21.36 22 22 21.36 22 19.77Z"
                      fill="#187498"
                    />
                    <path
                      d="M10.5 8.52V3.98C10.5 2.57 9.86 2 8.27 2H4.23C2.64 2 2 2.57 2 3.98V8.51C2 9.93 2.64 10.49 4.23 10.49H8.27C9.86 10.5 10.5 9.93 10.5 8.52Z"
                      fill="#187498"
                    />
                    <path
                      d="M10.5 19.77V15.73C10.5 14.14 9.86 13.5 8.27 13.5H4.23C2.64 13.5 2 14.14 2 15.73V19.77C2 21.36 2.64 22 4.23 22H8.27C9.86 22 10.5 21.36 10.5 19.77Z"
                      fill="#187498"
                    />
                  </svg>
                  Tableau de bord
                </NavLink>,
                currentHospital?.municipal_tax_data && (
                  <NavLink className="menu-item" to="/regisseurs">
                    <PiUsersThreeBold style={{ fontSize: "22px" }} />
                    Regisseurs
                  </NavLink>
                ),
                currentHospital?.municipal_tax_data && (
                  <NavLink className="menu-item" to="/alimentations">
                    <GrMoney style={{ fontSize: "22px" }} />
                    Alimentations
                  </NavLink>
                ),

                currentHospital?.municipal_tax_data && (
                  <NavLink className="menu-item" to="/remboursements">
                    <GrTransaction style={{ fontSize: "22px" }} />
                    Versements
                  </NavLink>
                ),

                currentHospital?.is_hospital && (
                  <NavLink className="menu-item" to="/Opérations">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M16.75 3.56V2C16.75 1.59 16.41 1.25 16 1.25C15.59 1.25 15.25 1.59 15.25 2V3.5H8.74999V2C8.74999 1.59 8.40999 1.25 7.99999 1.25C7.58999 1.25 7.24999 1.59 7.24999 2V3.56C4.54999 3.81 3.23999 5.42 3.03999 7.81C3.01999 8.1 3.25999 8.34 3.53999 8.34H20.46C20.75 8.34 20.99 8.09 20.96 7.81C20.76 5.42 19.45 3.81 16.75 3.56Z"
                        stroke="#187498"
                      />
                      <path
                        d="M19 15C16.79 15 15 16.79 15 19C15 19.75 15.21 20.46 15.58 21.06C16.27 22.22 17.54 23 19 23C20.46 23 21.73 22.22 22.42 21.06C22.79 20.46 23 19.75 23 19C23 16.79 21.21 15 19 15ZM21.07 18.57L18.94 20.54C18.8 20.67 18.61 20.74 18.43 20.74C18.24 20.74 18.05 20.67 17.9 20.52L16.91 19.53C16.62 19.24 16.62 18.76 16.91 18.47C17.2 18.18 17.68 18.18 17.97 18.47L18.45 18.95L20.05 17.47C20.35 17.19 20.83 17.21 21.11 17.51C21.39 17.81 21.37 18.28 21.07 18.57Z"
                        stroke="#187498"
                      />
                      <path
                        d="M20 9.84H4C3.45 9.84 3 10.29 3 10.84V17C3 20 4.5 22 8 22H12.93C13.62 22 14.1 21.33 13.88 20.68C13.68 20.1 13.51 19.46 13.51 19C13.51 15.97 15.98 13.5 19.01 13.5C19.3 13.5 19.59 13.52 19.87 13.57C20.47 13.66 21.01 13.19 21.01 12.59V10.85C21 10.29 20.55 9.84 20 9.84ZM9.21 18.21C9.02 18.39 8.76 18.5 8.5 18.5C8.24 18.5 7.98 18.39 7.79 18.21C7.61 18.02 7.5 17.76 7.5 17.5C7.5 17.24 7.61 16.98 7.79 16.79C7.89 16.7 7.99 16.63 8.12 16.58C8.49 16.42 8.93 16.51 9.21 16.79C9.39 16.98 9.5 17.24 9.5 17.5C9.5 17.76 9.39 18.02 9.21 18.21ZM9.21 14.71C9.16 14.75 9.11 14.79 9.06 14.83C9 14.87 8.94 14.9 8.88 14.92C8.82 14.95 8.76 14.97 8.7 14.98C8.63 14.99 8.56 15 8.5 15C8.24 15 7.98 14.89 7.79 14.71C7.61 14.52 7.5 14.26 7.5 14C7.5 13.74 7.61 13.48 7.79 13.29C8.02 13.06 8.37 12.95 8.7 13.02C8.76 13.03 8.82 13.05 8.88 13.08C8.94 13.1 9 13.13 9.06 13.17C9.11 13.21 9.16 13.25 9.21 13.29C9.39 13.48 9.5 13.74 9.5 14C9.5 14.26 9.39 14.52 9.21 14.71ZM12.71 14.71C12.52 14.89 12.26 15 12 15C11.74 15 11.48 14.89 11.29 14.71C11.11 14.52 11 14.26 11 14C11 13.74 11.11 13.48 11.29 13.29C11.67 12.92 12.34 12.92 12.71 13.29C12.89 13.48 13 13.74 13 14C13 14.26 12.89 14.52 12.71 14.71Z"
                        stroke="#187498"
                      />
                    </svg>
                    Opérations
                  </NavLink>
                ),
                currentHospital?.municipal_tax_data === null && (
                  <NavLink className="menu-item" to="/tickets">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M16.75 3.56V2C16.75 1.59 16.41 1.25 16 1.25C15.59 1.25 15.25 1.59 15.25 2V3.5H8.74999V2C8.74999 1.59 8.40999 1.25 7.99999 1.25C7.58999 1.25 7.24999 1.59 7.24999 2V3.56C4.54999 3.81 3.23999 5.42 3.03999 7.81C3.01999 8.1 3.25999 8.34 3.53999 8.34H20.46C20.75 8.34 20.99 8.09 20.96 7.81C20.76 5.42 19.45 3.81 16.75 3.56Z"
                        stroke="#187498"
                      />
                      <path
                        d="M19 15C16.79 15 15 16.79 15 19C15 19.75 15.21 20.46 15.58 21.06C16.27 22.22 17.54 23 19 23C20.46 23 21.73 22.22 22.42 21.06C22.79 20.46 23 19.75 23 19C23 16.79 21.21 15 19 15ZM21.07 18.57L18.94 20.54C18.8 20.67 18.61 20.74 18.43 20.74C18.24 20.74 18.05 20.67 17.9 20.52L16.91 19.53C16.62 19.24 16.62 18.76 16.91 18.47C17.2 18.18 17.68 18.18 17.97 18.47L18.45 18.95L20.05 17.47C20.35 17.19 20.83 17.21 21.11 17.51C21.39 17.81 21.37 18.28 21.07 18.57Z"
                        stroke="#187498"
                      />
                      <path
                        d="M20 9.84H4C3.45 9.84 3 10.29 3 10.84V17C3 20 4.5 22 8 22H12.93C13.62 22 14.1 21.33 13.88 20.68C13.68 20.1 13.51 19.46 13.51 19C13.51 15.97 15.98 13.5 19.01 13.5C19.3 13.5 19.59 13.52 19.87 13.57C20.47 13.66 21.01 13.19 21.01 12.59V10.85C21 10.29 20.55 9.84 20 9.84ZM9.21 18.21C9.02 18.39 8.76 18.5 8.5 18.5C8.24 18.5 7.98 18.39 7.79 18.21C7.61 18.02 7.5 17.76 7.5 17.5C7.5 17.24 7.61 16.98 7.79 16.79C7.89 16.7 7.99 16.63 8.12 16.58C8.49 16.42 8.93 16.51 9.21 16.79C9.39 16.98 9.5 17.24 9.5 17.5C9.5 17.76 9.39 18.02 9.21 18.21ZM9.21 14.71C9.16 14.75 9.11 14.79 9.06 14.83C9 14.87 8.94 14.9 8.88 14.92C8.82 14.95 8.76 14.97 8.7 14.98C8.63 14.99 8.56 15 8.5 15C8.24 15 7.98 14.89 7.79 14.71C7.61 14.52 7.5 14.26 7.5 14C7.5 13.74 7.61 13.48 7.79 13.29C8.02 13.06 8.37 12.95 8.7 13.02C8.76 13.03 8.82 13.05 8.88 13.08C8.94 13.1 9 13.13 9.06 13.17C9.11 13.21 9.16 13.25 9.21 13.29C9.39 13.48 9.5 13.74 9.5 14C9.5 14.26 9.39 14.52 9.21 14.71ZM12.71 14.71C12.52 14.89 12.26 15 12 15C11.74 15 11.48 14.89 11.29 14.71C11.11 14.52 11 14.26 11 14C11 13.74 11.11 13.48 11.29 13.29C11.67 12.92 12.34 12.92 12.71 13.29C12.89 13.48 13 13.74 13 14C13 14.26 12.89 14.52 12.71 14.71Z"
                        stroke="#187498"
                      />
                    </svg>
                    Tickets
                  </NavLink>
                ),
                currentHospital?.has_pharmacy && (
                  <NavLink className="menu-item" to="/Ventes">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M14.85 3.95005V7.75005H13.35V3.95005C13.35 3.68005 13.11 3.55005 12.95 3.55005C12.9 3.55005 12.85 3.56005 12.8 3.58005L4.87 6.57005C4.34 6.77005 4 7.27005 4 7.84005V8.51005C3.09 9.19005 2.5 10.28 2.5 11.51V7.84005C2.5 6.65005 3.23 5.59005 4.34 5.17005L12.28 2.17005C12.5 2.09005 12.73 2.05005 12.95 2.05005C13.95 2.05005 14.85 2.86005 14.85 3.95005Z"
                        stroke="#187498"
                      />
                      <path
                        d="M21.5 14.5V15.5C21.5 15.77 21.29 15.99 21.01 16H19.55C19.02 16 18.54 15.61 18.5 15.09C18.47 14.78 18.59 14.49 18.79 14.29C18.97 14.1 19.22 14 19.49 14H21C21.29 14.01 21.5 14.23 21.5 14.5Z"
                        fill="#187498"
                      />
                      <path
                        d="M19.48 12.95H20.5C21.05 12.95 21.5 12.5 21.5 11.95V11.51C21.5 9.44 19.81 7.75 17.74 7.75H6.26C5.41 7.75 4.63 8.03 4 8.51C3.09 9.19 2.5 10.28 2.5 11.51V18.24C2.5 20.31 4.19 22 6.26 22H17.74C19.81 22 21.5 20.31 21.5 18.24V18.05C21.5 17.5 21.05 17.05 20.5 17.05H19.63C18.67 17.05 17.75 16.46 17.5 15.53C17.29 14.77 17.54 14.04 18.04 13.55C18.41 13.17 18.92 12.95 19.48 12.95ZM14 12.75H7C6.59 12.75 6.25 12.41 6.25 12C6.25 11.59 6.59 11.25 7 11.25H14C14.41 11.25 14.75 11.59 14.75 12C14.75 12.41 14.41 12.75 14 12.75Z"
                        stroke="#187498"
                      />
                    </svg>
                    Pharmacy
                  </NavLink>
                ),
                currentHospital?.has_subscription && (
                  <NavLink className="menu-item" to="/subscriptions">
                    <FaUserShield
                      style={{ fontSize: "22px", color: "#187498" }}
                    />
                    Inscriptions
                  </NavLink>
                ),
                currentHospital?.specialization === "pirogue" && (
                  <NavLink className="menu-item" to="/pirogues">
                    <FaUserShield
                      style={{ fontSize: "22px", color: "#187498" }}
                    />
                    Pirogues
                  </NavLink>
                ),
                currentHospital?.is_hospital && (
                  <NavLink className="menu-item" to="/Analyses">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <g opacity="1">
                        <path
                          d="M19.96 8.96001C19.29 8.22001 18.28 7.79001 16.88 7.64001V6.88001C16.88 5.51001 16.3 4.19001 15.28 3.27001C14.25 2.33001 12.91 1.89001 11.52 2.02001C9.12999 2.25001 7.11999 4.56001 7.11999 7.06001V7.64001C5.71999 7.79001 4.70999 8.22001 4.03999 8.96001C3.06999 10.04 3.09999 11.48 3.20999 12.48L3.90999 18.05C4.11999 20 4.90999 22 9.20999 22H14.79C19.09 22 19.88 20 20.09 18.06L20.79 12.47C20.9 11.48 20.93 10.04 19.96 8.96001ZM11.66 3.41001C12.66 3.32001 13.61 3.63001 14.35 4.30001C15.08 4.96001 15.49 5.90001 15.49 6.88001V7.58001H8.50999V7.06001C8.50999 5.28001 9.97999 3.57001 11.66 3.41001ZM12 18.58C9.90999 18.58 8.20999 16.88 8.20999 14.79C8.20999 12.7 9.90999 11 12 11C14.09 11 15.79 12.7 15.79 14.79C15.79 16.88 14.09 18.58 12 18.58Z"
                          stroke="#187498"
                        />
                        <path
                          d="M11.4299 16.64C11.2399 16.64 11.0499 16.57 10.8999 16.42L9.90988 15.43C9.61988 15.14 9.61988 14.66 9.90988 14.37C10.1999 14.08 10.6799 14.08 10.9699 14.37L11.4499 14.85L13.0499 13.37C13.3499 13.09 13.8299 13.11 14.1099 13.41C14.3899 13.71 14.3699 14.19 14.0699 14.47L11.9399 16.44C11.7899 16.57 11.6099 16.64 11.4299 16.64Z"
                          stroke="#187498"
                        />
                      </g>
                    </svg>
                    Analyses
                  </NavLink>
                ),
                currentHospital?.municipal_tax_data === null && (
                  <NavLink className="menu-item" to="/donnees">
                    <FaDatabase
                      style={{ fontSize: "18px", color: "#187498" }}
                    />
                    Données
                  </NavLink>
                ),
                (currentHospital?.is_hospital ||
                  currentHospital?.has_pharmacy) && (
                  <NavLink className="menu-item" to="/CNAM">
                    <FaUserShield
                      style={{ fontSize: "22px", color: "#187498" }}
                    />
                    Assurances
                  </NavLink>
                ),
              ]
            : [
                <NavLink className="menu-item" to="/admin/">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M22 8.52V3.98C22 2.57 21.36 2 19.77 2H15.73C14.14 2 13.5 2.57 13.5 3.98V8.51C13.5 9.93 14.14 10.49 15.73 10.49H19.77C21.36 10.5 22 9.93 22 8.52Z"
                      fill="#187498"
                    />
                    <path
                      d="M22 19.77V15.73C22 14.14 21.36 13.5 19.77 13.5H15.73C14.14 13.5 13.5 14.14 13.5 15.73V19.77C13.5 21.36 14.14 22 15.73 22H19.77C21.36 22 22 21.36 22 19.77Z"
                      fill="#187498"
                    />
                    <path
                      d="M10.5 8.52V3.98C10.5 2.57 9.86 2 8.27 2H4.23C2.64 2 2 2.57 2 3.98V8.51C2 9.93 2.64 10.49 4.23 10.49H8.27C9.86 10.5 10.5 9.93 10.5 8.52Z"
                      fill="#187498"
                    />
                    <path
                      d="M10.5 19.77V15.73C10.5 14.14 9.86 13.5 8.27 13.5H4.23C2.64 13.5 2 14.14 2 15.73V19.77C2 21.36 2.64 22 4.23 22H8.27C9.86 22 10.5 21.36 10.5 19.77Z"
                      fill="#187498"
                    />
                  </svg>
                  Tableau de bord
                </NavLink>,
                <NavLink className="menu-item" to="/admin/hopitaux">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M22 22H2"
                      stroke="#187498"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                    <path
                      d="M17 22V6C17 4.114 17 3.172 16.414 2.586C15.828 2 14.886 2 13 2H11C9.114 2 8.172 2 7.586 2.586C7 3.172 7 4.114 7 6V22"
                      stroke="#187498"
                      stroke-width="1.5"
                    />
                    <path
                      d="M12 22V19M10 12H14M5.5 11H7M5.5 14H7M17 11H18.5M17 14H18.5M5.5 8H7M17 8H18.5"
                      stroke="#187498"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                    <path
                      d="M12 9V5M14 7H10"
                      stroke="#187498"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M20.25 8.5C20.25 8.69891 20.329 8.88968 20.4697 9.03033C20.6103 9.17098 20.8011 9.25 21 9.25C21.1989 9.25 21.3897 9.17098 21.5303 9.03033C21.671 8.88968 21.75 8.69891 21.75 8.5H20.25ZM21.75 12.5C21.75 12.3011 21.671 12.1103 21.5303 11.9697C21.3897 11.829 21.1989 11.75 21 11.75C20.8011 11.75 20.6103 11.829 20.4697 11.9697C20.329 12.1103 20.25 12.3011 20.25 12.5H21.75ZM17.5 5.75C18.218 5.75 18.7 5.75 19.067 5.788C19.422 5.824 19.586 5.888 19.695 5.961L20.528 4.713C20.132 4.449 19.693 4.344 19.219 4.296C18.758 4.249 18.187 4.25 17.5 4.25V5.75ZM21.75 8.5C21.75 7.813 21.75 7.242 21.704 6.781C21.656 6.308 21.551 5.868 21.286 5.472L20.039 6.306C20.112 6.414 20.176 6.578 20.212 6.933C20.249 7.3 20.25 7.783 20.25 8.5H21.75ZM19.695 5.961C19.831 6.053 19.948 6.17 20.039 6.306L21.286 5.472C21.086 5.172 20.828 4.914 20.528 4.713L19.695 5.961ZM20.25 12.5V22H21.75V12.5H20.25ZM3.75 17C3.75 16.8011 3.67098 16.6103 3.53033 16.4697C3.38968 16.329 3.19891 16.25 3 16.25C2.80109 16.25 2.61032 16.329 2.46967 16.4697C2.32902 16.6103 2.25 16.8011 2.25 17H3.75ZM2.25 13C2.25 13.1989 2.32902 13.3897 2.46967 13.5303C2.61032 13.671 2.80109 13.75 3 13.75C3.19891 13.75 3.38968 13.671 3.53033 13.5303C3.67098 13.3897 3.75 13.1989 3.75 13H2.25ZM6.5 4.25C5.813 4.25 5.242 4.25 4.781 4.296C4.308 4.344 3.868 4.449 3.472 4.713L4.306 5.961C4.414 5.888 4.578 5.824 4.933 5.788C5.3 5.751 5.783 5.75 6.5 5.75V4.25ZM3.75 8.5C3.75 7.782 3.75 7.3 3.788 6.933C3.824 6.578 3.888 6.414 3.961 6.306L2.713 5.472C2.449 5.868 2.344 6.308 2.296 6.781C2.249 7.242 2.25 7.813 2.25 8.5H3.75ZM3.472 4.713C3.17167 4.91419 2.91379 5.17141 2.713 5.472L3.961 6.306C4.05222 6.16946 4.16946 6.05222 4.306 5.961L3.472 4.713ZM2.25 17V22H3.75V17H2.25ZM2.25 8.5V13H3.75V8.5H2.25Z"
                      fill="#187498"
                    />
                    <path
                      d="M10 15H10.5M14 15H12.5"
                      stroke="#187498"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                  </svg>
                  Centres
                </NavLink>,
                <NavLink className="menu-item" to="/admin/versement">
                  <GrTransaction style={{ fontSize: "20px" }} />
                  Versements
                </NavLink>,
                <NavLink className="menu-item" to="/admin/weeks_not_payed">
                  <div className=" rotate-180">
                    <GrTransaction style={{ fontSize: "20px", color: "red" }} />
                  </div>
                  Ajouter versement
                </NavLink>,
                <NavLink className="menu-item" to="/admin/versement_assurance">
                  <div className=" rotate-180">
                    <GrSecure style={{ fontSize: "20px" }} />
                  </div>
                  Versement assurance
                </NavLink>,
                <NavLink className="menu-item" to="/admin/renenue_status">
                  <div className=" rotate-180">
                    <GrMoney style={{ fontSize: "20px" }} />
                  </div>
                  État récapitulatif
                </NavLink>,
                <NavLink className="menu-item" to="/admin/utilisateurs">
                  <PiUsersThreeBold style={{ fontSize: "22px" }} />
                  Utilisateurs
                </NavLink>,
              ]}
        </div>
      }
    </div>
  );
};
