import React, { useContext } from "react";
import { useState, useEffect, useRef } from "react";
import { FaFileImport } from "react-icons/fa6";
import {
  MHeader,
  MTableCell,
  MActionsCell,
  ApiData,
  ActionType,
} from "../Components/table/Table";
import Header from "../Components/Header/Header";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import QuantityInput from "../Components/QuantityInput/QuantityInput";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { Header2, HeaderDelete } from "../Components/Header/Header2";
import ModalSupression from "../Components/Modal/ModalSupression";
import { AnalyseInterface } from "../interfaces";
import Pagination from "../Components/pagination/Pagination";
import { rootUrl } from "../constants";
import { CurrentUserContext } from "../App";
import { useReactToPrint } from "react-to-print";
import Fileuploadmodal from "../Components/Modal/fileuploadmodal";
import PrintList from "../Components/printable_list";

export const titles = [
  { label: "Analyse", sortable: true },
  { label: "Prix", sortable: true },
];

const AnalysesDataPage = () => {
  const [printing, setPrinting] = useState<boolean>(false);
  const componentRef = useRef<HTMLDivElement>(null);
  const [selected, setSelected] = useState<number[]>([]);
  let [currentPage, setCurrentPage] = useState<number>(1);
  const [data, setData] = useState<ApiData<AnalyseInterface> | null>(null);
  const [searchedText, setSearchedText] = useState<string>("");
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const [openedDeleteModal, setOpenedDeleteModal] = useState(false);

  const [isAll, setIsAll] = useState<boolean>(false);

  const navigate = useNavigate();
  const [error, setError] = useState(null);

  const isAdmin = useContext(CurrentUserContext).currentUserData!.isAdmin;

  let currentSelectedHospitalId =
    useContext(CurrentUserContext).currentUserData!.selectedHospitalId;

  const currentHospital = useContext(
    CurrentUserContext
  ).currentUserData!.hospitals.find((h) => h.id === currentSelectedHospitalId);

  const [deletingId, setDeletingId] = useState<number[]>([]);

  let token = useContext(CurrentUserContext).currentUserData!.token;

  function FetchData() {
    // setData(null);
    axios
      .get(rootUrl + "/hospital/" + currentSelectedHospitalId + "/analyses", {
        params: {
          page: currentPage,
          search: searchedText,
          all: isAll ? "true" : undefined,
        },
        headers: {
          Authorization: "Token " + token,
        },
      })
      .then((response: { data: any }) => {
        console.log(data);
        setData(response.data);
      })
      .catch((error: any) => {
        setError(error);
      });
  }
  function deleteAnalyse(id: number) {
    axios
      .delete(
        `${rootUrl}/hospital/${currentSelectedHospitalId}/analyses/${id}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((response: { data: any }) => {
        console.log(response);
        FetchData();
      })
      .catch((error: any) => {
        // alert(`Une erreur est su ${error}`);
      });
  }

  useEffect(() => {
    FetchData();
  }, [currentPage, currentSelectedHospitalId, isAll]);

  useEffect(() => {
    clearTimeout(typingTimeout);

    // Set a new timeout to delay the search
    const timeoutId = setTimeout(() => {
      setSelected([]);
      currentPage = 1; //should be updated but not render
      FetchData();
    }, 500);

    setTypingTimeout(timeoutId);

    return () => {
      clearTimeout(typingTimeout);
    };
  }, [searchedText]);

  const handlePrint = useReactToPrint({
    onBeforeGetContent() {
      setPrinting(true);
    },

    content: () => {
      return componentRef.current;
    },
    onAfterPrint: () => {
      setPrinting(false);
    },
  });

  return (
    <div className="medicament-wrapper">
      {deletingId.length > 0 && (
        <ModalSupression
          open={false}
          name={
            deletingId.length === 1
              ? data!.data.find((item) => item.id === deletingId[0])!.name
              : null
          }
          onClose={function (): void {
            setDeletingId([]);
            setOpenedDeleteModal(false);
          }}
          onConfirmClick={function (): void {
            deletingId.forEach((id) => deleteAnalyse(id));
            setDeletingId([]);
            setOpenedDeleteModal(false);
          }}
        />
      )}

      <Header title="Analyses" />
      {selected.length > 0 ? (
        <HeaderDelete
          legend={"Analyses"}
          onDeleteClick={() => {
            setDeletingId([...selected]);
          }}
          selectedLength={selected.length}
          handlePrint={handlePrint}
        />
      ) : (
        <Header2
          inputPlaceholder={"Rechercher dans ce tableau"}
          all={isAll}
          onAllClick={() => {
            setIsAll(!isAll);
            setCurrentPage(1);
          }}
          onInputChange={function (text: string): void {
            setSearchedText(text);
          }}
          onAddButtonClick={function (): void {
            navigate("/donnees/analyses/Ajouter");
          }}
          handlePrint={handlePrint}
        />
      )}
      {data === null ? (
        // center loading
        //center
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress thickness={6} sx={{ color: "#0B63F8" }} />
        </div>
      ) : (
        <div>
          <Table
            style={{
              backgroundColor: "white",
              marginRight: "40px",
              marginLeft: "40px",
              width: "95.5%",
            }}
          >
            <MHeader
              titles={titles}
              onCheckClick={() => {
                if (selected.length === data?.data.length) {
                  setSelected([]);
                } else {
                  setSelected(data?.data.map((item) => item.id) || []);
                }
              }}
              isAllSelected={
                data &&
                data!.data.length !== 0 &&
                selected.length === data?.data.length
              }
            />
            <TableBody>
              {data!.data.map((analyse, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Checkbox
                      checked={selected.includes(analyse.id)}
                      onChange={() => {
                        if (selected.includes(analyse.id)) {
                          setSelected(
                            selected.filter((item) => item !== analyse.id)
                          );
                        } else {
                          setSelected([...selected, analyse.id]);
                        }
                      }}
                    />
                  </TableCell>
                  <MTableCell label={analyse.name} isTitle={false} />
                  <MTableCell label={`${analyse.price} MRU`} isTitle={false} />

                  {isAdmin ? (
                    <MActionsCell
                      onClick={(a) => {
                        if (a === ActionType.DELETE) {
                          setDeletingId([analyse.id]);
                        } else if (a === ActionType.VIEW) {
                        } else if (a === ActionType.EDIT) {
                          navigate(
                            "/donnees/analyses/" + analyse.id + "/Modifier/"
                          );
                        }
                      }}
                    />
                  ) : (
                    <TableCell />
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      )}
      {data && (
        <Pagination
          currentPage={currentPage}
          totalPages={data!.total_pages}
          onPageChange={function (page: number): void {
            setCurrentPage(page);
          }}
        />
      )}
      <div ref={componentRef}>
        <PrintList
          centerName={currentHospital!.name}
          title={"LISTE DES ANALYSES"}
          titles={titles.map((item) => item.label)}
          data={
            data?.data
              .filter(
                (analyse, index) =>
                  selected.length === 0 || selected.includes(analyse.id)
              )
              .map((analyse, index) => [
                analyse.name,
                analyse.price + " MRU",
              ]) ?? []
          }
        />
      </div>
    </div>
  );
};

export default AnalysesDataPage;
