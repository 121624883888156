import React, { useContext, useEffect, useState } from "react";
import LineChart from "./Line";
import "./mainchart.css";
import PieChart from "./Pie";
import { CurrentUserContext } from "../../App";

interface DataPoint {
  id: number;
  month: number;
  sales: number;
}

interface DataPie {
  sales: number;
  type: string;
}

function MainChart({
  monthSales,
  medicaments,
  operations,
  analyses,
  tickets,
  isHospital,
  isPharmacy,
}: {
  monthSales: {
    [key: string]: number;
  };
  medicaments: number;
  operations: number;
  analyses: number;
  tickets: number;
  isHospital: boolean;
  isPharmacy: boolean;
}) {
  const data: DataPoint[] = Object.keys(monthSales).map((key, index) => {
    return {
      id: index,
      month: parseInt(key),
      sales: monthSales[key],
    };
  });

  const userData = {
    labels: [
      "Jan",
      "Fev",
      "Mar",
      "Avr",
      "Mai",
      "Juin",
      "Juil",
      "Aout",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Revenue Par Mois",
        data: data.map((data: any) => data.sales),
        backgroundColor: ["#187498"],
        borderColor: ["#187498"],
      },
    ],
  };

  const saleType: DataPie[] = [
    ...[{ type: "Tickets", sales: tickets }],
    ...(isPharmacy
      ? [{ type: "Vente de Médicaments", sales: medicaments }]
      : []),
    ...(isHospital
      ? [
          { type: "Opérations", sales: operations },
          { type: "Analyses", sales: analyses },
        ]
      : []),
  ];

  const userPieData = {
    labels: saleType.map((data: any) => data.type),
    datasets: [
      {
        label: "Ajourd'hui : ",
        data: saleType.map((data: any) => data.sales),
        backgroundColor: ["#187498", "#EB5353", "#9EDDFF", "#FFC700"],
      },
    ],
  };

  return (
    <div className="container">
      <div className="chart-wrapper">
        <PieChart chartData={userPieData} />
        <LineChart chartData={userData} />
      </div>
    </div>
  );
}

export default MainChart;
