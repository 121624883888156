import { useContext, useRef } from "react";
import { useState, useEffect } from "react";

import {
  MHeader,
  MTableCell,
  MActionsCell,
  ApiData,
  ActionType,
} from "../Components/table/Table";

import Header from "../Components/Header/Header";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { Header2 } from "../Components/Header/Header2";
import Pagination from "../Components/pagination/Pagination";
import { rootUrl } from "../constants";
import { CurrentUserContext } from "../App";
import { useReactToPrint } from "react-to-print";
import PrintList from "../Components/printable_list";
import ModalSupression from "../Components/Modal/ModalSupression";
import { FilterComponent, FilterProps } from "../Components/filter_component";
import { Director } from "../interfaces";
import { generalRefundCategories, paraseRefundCategory } from "../utils";

interface RefundInterface {
  id: number;
  staff_name: string | null;
  amount: { [key: string]: number };
  created_at: string;
  director_name: string;
  director: number;
}

function AddRefundModal({ onDone }: { onDone: () => void }) {
  const [selectedDirector, setSelectedDirector] = useState<Director | null>(
    null
  );
  const [amounts, setAmounts] = useState<{
    category_1: number;
    category_2: number;
    category_3: number;
  }>({
    category_1: 0,
    category_2: 0,
    category_3: 0,
  });
  const token = useContext(CurrentUserContext).currentUserData!.token;

  const currentSelectedHospitalId =
    useContext(CurrentUserContext).currentUserData!.selectedHospitalId;
  const currentHospital = useContext(
    CurrentUserContext
  ).currentUserData!.hospitals.find(
    (hospital) => hospital.id === currentSelectedHospitalId
  )!;

  function submit() {
    if (!selectedDirector) {
      alert("Veuillez selectionner un régisseur");
      return;
    }
    if (Object.values(amounts).reduce((prev, curr) => prev + curr, 0) <= 0) {
      alert("Veuillez entrer un montant valide pour au moins une catégorie");
      return;
    }
    axios
      .post(
        rootUrl + `/hospital/${currentSelectedHospitalId}/municipal_refund`,
        {
          director: selectedDirector!.id,
          amount: amounts,
        },
        {
          headers: {
            Authorization: "Token " + token,
          },
        }
      )
      .then((response: { data: any }) => {
        onDone();
      })
      .catch((error: any) => {
        alert("Une erreur s'est produite" + error);
      });
  }

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className="flex flex-col bg-white shadow-xl p-4 rounded w-[500px]"
    >
      <h2 className=" self-start font-medium text-2xl">Ajouter un versement</h2>
      <select
        className=" mt-5 border border-gray-400 rounded-md px-4 py-2 w-max pr-14 "
        value={selectedDirector?.id ?? ""}
        onChange={(e) => {
          setSelectedDirector(
            (currentHospital.municipal_tax_data?.directors ?? []).find(
              (director) => director.id === parseInt(e.target.value)
            )!
          );
        }}
      >
        {[
          <option value={""} disabled>
            Selectionner un régisseur
          </option>,
          ...(currentHospital.municipal_tax_data?.directors.map((director) => (
            <option value={director.id}>{director.name}</option>
          )) ?? []),
        ]}
      </select>
      {Object.entries(amounts).map(([category, amount]) => (
        <input
          type="number"
          className=" mt-5 border border-gray-400 rounded-md px-4 py-2 w-max "
          placeholder={paraseRefundCategory(category).toUpperCase()}
          value={amount === 0 ? "" : amount}
          onChange={(e) => {
            let ret = parseInt(e.target.value);
            if (isNaN(ret)) {
              ret = 0;
            }
            setAmounts({
              ...amounts,
              [category]: ret,
            });
          }}
        />
      ))}
      <button
        onClick={submit}
        className="mt-5 bg-[#187498] text-white rounded-md px-4 py-2 w-max hover:bg-[#187498] transition-all self-end"
      >
        Ajouter
      </button>
    </div>
  );
}

const RefundListPage = () => {
  const isAdmin = useContext(CurrentUserContext).currentUserData!.isAdmin;

  const [printing, setPrinting] = useState<boolean>(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);

  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState<number>(0);

  const componentRef = useRef<HTMLDivElement>(null);
  const [selected, setSelected] = useState<number[]>([]);
  let [currentPage, setCurrentPage] = useState<number>(1);
  const [data, setData] = useState<ApiData<RefundInterface> | null>(null);
  const [searchedText, setSearchedText] = useState<string>("");
  const [isAll, setIsAll] = useState<boolean>(false);
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const [filterProps, setFilterProps] = useState<FilterProps>({
    statsType: null,
    selectedDate: null,
    selectedMonth: null,
    selectedYear: null,
    selectedUserId: null,
  });

  const [deletingId, setDeletingId] = useState<number | null>(null);

  const navigate = useNavigate();
  const [error, setError] = useState(null);

  let currentSelectedHospitalId =
    useContext(CurrentUserContext).currentUserData!.selectedHospitalId;

  let token = useContext(CurrentUserContext).currentUserData!.token;

  const currentHospital = useContext(
    CurrentUserContext
  ).currentUserData!.hospitals.find(
    (hospital) => hospital.id === currentSelectedHospitalId
  )!;

  const titles = [
    "date",
    "regisseur",
    "montant",
    ...(isAdmin ? ["Caissier Responsable"] : []),
    "",
  ];

  function deleteRefund(id: number) {
    axios
      .delete(
        rootUrl +
          `/hospital/` +
          currentSelectedHospitalId +
          `/municipal_refund/${id}`,
        {
          headers: {
            Authorization: "Token " + token,
          },
        }
      )
      .then((response: { data: any }) => {
        console.log(data);
        FetchData();
      })
      .catch((error: any) => {
        alert("Une erreur s'est produite" + error);
      });
  }

  function FetchData() {
    let filterParams = {};

    if (filterProps.statsType === "daily") {
      filterParams = {
        year: filterProps.selectedDate?.split("-")[0] ?? undefined,
        month: filterProps.selectedDate?.split("-")[1] ?? undefined,
        day: filterProps.selectedDate?.split("-")[2] ?? undefined,
      };
    } else if (filterProps.statsType === "monthly") {
      filterParams = {
        year: filterProps.selectedYear ?? undefined,
        month: filterProps.selectedMonth ?? undefined,
      };
    }
    filterParams = {
      ...filterParams,
      user: filterProps.selectedUserId ?? undefined,
      all: isAll ? "true" : undefined,
    };
    setData(null);
    axios
      .get(
        rootUrl +
          "/hospital/" +
          currentSelectedHospitalId +
          "/municipal_refund",
        {
          params: { page: currentPage, search: searchedText, ...filterParams },
          headers: {
            Authorization: "Token " + token,
          },
        }
      )
      .then((response: { data: any }) => {
        setData(response.data);
      })
      .catch((error: any) => {
        setError(error);
      });
  }

  useEffect(() => {
    FetchData();
  }, [currentPage, filterProps, isAll]);

  useEffect(() => {
    setFilterProps({
      ...filterProps,
      selectedUserId: null,
    });
  }, [currentSelectedHospitalId]);

  useEffect(() => {
    clearTimeout(typingTimeout);

    // Set a new timeout to delay the search
    const timeoutId = setTimeout(() => {
      setSelected([]);
      currentPage = 1; //should be updated but not render
      FetchData();
    }, 500);

    setTypingTimeout(timeoutId);

    return () => {
      clearTimeout(typingTimeout);
    };
  }, [searchedText]);
  const handlePrint = useReactToPrint({
    onBeforeGetContent() {
      setPrinting(true);
    },

    content: () => {
      return componentRef.current;
    },
    onAfterPrint: () => {
      setPrinting(false);
    },
  });

  return (
    <div className="operations-wrapper">
      {
        // why it is not working
        isAddModalOpen && (
          <div
            onClick={() => {
              setIsAddModalOpen(false);
            }}
            className="modal"
          >
            <div
              className={
                (isAddModalOpen
                  ? "opacity-100 scale-100"
                  : "opacity-20 scale-50") + " transition-all duration-200"
              }
            >
              <AddRefundModal
                onDone={() => {
                  setIsAddModalOpen(false);
                  FetchData();
                }}
              />
            </div>
          </div>
        )
      }
      {deletingId && (
        <ModalSupression
          open={false}
          name={`ce remboursement`}
          onClose={function (): void {
            setDeletingId(null);
          }}
          onConfirmClick={function (): void {
            deleteRefund(deletingId);
            setDeletingId(null);
          }}
        />
      )}
      <Header title="Versements" />
      {
        <Header2
          onAddButtonClick={
            isAdmin
              ? null
              : () => {
                  setIsAddModalOpen(true);
                }
          }
          inputPlaceholder={"Rechercher dans ce tableau"}
          onInputChange={function (text: string): void {
            setSearchedText(text);
          }}
          handlePrint={handlePrint}
          all={isAll}
          onAllClick={function (): void {
            setIsAll(!isAll);
            setCurrentPage(1);
          }}
        />
      }
      {data === null ? (
        // center loading
        //center
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress thickness={6} sx={{ color: "#0B63F8" }} />
        </div>
      ) : (
        <div className="flex flex-col gap-y-2">
          {/* <div className="flex gap-x-2 font-pop font-medium ml-10 mb-4">
            {[1, 2, 3].map((n) => (
              <button
                onClick={() => {
                  setSelectedCategory(n);
                  setCurrentPage(1);
                }}
                className={`font-semibold rounded-sm text-black  p-2 transition-all ${
                  selectedcategory === n ? "bg-[#187498] text-white" : ""
                }`}
              >
                <p>Categorie {n}</p>
              </button>
            ))}
          </div> */}
          {isAdmin && (
            <div className="flex flex-col  gap-y-2 items-start justify-center ml-10 mb-5">
              <FilterComponent
                filterProps={filterProps}
                setFilterProps={(props: FilterProps) => {
                  setFilterProps(props);
                  setCurrentPage(1);
                }}
              />
            </div>
          )}

          <div className="flex gap-x-2 font-pop font-medium ml-10 ">
            {generalRefundCategories.map((category, index) => {
              return (
                <button
                  onClick={() => {
                    setSelectedCategoryIndex(index);
                    setCurrentPage(1);
                  }}
                  className={`font-semibold rounded-sm text-black  p-2 transition-all capitalize ${
                    selectedCategoryIndex === index
                      ? "bg-[#187498] text-white"
                      : ""
                  }`}
                >
                  <p>{category}</p>
                </button>
              );
            })}
          </div>
          {data.total !== undefined && (
            <div className="flex gap-x-2 ms-10">
              <span>Total :</span>
              <span className="font-bold">
                {(data.total["total_category_" + (selectedCategoryIndex + 1)] ??
                  0) + " MRU"}
              </span>
            </div>
          )}
          <Table
            style={{
              backgroundColor: "white",
              marginRight: "40px",
              marginLeft: "40px",
              width: "95.5%",
            }}
          >
            <MHeader
              titles={titles.map((t) => {
                return { label: t, sortable: true };
              })}
              withActions={false}
              onCheckClick={() => {
                if (selected.length === data?.data.length) {
                  setSelected([]);
                } else {
                  setSelected(data?.data.map((item) => item.id) || []);
                }
              }}
              isAllSelected={
                data &&
                data!.data.length !== 0 &&
                selected.length === data?.data.length
              }
            />
            <TableBody>
              {data!.data.map((refund, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Checkbox
                      checked={selected.includes(refund.id)}
                      onChange={() => {
                        if (selected.includes(refund.id)) {
                          setSelected(
                            selected.filter((item) => item !== refund.id)
                          );
                        } else {
                          setSelected([...selected, refund.id]);
                        }
                      }}
                    />
                  </TableCell>
                  <MTableCell
                    label={new Date(refund.created_at).toLocaleDateString()}
                    isTitle={false}
                  />
                  <MTableCell label={refund.director_name} isTitle={false} />

                  <MTableCell
                    label={
                      // show each category and the amount  (category is the key)
                      <div className="flex justify-start gap-x-8">
                        {Object.values(refund.amount)[selectedCategoryIndex] +
                          " MRU"}
                        {/* {Object.entries(refund.amount).map(
                          ([category, amount]) => (
                            <div className="flex flex-col text-xs">
                              <span className="font-semibold capitalize">
                                {paraseRefundCategory(category)}
                              </span>
                              <span>{amount + " MRU"}</span>
                            </div>
                          )
                        )} */}
                      </div>
                    }
                    isTitle={false}
                  />
                  {isAdmin && (
                    <MTableCell
                      label={refund.staff_name ?? "-"}
                      isTitle={false}
                    />
                  )}
                  {isAdmin && (
                    <MTableCell
                      label={
                        <button
                          onClick={() => {
                            setDeletingId(refund.id);
                          }}
                          className="bg-red-400 border-2 text-sm font-medium text-white border-red-400 rounded py-2 px-3 hover:bg-transparent hover:text-red-500 active:scale-95 transition-all "
                        >
                          Supprimer
                        </button>
                      }
                      isTitle={false}
                    />
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      )}
      {data && (
        <Pagination
          currentPage={currentPage}
          totalPages={data!.total_pages}
          onPageChange={function (page: number): void {
            setCurrentPage(page);
          }}
        />
      )}
      <div ref={componentRef}>
        <PrintList
          centerName={currentHospital.name}
          title={"Liste des remboursements"}
          afterTable={
            <div className="flex justify-between w-full">
              {data?.total !== undefined && (
                <div className="flex gap-x-2">
                  <span>Total :</span>
                  <span className="font-bold">
                    {(data.total[
                      "total_category_" + (selectedCategoryIndex + 1)
                    ] ?? 0) + " MRU"}
                  </span>
                </div>
              )}
              {filterProps.selectedUserId && (
                <div>
                  <span>Caissier Responsable :</span>

                  <span className="font-bold">
                    {currentHospital!.staff_data.find(
                      (u) => u.id === filterProps.selectedUserId
                    )?.name ?? ""}
                  </span>
                </div>
              )}
            </div>
          }
          titles={titles.filter((title) => title !== "")}
          data={
            data?.data
              .filter(
                (refund, index) =>
                  selected.length === 0 || selected.includes(refund.id)
              )
              .map((refund, index) => [
                new Date(refund.created_at).toLocaleDateString(),
                refund.director_name,
                Object.values(refund.amount)[selectedCategoryIndex] + " MRU",
                // Object.entries(refund.amount).map(([category, amount]) => (
                //   <div className="flex flex-col text-xs">
                //     <span className="font-semibold capitalize">
                //       {paraseRefundCategory(category)}
                //     </span>
                //     <span>{amount + " MRU"}</span>
                //   </div>
                // )),
                ...(isAdmin ? [refund.staff_name ?? "-"] : []),
              ]) ?? []
          }
        />
      </div>
    </div>
  );
};

export default RefundListPage;
