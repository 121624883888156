import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { medicationSchema } from "../Validations/MedicamentValidation";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import "../Ajouter_medicament/ajouter.css";
import { useParams } from "react-router-dom";
import { HospitalInterface, UserInterface } from "../interfaces";
import CircularProgress from "@mui/material/CircularProgress";
import { rootUrl } from "../constants";
import { CurrentUserContext } from "../App";
import { FaRegBell } from "react-icons/fa";
import { MdLogout } from "react-icons/md";
import { DefDropdown } from "../Components/dropdown";

const AjouterUtilisateur = () => {
  const { id: userId } = useParams();
  const [userData, setUserData] = useState<UserInterface | null>(null);
  const [selectedHospital, setSelectedHospital] =
    useState<HospitalInterface | null>(null);
  const navigate = useNavigate();

  const userSchema = yup.object().shape({
    name: yup.string().required("Veuillez saisir le nom de l'utilisateur "),
    username: yup.string().required("Veuillez saisir l'identifiant"),

    password: yup.string().required("Veuillez saisir le mot de passe"),
  });

  let token = useContext(CurrentUserContext).currentUserData!.token;
  useEffect(() => {
    if (userId) {
      axios
        .get(rootUrl + "/users/" + userId, {
          headers: {
            Authorization: "Token " + token,
          },
        })
        .then((response: { data: any }) => {
          console.log("data returned : ");
          console.log(response);

          setUserData(response.data);
          setSelectedHospital({
            name: response.data.assigned_hospital_name,
            id: response.data.assigned_hospital,
            addresse: "",
            tickets: [],
            payments: [],
            created_at: "",
            is_hospital: false,
            has_pharmacy: false,
            has_subscription: false,
            staff: [],
            subscription_price: 200,
            specialization: null,
            stock_alerts: [],
            bank_account: "",
            staff_data: [],
            ticket_name: null,
            municipal_tax_data: null,
          });
        })
        .catch((error: any) => {
          navigate("/admin/utilisateurs");
        });
    }
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(userSchema),
  });

  const submitForm = (data: any) => {
    if (!selectedHospital) {
      alert("Veillez choisir un centre");
      return;
    }
    const newUser = {
      name: data.name,
      assigned_hospital: selectedHospital.id,
      username: data.username,
      password: data.password,
    };

    if (userId) {
      axios
        .put(rootUrl + "/users/" + userId + "/", newUser, {
          headers: {
            Authorization: "Token " + token,
          },
        })
        .then((response) => {
          navigate("/admin/utilisateurs");
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      axios
        .post(rootUrl + "/users/", newUser, {
          headers: {
            Authorization: "Token " + token,
          },
        })
        .then((response) => {
          navigate("/admin/utilisateurs");
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  return (
    <div className="ajouter-wrapper">
      <h1 className="title">
        {!userId ? "Ajouter un Utilisateur" : "Mettre à jour"}
      </h1>
      <div className="positioned-buttons">
        <button className="notif-button">
          <FaRegBell />
        </button>
        <button className="logout-button">
          <MdLogout />
        </button>
      </div>
      {userId && !userData ? (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress thickness={6} sx={{ color: "#0B63F8" }} />
        </div>
      ) : (
        <form onSubmit={handleSubmit(submitForm)}>
          <div className="row">
            <div className="column-side">
              <div className="input-grp">
                <label className="label" htmlFor="name">
                  Le nom de l'utilisateur
                </label>
                <div className="input-p">
                  <input
                    className="input-1"
                    defaultValue={userData?.name ?? ""}
                    {...register("name")}
                    type="text"
                    name="name"
                  />
                  <p className="error-msg">{errors.name?.message}</p>
                </div>
              </div>

              <div className="input-grp">
                <label className="label" htmlFor="username">
                  Identifiant de l'utilisateur
                </label>
                <div className="input-p">
                  <input
                    className="input-1"
                    type="text"
                    defaultValue={userData?.username ?? ""}
                    {...register("username")}
                    name="username"
                  />
                  <p className="error-msg">{errors.username?.message}</p>
                </div>
              </div>
            </div>

            <div className="column-side">
              <div className="input-grp">
                <label className="label" htmlFor="hospital">
                  Entrez le centre assigné
                </label>

                <div className="input-p">
                  <DefDropdown<HospitalInterface>
                    search={true}
                    value={selectedHospital?.name ?? ""}
                    onSelected={(value: HospitalInterface) => {
                      setSelectedHospital(value);
                    }}
                    placeholder={"nom du centre"}
                    url={"hospital"}
                  />
                </div>
              </div>

              <div className="input-grp">
                <label className="label" htmlFor="password">
                  Entrez le mot-de-passe
                </label>
                <div className="input-p">
                  <input
                    className="input-1"
                    type="text"
                    // defaultValue={hospitalData?.password ?? ""}
                    {...register("password")}
                    name="password"
                  />
                  <p className="error-msg">{errors.password?.message}</p>
                </div>
              </div>
            </div>
          </div>

          <input
            className="ajouter-button"
            type="submit"
            value={userId ? "Modifier" : "Ajouter"}
          />
        </form>
      )}
    </div>
  );
};

export default AjouterUtilisateur;
